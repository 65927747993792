<div class="row">
  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <i class="far fa-clock"></i> {{ rideDetail?.time }}
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-clock-in"></i>{{ rideDetail?.speed }} Km/h
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-multiplication"></i>{{ rideDetail?.co2 }}
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-pulse"></i> {{ rideDetail?.distance }} km
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-vector-line"></i>{{ rideDetail?.calorie }} cal
          </li>
        </ul>
        <div>
          <h5 class="mt-4">Asset detail</h5>
        </div>

        <div>
          <i class="fa fa-user"></i> Owner -
          {{ rideDetail?.lock_id?.owner_id?.user_id?.name }}
        </div>
        <div>
          <i class="fas fa-lock"></i>
          {{ rideDetail?.lock_id?.lock_name }} &nbsp;&nbsp;
          <i class="fas fa-battery-full"></i
          >{{ rideDetail?.lock_id?.battery_percentage }}%
        </div>
        <h5 class="mt-4">Location details</h5>
        <div class="mt-2">
          <i class="mdi mdi-map-marker text-success"></i><b>Start point</b> :
          {{ rideDetail?.start_address }}
        </div>
        <div class="mt-2">
          <i class="mdi mdi-map-marker text-danger"></i><b>End point </b>:
          {{ rideDetail?.end_address }}
        </div>
        <h5 class="mt-4">User detail</h5>
        <div class="mt-2"><b>Name</b> : {{ rideDetail?.user_id.name }}</div>
        <div class="mt-2"><b>Email </b>: {{ rideDetail?.user_id.email }}</div>
        <div class="mt-2">
          <b>Phone </b>:
          {{ rideDetail?.user_id?.dialling_code }}
          {{ rideDetail?.user_id?.mobile }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-8">
    <div class="card">
      <div class="card-body">
        <div class="map" id="map" class="match-parent"></div>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="imageList.length != 0">
    <div class="card">
      <div class="card-body">
        <div class="mt-2">
          <h5 class="mt-2">Ride Proofs</h5>
          <ng-image-slider
            [images]="imageList"
            [imageSize]="{ width: 380, height: 300 }"
            #navigation
          ></ng-image-slider>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div
      *ngIf="
        rideDetail &&
        rideDetail.start_ride_images &&
        rideDetail.start_ride_images.length > 0
      "
    >
      <h5>Start Ride Image</h5>
      <i class="mdi mdi-map-marker text-primary"></i><b>Time </b>:
      {{ start_ride_time }}
      <div *ngFor="let images of rideDetail && rideDetail.start_ride_images">
        <img class="card-img-top" [src]="images" alt="Start Ride Image" />
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div
      *ngIf="
        rideDetail &&
        rideDetail.end_ride_images &&
        rideDetail &&
        rideDetail.end_ride_images.length > 0
      "
    >
      <h5>End Ride Image</h5>
      <div *ngFor="let images of rideDetail && rideDetail.end_ride_images">
        <img class="card-img-top" [src]="images" alt="Start Ride Image" />
      </div>
    </div>
  </div>

  <div class="col-md-3" *ngIf="rideDetail && rideDetail.ode_end_checklist">
    <div>
      <h5>End Ride Checklist</h5>
      <div class="mt-2">
        <i class="mdi mdi-map-marker text-success"></i><b>Comment</b> :
        {{
          rideDetail &&
            rideDetail.ode_end_checklist &&
            rideDetail.ode_end_checklist.comment
        }}
      </div>
      <div class="mt-2">
        <i class="mdi mdi-map-marker text-primary"></i><b>Time </b>:
        {{ ode_end_checklist_time }}
        <div
          *ngIf="
            rideDetail &&
            rideDetail.ode_end_checklist &&
            rideDetail.ode_end_checklist.question_answer.length > 0
          "
        >
          <div
            class="mt-2"
            *ngFor="
              let array of rideDetail &&
                rideDetail.ode_end_checklist &&
                rideDetail.ode_end_checklist.question_answer
            "
          >
            <i class="mdi mdi-map-marker text-danger"></i><b>Questions </b>:
            {{ array.question }}
            <br />
            <i class="mdi mdi-map-marker text-success"></i><b>Answer </b>:
            {{
              array.ans == "true"
                ? "Yes"
                : array.ans == "false"
                ? "No"
                : array.ans
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div
      *ngIf="
        rideDetail &&
        rideDetail.end_ride_station_images &&
        rideDetail &&
        rideDetail.end_ride_station_images.length > 0
      "
    >
      <h5>End Ride Station Image</h5>
      <div
        *ngFor="let images of rideDetail && rideDetail.end_ride_station_images"
      >
        <img class="card-img-top" [src]="images" alt="Start Ride Image" />
      </div>
    </div>
  </div>
</div>
