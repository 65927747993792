<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div>
        <h4 class="card-title mb-0 alignleft">
          <span class="lstick"></span>Pludda List
        </h4>
        <div class="row alignright">
          <label style="margin-right: 20px; margin-top: 7px"
            >Allow to upload pludda
          </label>
          <ui-switch
            (change)="changeStatus()"
            [(ngModel)]="isPluddaEnabled"
          ></ui-switch>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Address</th>
              <th scope="col">Description</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">View</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <th><img [src]="d?.images[0]" width="40" /></th>
              <td>
                {{
                  d?.address.length > 20
                    ? d?.address.substring(0, 20) + "..."
                    : d?.address.substring(0, 20)
                }}
              </td>

              <td>
                {{
                  d?.description.length > 20
                    ? d?.description.substring(0, 20) + "..."
                    : d?.description.substring(0, 20)
                }}
              </td>

              <td>{{ d?.created_at | date : "medium" }}</td>
              <td>{{ d?.status | titlecase }}</td>
              <td>
                <a
                  [routerLink]="['../pludda-details/', d?._id]"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="View detail"
                >
                  <i class="fa fa-eye"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="6">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="pageNO"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
