import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgForm, AbstractControl } from "@angular/forms";
import { Globals } from "../../common/global";
@Component({
  selector: "app-pay-later-payment",
  templateUrl: "./pay-later-payment.component.html",
  styleUrls: ["./pay-later-payment.component.css"],
})
export class PayLaterPaymentComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  operatorDetails = this.Globals.getOperatorDetails();
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.getList();
  }
  modeltitle: any;
  page = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  amount: any;
  operator_id = this.Globals.getUser()._id;

  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
    this.amount = this.detail.pending_payment_amount / 100;
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  getList() {
    let page = this.page == 0 ? 0 : this.page - 1;

    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.PAY_LATER_PAYMENTs.GET_LIST +
          "?operator_id=" +
          this.operator_id +
          "&page=" +
          page +
          "&limit=" +
          this.pageSize
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = res.total;
        }
      });
  }

  loadPage(page: number) {
    this.page = page;
    this.getList();
  }

  onSubmit(f: any, details: any) {
    console.log("hello", details);
    const body = new HttpParams()

      // .set("user_id", this.Globals.getUser()._id)
      .set("pending_payment_amount", f.value.pending_payment_amount * 100)
      .set("booking_amount_paid", f.value.booking_amount_paid);

    this.apiCallingService
      .callAPI(
        body,
        "POST",
        ConfigApi.URLS.PAY_LATER_PAYMENTs.UPDATE + details._id
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            // this.data = res.data;
            this.toastr.success(res.msg);
            this.closeBtnClick();
            this.getList();
            console.log(res.data);
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          console.log("🚀 ~ PayLaterPaymentComponent ~ onSubmit ~ err:", err);
          this.toastr.error(err?.error?.msg, "Error");
        }
      );
  }
}
