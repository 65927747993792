import { Component, OnInit } from "@angular/core";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { Globals } from "../../common/global";
@Component({
  selector: "app-my-asset-ride-history",
  templateUrl: "./my-asset-ride-history.component.html",
  styleUrls: ["./my-asset-ride-history.component.css"],
})
export class MyAssetRideHistoryComponent implements OnInit {
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  constructor(
    private apiCallingService: ApiCallingService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.data = [];
    this.getList();
  }

  getList() {
    let user_id = this.Globals.getUser()._id;
    var body = {
      userId: user_id,
      page: this.page - 1,
      limit: this.pageSize,
    };
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.MY_ASSET_RIDE_HISTORY)
      .subscribe((res) => {
        this.data = res.data;
        this.totalcount = res.total;
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }
}
