<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Rent Package
        </h4>
        <div class="d-flex mt-3">
          <a
            href="javascript: void(0);"
            class="btn btn-primary ml-auto"
            placement="top"
            (click)="openModal(editModal, {}, 'Create Package')"
            ngbTooltip="Create Package"
          >
            + Create
          </a>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Package Name</th>
              <th scope="col">Extra Fees Applied</th>
              <th scope="col">Base price</th>
              <th scope="col">Price</th>
              <th scope="col">Extra Fees</th>
              <th scope="col">Free Minutes/Day</th>
              <th scope="col">Security deposite</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>
                For- {{ d.duration }} {{ d.timing }}
                {{ d.type }}
              </td>
              <td>
                {{ d.package_name ? d.package_name : "-" }}
              </td>
              <td>
                {{ d.enable_extra_fee == true ? "True" : "False" }}
              </td>
              <td>
                {{ d.currency.symbol }} {{ d.base_price }} {{ d.currency.code }}
              </td>
              <td>
                {{ d.currency.symbol }} {{ d.price }} {{ d.currency.code }}
              </td>
              <td>
                {{ d?.extra_fee ? d.currency.symbol : "" }}
                {{ d?.extra_fee ? d?.extra_fee : "-" }}
                {{ d?.extra_fee ? d.currency.code : "" }}
              </td>
              <td>{{ d?.free_minutes ? d?.free_minutes : "-" }}</td>

              <td>{{ d?.security_deposit }}</td>
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Edit Package')"
                  ngbTooltip="Edit"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Delete Package')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="9">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form
      *ngIf="modeltitle == 'Create Package' || modeltitle == 'Edit Package'"
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      Package will decide how much rent you will get when a person hire your
      assets.
      <div class="form-group row">
        <label for="fullName" class="col-sm-4 col-form-label">Lock type</label>
        <div class="col-sm-8">
          <select
            class="form-control"
            #type="ngModel"
            name="type"
            [(ngModel)]="detail.type"
          >
            <option *ngFor="let type of locktypes">{{ type }}</option>
          </select>
          <span
            >Note : You cannot create multiple package with same asset type and
            timing</span
          >
        </div>
      </div>

      <div class="form-group row">
        <label for="base_price" class="col-sm-4 col-form-label"
          >Base price({{ currency }})</label
        >
        <div class="col-sm-8">
          <input
            type="double"
            class="form-control"
            name="base_price"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.base_price"
            id="base_price"
            name="base_price"
            ngModel
            #base_price="ngModel"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="price" class="col-sm-4 col-form-label"
          >Fees ({{ currency }})</label
        >
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            name="price"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.price"
            id="base_price"
            name="price"
            ngModel
            #price="ngModel"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="timing" class="col-sm-4 col-form-label">Timing</label>
        <div class="col-sm-8">
          <select
            class="form-control"
            #timing="ngModel"
            name="timing"
            [(ngModel)]="detail.timing"
            (change)="changeTiming($event)"
          >
            <option *ngFor="let t of timings" value="{{ t }}">{{ t }}</option>
          </select>
          <span
            >Note: Your package will be created under membership feature if you
            select timing as week or month</span
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="duration" class="col-sm-4 col-form-label">Duration</label>
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            name="duration"
            pattern="(\s*[^\s]+\s*)+"
            min="1"
            max="{{ maxValue }}"
            onKeyPress="if(this.value.length==3)return false;"
            [(ngModel)]="detail.duration"
            id="duration"
            name="duration"
          />
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="detail.timing == 'Week' || detail.timing == 'Month'"
      >
        <label class="col-sm-4 col-form-label">Enable Extra Fees ?</label>
        <div class="col-sm-8">
          <ui-switch
            [(ngModel)]="detail.enable_extra_fee"
            [ngModelOptions]="{ standalone: true }"
            name="enable_extra_fee"
            #isExclusive="ngModel"
          ></ui-switch>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          detail.enable_extra_fee == true &&
          (detail.timing == 'Week' || detail.timing == 'Month')
        "
      >
        <label for="free_minutes" class="col-sm-4 col-form-label"
          >Free Minutes/Day</label
        >
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            name="free_minutes"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.free_minutes"
            id="free_minutes"
            name="free_minutes"
            ngModel
            #free_minutes="ngModel"
            required
          />
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          detail.enable_extra_fee == true &&
          (detail.timing == 'Week' || detail.timing == 'Month')
        "
      >
        <label for="package_name" class="col-sm-4 col-form-label"
          >Package Name</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="package_name"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.package_name"
            id="package_name"
            name="package_name"
            ngModel
            #package_name="ngModel"
            required
          />
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          detail.enable_extra_fee == true &&
          (detail.timing == 'Week' || detail.timing == 'Month')
        "
      >
        <label for="extra_fee" class="col-sm-4 col-form-label"
          >Extra fees ({{ currency }})</label
        >
        <div class="col-sm-8">
          <input
            type="double"
            class="form-control"
            name="extra_fee"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.extra_fee"
            id="base_extra_fee"
            min="1"
            name="extra_fee"
            ngModel
            #extra_fee="ngModel"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="vat" class="col-sm-4 col-form-label"
          >{{
            this.operatorDetails &&
            this.operatorDetails.tax_type &&
            this.operatorDetails.tax_type
              ? this.operatorDetails.tax_type
              : "VAT"
          }}
          (%)</label
        >
        <div class="col-sm-8">
          <input
            type="double"
            class="form-control"
            name="vat"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.vat"
            id="vat"
            step="0.01"
            min="0"
            name="vat"
            ngModel
            #vat="ngModel"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="pkg_desc" class="col-sm-4 col-form-label"
          >Description</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="pkg_desc"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.pkg_desc"
            id="pkg_desc"
            name="pkg_desc"
            ngModel
            #pkg_desc="ngModel"
            maxlength="100"
          />
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="detail.timing == 'Minute' || detail.timing == 'Hour'"
      >
        <label for="security_deposit" class="col-sm-4 col-form-label"
          >Security deposite</label
        >
        <div class="col-sm-8">
          <input
            type="double"
            class="form-control"
            name="security_deposit"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.security_deposit"
            id="security_deposit"
            min="0"
            name="security_deposit"
            ngModel
            #security_deposit="ngModel"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="timing" class="col-sm-4 col-form-label"
          >Is
          {{
            this.operatorDetails &&
            this.operatorDetails.tax_type &&
            this.operatorDetails.tax_type
              ? this.operatorDetails.tax_type
              : "VAT"
          }}
          inculsive or exclusive ?</label
        >
        <div class="col-sm-8">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              [(ngModel)]="detail.isExclusive"
              name="isExclusive"
              id="inlineRadio1"
              #isExclusive="ngModel"
              [value]="false"
            />
            <label class="form-check-label" for="inlineRadio1">Inculsive</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              [(ngModel)]="detail.isExclusive"
              name="isExclusive"
              id="inlineRadio2"
              [value]="true"
            />
            <label class="form-check-label" for="inlineRadio2">Exclusive</label>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label"
          >Need to fill Waiver form ?</label
        >
        <div class="col-sm-8">
          <ui-switch
            [(ngModel)]="detail.isWaiverDisplay"
            [ngModelOptions]="{ standalone: true }"
            name="isWaiverDisplay"
            #isExclusive="ngModel"
          ></ui-switch>
        </div>
      </div>

      <div class="form-group row" *ngIf="detail.isWaiverDisplay">
        <label class="col-sm-4 col-form-label">Required License ?</label>
        <div class="col-sm-8">
          <ui-switch
            [(ngModel)]="detail.request_licence"
            [ngModelOptions]="{ standalone: true }"
            name="request_licence"
            #request_licence="ngModel"
            (ngModelChange)="onLicenseDisplayChange($event)"
          ></ui-switch>
        </div>
      </div>

      <div class="form-group row" *ngIf="detail.request_licence">
        <label class="col-sm-4 col-form-label">License Type ?</label>
        <div class="col-sm-8">
          <select
            class="form-control"
            #license_type="ngModel"
            name="license_type"
            [(ngModel)]="detail.license_type"
          >
            <option *ngFor="let type of licenseTypelist">{{ type }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label"
          >Present safety instruction video ?</label
        >
        <div class="col-sm-8">
          <ui-switch
            [(ngModel)]="detail.enable_video"
            [ngModelOptions]="{ standalone: true }"
            name="enable_video"
            #enable_video="ngModel"
          ></ui-switch>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Safety instruction Link </label>
        <div class="col-sm-8">
          <input
            class="form-control"
            type="text"
            name="video_link"
            id="video_link"
            ngModel
            [(ngModel)]="detail.video_link"
            [required]="detail.enable_video"
            #video_link="ngModel"
          />
          <div
            *ngIf="
              detail.enable_video &&
              video_link.invalid &&
              (video_link.dirty || video_link.touched)
            "
            class="text-danger"
          >
            <div *ngIf="video_link.errors?.required">
              Video link is required when video is enabled.
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>

        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>

    <div *ngIf="modeltitle == 'Delete Package'">
      Are you sure want to delete?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="delete()">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>
