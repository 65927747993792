import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  ElementRef,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { Globals } from "../../common/global";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import * as mapboxgl from "mapbox-gl";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-my-assets",
  templateUrl: "./my-assets.component.html",
  styleUrls: ["./my-assets.component.css"],
})
export class MyAssetsComponent implements OnInit {

  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  @ViewChild("qrDiv") divView: any = ElementRef;

  constructor(
    private http: HttpClient,
    private elRef: ElementRef,
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    private toastr: ToastrService,
    private router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getLockTypes();
    this.getList();
  }
  modeltitle: any;
  page = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  locktypes = [];
  imageSrc: any;
  file: any;
  map: any;
  searchresult: any;
  model1: any;

  pageOfItems?: Array<any>;
  sortProperty: string = 'id';
  sortOrder = 1;
  loading = false;
  getList() {
    let user_id = this.Globals.user._id;
    let query = "?user_id=" + user_id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.MY_ASSETS_FOR_LEASE + query)
      .subscribe((res) => {
        this.data = res.data;
        this.totalcount = res.total;
      });
  }

  loadPage(page: number) {
    this.page = page - 1;
    this.getList();
  }
  getLockTypes() {
    let user_id = this.Globals.getUser()._id;
    let query = "?user_id=" + user_id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_LOCK_TYPE + query)
      .subscribe((res) => {
        this.locktypes = res.data;
      });
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    data.brand_name = data.brand_name ? data.brand_name : "";
    if (data.brand_name == "") {
      if (data.isSharekayakLock) {
        data.brand_name = "Sharekayak";
      } else {
        data.brand_name = "Frekis";
      }
    }

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.model1 = "";
    this.searchresult = [];
    this.detail = Object.assign({}, data);
    this.imageSrc = data.lock_image;
    if (title == "Asset detail") {
      setTimeout(() => {
        (mapboxgl as any).accessToken = environment.mapbox.accessToken;
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          zoom: 13,
          center: [this.detail.longitude, this.detail.latitude],
        });
        let markerUrl = this.getCustomerMarker(this.detail);

        const el = document.createElement("div");
        el.className = "marker";
        el.style.backgroundImage = `url(${markerUrl})`;
        el.style.width = `40px`;
        el.style.height = `40px`;
        el.style.backgroundSize = "100%";
        let htmlofPopup = "<b>Address : </b>" + this.detail.lock_address;
        let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlofPopup);
        let m = new mapboxgl.Marker(el)
          .setLngLat([this.detail.longitude, this.detail.latitude])
          .setPopup(popup)
          .addTo(this.map);
      });
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.file = file;
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
      this.imageSrc = file;
    }
  }
  onSubmit(f: any) {
    const uploadData = new FormData();

    if (this.file) {
      uploadData.append("lock_image", this.file, this.file.name);
    }

    if (this.detail.is_free_lock && this.detail.is_free_lock == undefined) {
      this.detail.is_free_lock = false
    }

    uploadData.append("owner_id", this.Globals.getUser()._id);
    uploadData.append("lock_name", f.value.lock_name);
    uploadData.append("lock_type", f.value.lock_type);
    uploadData.append("description", f.value.description);
    uploadData.append("id", this.detail._id);
    uploadData.append("is_reservable", this.detail.is_reservable);
    uploadData.append("brand_name", f.value.brand_name);
    uploadData.append("is_free_lock", this.detail.is_free_lock);


    this.apiCallingService
      .callAPIwithFile(uploadData, "POST", ConfigApi.URLS.MY_ASSETS_UPDATE_LOCK)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  printComponent(cmpName: any) {
    const base64Img = document.getElementById(cmpName)
      ?.children[0] as HTMLCanvasElement;
    let dataURL = base64Img.toDataURL("image/jpeg");
    console.log(base64Img);
    let html =
      "<img src='" +
      dataURL +
      "' alt='from canvas'/>" +
      "<h2 style='margin-left: 52px;'>" +
      this.detail.lock_id +
      "</h2>";
    // let html = "<img src='" + dataURL + "' alt='from canvas'/>";
    let newTab = window.open("about:blank", "image from canvas");
    let k = newTab?.document.write(html);
    console.log(k);
    //newTab?.document.close();
    newTab?.focus();
    setTimeout(() => {
      newTab?.print();
      newTab?.close();
    }, 200);
  }
  removeMember(userid: any, index: any) {
    let users = {
      lock_id: this.detail._id,
      users: [{ user_id: userid }],
    };
    this.apiCallingService
      .callAPI(users, "POST", ConfigApi.URLS.REMOVE_MEMBER_FROM_LOCK)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Removed memeber successfully");
          this.search();
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  addMember(userid: any, index: any) {
    let users = {
      lock_id: this.detail._id,
      users: [{ user_id: userid }],
    };
    this.apiCallingService
      .callAPI(users, "POST", ConfigApi.URLS.ADD_MEMEBR_FOR_LOCK)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Added memeber successfully");
          this.search();
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  search() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.SEARCH_MEMEBR_FOR_LOCK +
        "?search=" +
        this.model1 +
        "&lock_id=" +
        this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          if (this.model1 !== "") {
            this.searchresult = res.userslists;
          } else {
            this.searchresult = [];
          }
        }
      });
  }

  getCustomerMarker(detail: any) {
    let imagepath, imageName;
    if (detail.isSharekayakLock) {
      if (detail.isSharekayakLock) {
        if (detail.in_use) {
          imageName = "rideRed";
        } else {
          imageName = "rideGreen";
        }
      } else {
        if (detail.in_use) {
          imageName = "rideHireRed";
        } else {
          imageName = "rideHire";
        }
      }
    } else {
      if (detail.in_use) {
        imageName = "rideRed";
      } else {
        imageName = "rideGreen";
      }
    }
    imagepath = "assets/map/" + imageName + "/icon.png";
    return imagepath;
  }
  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.MY_LEASE_DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  getId(id: any) {
    // this.apiCallingService.setter(id);
    this.router.navigate(["/user/business-analytics", id], {
      relativeTo: this.route,
    });
  }
  sortBy(property: string) {
    console.log(property);

    this.sortOrder = property === this.sortProperty ? (this.sortOrder * -1) : 1;
    this.sortProperty = property;
    this.data = [...this.data.sort((a: any, b: any) => {
      // sort comparison function
      let result = 0;
      if (a[property] < b[property]) {
        result = -1;
      }
      if (a[property] > b[property]) {
        result = 1;
      }
      return result * this.sortOrder;
    })];
  }

  sortIcon(property: string) {
    if (property === this.sortProperty) {
      return this.sortOrder === 1 ? '' : '';
    }
    return '';
  }

}
