<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Advance Booking
        </h4>
        <div class="d-flex mt-3">
          <a
            href="javascript: void(0);"
            class="btn btn-primary ml-auto"
            placement="top"
            (click)="openModal(editModal, {}, 'Create Booking Package')"
            ngbTooltip="Create Booking Package"
          >
            + Create
          </a>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4 table-responsive">
          <thead>
            <tr>
              <th scope="col">Action</th>
              <th scope="col">Title</th>
              <th scope="col">Booking Type</th>
              <th scope="col">Type</th>
              <th scope="col">Total Asset Allowed</th>
              <th scope="col">Duration</th>
              <th scope="col">Timing</th>
              <th scope="col">
                {{
                  this.operatorDetails &&
                  this.operatorDetails.tax_type &&
                  this.operatorDetails.tax_type
                    ? this.operatorDetails.tax_type
                    : "VAT"
                }}(%)
              </th>
              <th scope="col">Ending Picture Mandatory</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Check In Time</th>
              <th scope="col">Check Out Time</th>
              <th scope="col">Address</th>
              <th scope="col">
                Is
                {{
                  this.operatorDetails &&
                  this.operatorDetails.tax_type &&
                  this.operatorDetails.tax_type
                    ? this.operatorDetails.tax_type
                    : "VAT"
                }}
                Inclusive/Exclusive
              </th>
              <th scope="col">Enable Queue</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  *ngIf="d.qr_code"
                  (click)="openModal(editModal, d, 'QrCode')"
                  ngbTooltip="QrCode"
                >
                  <i class="fa fa-qrcode"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  (click)="openModal(editModal, d, 'View Booking')"
                  class="link mr-1"
                  placement="top"
                  ngbTooltip="View Booking"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  *ngIf="
                    d.booking_type !== 'self_serve' && d.enable_queue == true
                  "
                  (click)="openModal(editModal, d, 'Queue Dashboard')"
                  class="link mr-1"
                  placement="top"
                  ngbTooltip="Queue Dashboard"
                >
                  <i class="fa fa-users"></i>
                </a>
                <a
                  [routerLink]="['../time-slot/', d?._id]"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="Preview"
                >
                  <i class="fa fa-calendar"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  class="link mr-1"
                  placement="top"
                  (click)="openModal(editModal, d, 'Edit Package')"
                  ngbTooltip="Edit"
                  *ngIf="d.is_expired == true || d.isEditable == true"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Delete Package')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
              <td>{{ d.title }}</td>
              <td>
                {{
                  d.booking_type == "activities"
                    ? "Activities"
                    : d.booking_type == "self_serve"
                    ? "Self Serve"
                    : d.booking_type == "rentx"
                    ? "RentX"
                    : "-"
                }}
              </td>
              <td>
                {{ d.type ? d.type : "-" }}
              </td>
              <td>{{ d.total_asset_allow_in_pack }}</td>
              <td>{{ d.duration ? d.duration : "-" }}</td>
              <td>{{ d.timing ? d.timing : "-" }}</td>
              <td>{{ d.vat }}</td>
              <td>
                {{ d.is_ending_pic_mandatory == true ? "true" : "false" }}
              </td>
              <td>{{ d?.start_date | date : "medium" }}</td>
              <td>{{ d?.end_date | date : "medium" }}</td>
              <td>{{ d.check_in_time }}</td>
              <td>{{ d.check_out_time }}</td>
              <td>{{ d.address }}</td>
              <td *ngIf="d.isExclusive == false">Inclusive</td>
              <td *ngIf="d.isExclusive == true">Exclusive</td>

              <td>
                <ui-switch
                  *ngIf="d.booking_type !== 'self_serve'"
                  [(ngModel)]="d.enable_queue"
                  [ngModelOptions]="{ standalone: true }"
                  name="enable_queue"
                  #enable_queue="ngModel"
                  (click)="enableQueue(d)"
                >
                </ui-switch>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="16">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form
      *ngIf="
        modeltitle == 'Create Booking Package' || modeltitle == 'Edit Package'
      "
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      Package will decide how much rent you will get when a person hire your
      assets.

      <div class="card bookingModalCard my-3">
        <div class="card-header">Booking Type</div>
        <div class="card-body">
          <div class="form-group row">
            <label for="booking_type" class="col-sm-4 col-form-label"
              >Pack type</label
            >
            <div class="col-sm-8">
              <select
                class="form-control"
                #booking_type="ngModel"
                name="booking_type"
                [(ngModel)]="detail.booking_type"
              >
                <option *ngFor="let d of bookingType" value="{{ d.value }}">
                  {{ d.item }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="
              detail.booking_type == 'rentx' ||
              detail.booking_type == 'activities'
            "
          >
            <label for="category_type" class="col-sm-4 col-form-label"
              >Category</label
            >
            <div class="col-sm-8">
              <input
                class="form-control"
                name="category_name"
                (click)="toggleDropdown()"
                [(ngModel)]="detail.category_name"
                id="category_name"
                ngModel
                #category_name="ngModel"
                required
              />
              <ul class="dropdown-list" *ngIf="isOpen">
                <li
                  *ngFor="let option of category_data"
                  (click)="getSubCategoryList(option._id, option.category_name)"
                >
                  {{ option.category_name }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="
              detail.booking_type == 'rentx' ||
              detail.booking_type == 'activities'
            "
          >
            <label for="sub_category_type" class="col-sm-4 col-form-label"
              >Sub Category</label
            >
            <div class="col-sm-8">
              <input
                class="form-control"
                name="sub_category_name"
                (click)="toggleSubDropdown()"
                [(ngModel)]="detail.sub_category_name"
                id="sub_category_name"
                ngModel
                #sub_category_name="ngModel"
                required
              />
              <ul class="dropdown-list" *ngIf="isSubOpen">
                <li
                  *ngFor="let option of sub_category_data"
                  (click)="getSubList(option._id, option.sub_category_name)"
                >
                  {{ option.sub_category_name }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="detail.booking_type == 'self_serve'"
          >
            <label for="type" class="col-sm-4 col-form-label">Lock type</label>
            <div class="col-sm-8">
              <select
                class="form-control"
                #type="ngModel"
                name="type"
                [(ngModel)]="detail.type"
              >
                <option *ngFor="let type of locktypes">{{ type }}</option>
              </select>
              <span
                >Note : You cannot create multiple package with same asset type
                and timing</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card bookingModalCard">
        <div class="card-header">Booking Title & Banner</div>
        <div class="card-body">
          <div class="form-group row">
            <label for="title" class="col-sm-4 col-form-label">Title</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                name="title"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.title"
                id="title"
                ngModel
                #title="ngModel"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="poster_image" class="col-sm-4 col-form-label"
              >Profile image</label
            >
            <div class="col-sm-8">
              <input
                type="file"
                class="form-control-file"
                (change)="readURL($event)"
                accept="image/png, image/gif, image/jpeg,image/jpg"
                name="poster_image"
                ngModel
                #file="ngModel"
              />
              <img
                id="blah"
                *ngIf="imageSrc"
                width="100"
                [src]="imageSrc"
                class="previewImg"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card bookingModalCard">
        <div class="card-header">Date & Time</div>
        <div class="card-body">
          <div
            class="form-group row"
            *ngIf="detail.booking_type == 'activities'"
          >
            <div class="col-12">
              <div class="switch-container">
                <button
                  type="button"
                  class="btn-repeat"
                  (click)="setValue(true)"
                  [class.active]="valueRepeat === true"
                >
                  One Time
                </button>
                <button
                  type="button"
                  class="btn-repeat1"
                  (click)="setValue(false)"
                  [class.active]="valueRepeat === false"
                >
                  Repeat
                </button>
              </div>
            </div>
          </div>
          <div
            *ngIf="modeltitle == 'Create Booking Package'"
            class="form-group row"
          >
            <label class="col-sm-4 col-form-label"
              >Package start & End date</label
            >
            <div class="col-sm-4">
              <input
                type="date"
                class="form-control"
                onkeydown="return true"
                [(ngModel)]="detail.start_date"
                (change)="changeStartDate($event)"
                name="start_date"
                min="{{ startDate }}"
                id="start_date"
                ngModel
                #start_date="ngModel"
                required
              />
            </div>
            <div class="col-sm-4">
              <input
                type="date"
                class="form-control"
                onkeydown="return false"
                min="{{ startDate }}"
                [(ngModel)]="detail.end_date"
                (change)="changeEndDate($event)"
                name="end_date"
                id="end_date"
                ngModel
                #end_date="ngModel"
                required
              />
            </div>
          </div>

          <div *ngIf="modeltitle == 'Edit Package'" class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Package start & End date</label
            >
            <div class="col-sm-4">
              <input
                type="date"
                class="form-control"
                onkeydown="return true"
                [(ngModel)]="detail.start_date"
                min="{{ startDate }}"
                name="start_date"
                id="start_date"
                ngModel
                #start_date="ngModel"
                required
              />
            </div>
            <div class="col-sm-4">
              <input
                type="date"
                class="form-control"
                onkeydown="return false"
                [(ngModel)]="detail.end_date"
                min="{{ startDate }}"
                name="end_date"
                id="end_date"
                ngModel
                #end_date="ngModel"
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="check_in_time" class="col-sm-4 col-form-label"
              >Check-in/out hours</label
            >
            <div class="col-sm-4">
              <input
                name="check_in_time"
                [(ngModel)]="detail.check_in_time"
                type="time"
                class="form-control"
                required
              />
            </div>
            <div class="col-sm-4">
              <input
                name="check_out_time"
                [(ngModel)]="detail.check_out_time"
                type="time"
                class="form-control"
                required
              />
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="
              !valueRepeat ||
              detail.booking_type == 'rentx' ||
              detail.booking_type == 'self_serve'
            "
          >
            <label for="timing" class="col-sm-4 col-form-label">Timing</label>
            <div class="col-sm-8">
              <select
                class="form-control"
                #timing="ngModel"
                name="timing"
                [(ngModel)]="detail.timing"
              >
                <option *ngFor="let t of timings" value="{{ t }}">
                  {{ t }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="
              !valueRepeat ||
              detail.booking_type == 'rentx' ||
              detail.booking_type == 'self_serve'
            "
          >
            <label for="duration" class="col-sm-4 col-form-label"
              >Duration</label
            >
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                name="duration"
                [(ngModel)]="detail.duration"
                pattern="(\s*[^\s]+\s*)+"
                min="1"
                max="{{ maxValue }}"
                onKeyPress="if(this.value.length==3)return false;"
                id="duration"
                name="duration"
                *ngIf="detail.timing !== 'Hour'"
              />
              <select
                class="form-control"
                name="duration"
                [(ngModel)]="detail.duration"
                *ngIf="detail.timing == 'Hour'"
              >
                <option *ngFor="let t of duration" value="{{ t }}">
                  {{ t }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row" *ngIf="detail.timing == 'Hour'">
            <label for="duration" class="col-sm-2 col-form-label">Day</label>
            <div class="col-sm-10">
              <div class="btn-weekDays d-flex">
                <div *ngFor="let day of daysOfWeek">
                  <button
                    type="button"
                    class="btn btn-week"
                    [class.btn-uncheckd]="!selectedDaysArray.includes(day)"
                    [class.btn-secondary]="selectedDaysArray.includes(day)"
                    (click)="toggleDay(day)"
                  >
                    {{ day | titlecase }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card bookingModalCard">
        <div class="card-header">Pricing</div>
        <div class="card-body">
          <div class="form-group row">
            <label for="price" class="col-sm-4 col-form-label"
              >Fees ({{ currency }})</label
            >
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                name="price"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.price"
                id="price"
                name="price"
                min="1"
                ngModel
                #price="ngModel"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="security_deposit" class="col-sm-4 col-form-label"
              >Security Deposit ({{ currency }})</label
            >
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                name="security_deposit"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.security_deposit"
                id="base_security_deposit"
                min="0"
                name="security_deposit"
                ngModel
                #security_deposit="ngModel"
                required
              />
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="modeltitle == 'Create Booking Package'"
          >
            <label for="vat" class="col-sm-4 col-form-label"
              >{{
                this.operatorDetails &&
                this.operatorDetails.tax_type &&
                this.operatorDetails.tax_type
                  ? this.operatorDetails.tax_type
                  : "VAT"
              }}
              (%)</label
            >
            <div class="col-sm-8">
              <input
                type="double"
                class="form-control"
                name="vat"
                pattern="(\s*[^\s]+\s*)+"
                id="vat"
                [(ngModel)]="detail.vat"
                step="0.01"
                min="0"
                name="vat"
                ngModel
                #vat="ngModel"
                required
              />
            </div>
          </div>
          <div class="form-group row" *ngIf="modeltitle == 'Edit Package'">
            <label for="vat" class="col-sm-4 col-form-label"
              >{{
                this.operatorDetails &&
                this.operatorDetails.tax_type &&
                this.operatorDetails.tax_type
                  ? this.operatorDetails.tax_type
                  : "VAT"
              }}
              (%)</label
            >
            <div class="col-sm-8">
              <input
                type="double"
                class="form-control"
                name="vat"
                [(ngModel)]="detail.vat"
                pattern="(\s*[^\s]+\s*)+"
                id="vat"
                step="0.01"
                min="0"
                name="vat"
                ngModel
                #vat="ngModel"
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="timing" class="col-sm-4 col-form-label"
              >Is
              {{
                this.operatorDetails &&
                this.operatorDetails.tax_type &&
                this.operatorDetails.tax_type
                  ? this.operatorDetails.tax_type
                  : "VAT"
              }}
              inculsive or exclusive?</label
            >
            <div class="col-sm-8">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  [(ngModel)]="detail.isExclusive"
                  name="isExclusive"
                  id="inlineRadio1"
                  #isExclusive="ngModel"
                  [value]="false"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Inculsive</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  [(ngModel)]="detail.isExclusive"
                  name="isExclusive"
                  id="inlineRadio2"
                  [value]="true"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >Exclusive</label
                >
              </div>
            </div>
          </div>

          <div
            class="form-group row"
            *ngIf="detail.booking_type !== 'activities'"
          >
            <label for="extra_fee" class="col-sm-4 col-form-label"
              >Extra Fee per minute({{ currency }})</label
            >
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                name="extra_fee"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.extra_fee"
                id="extra_fee"
                name="extra_fee"
                ngModel
                #extra_fee="ngModel"
                required
              />
              <span
                >Note : You can specify how many late fees(per minute) you want
                for customers who don't end the pack on time. If you are not
                charge late fees,then add 0(Zero)</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card bookingModalCard" *ngIf="hideCountry">
        <div class="card-header">Location</div>
        <div class="card-body">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label" for="location"
              >Location</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                name="address"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.address"
                id="address"
                name="address"
                ngModel
                #address="ngModel"
                required
                (click)="openMapBoxModel(searchModal)"
              />
            </div>
          </div>
          <div class="form-group row" *ngIf="hideCountry">
            <label class="col-sm-4 col-form-label" for="country">Country</label>
            <div class="col-sm-8">
              <select
                class="form-control"
                [(ngModel)]="detail.country"
                name="country"
                id="country"
              >
                <option
                  *ngFor="let c of allcountry"
                  value="{{ c.name }}"
                  data="c"
                >
                  {{ c.name }} - {{ c.currencies[0].code }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card bookingModalCard">
        <div class="card-header">Allow in Pack</div>
        <div class="card-body">
          <div
            class="form-group row"
            *ngIf="detail.booking_type == 'self_serve'"
          >
            <label
              for="total_asset_allow_in_pack"
              class="col-sm-4 col-form-label"
              >Total asset allow in pack</label
            >
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                name="total_asset_allow_in_pack"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.total_asset_allow_in_pack"
                id="total_asset_allow_in_pack"
                name="total_asset_allow_in_pack"
                ngModel
                #total_asset_allow_in_pack="ngModel"
                required
              />
              <span
                >Note : You can specify how many assets you want included in
                this package for user's.</span
              >
            </div>
          </div>
          <div class="form-group row" *ngIf="detail.booking_type == 'rentx'">
            <label
              for="total_qtn_of_product_in_pack"
              class="col-sm-4 col-form-label"
              >Total available quantity of product in pack</label
            >
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                name="total_qtn_of_product_in_pack"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.total_qtn_of_product_in_pack"
                id="total_qtn_of_product_in_pack"
                name="total_qtn_of_product_in_pack"
                ngModel
                #total_qtn_of_product_in_pack="ngModel"
                required
              />
              <span
                >Note : You can specify how many quantity available of your
                product in this package for user's.</span
              >
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="detail.booking_type == 'activities'"
          >
            <label
              for="total_user_allow_in_pack"
              class="col-sm-4 col-form-label"
              >Total member allow in pack</label
            >
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                name="total_user_allow_in_pack"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="detail.total_user_allow_in_pack"
                id="total_user_allow_in_pack"
                name="total_user_allow_in_pack"
                ngModel
                #total_user_allow_in_pack="ngModel"
                required
              />
              <span
                >Note : You can specify how many members are allowed in this
                package.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card bookingModalCard">
        <div class="card-header">Available For</div>
        <div class="form-group p-3">
          <div *ngIf="brandSelectionError" class="text-danger p-1">
            Please select the app brand for which this package is available
          </div>
          <label *ngFor="let brand of brand_name" class="d-block">
            <input
              type="checkbox"
              [(ngModal)]="selectedOptions"
              [checked]="brand.checked"
              (change)="toggleBrandSelection(brand)"
            />

            {{ transformBrandName(brand.name) }}
          </label>
        </div>
      </div>

      <div class="card bookingModalCard">
        <div class="card-header">Other</div>
        <div class="card-body">
          <div class="form-group row">
            <label for="hashgroup" class="col-sm-4 col-form-label"
              >Hashtags</label
            >
            <div class="col-sm-8">
              <div class="hashGroupInputBox">
                <ul id="tags">
                  <li *ngFor="let item of hashArr">
                    <div class="hashItemList">
                      <p class="hashItemS">{{ item }}</p>
                      <p class="removeBtn" (click)="removeTag(item)">X</p>
                    </div>
                  </li>
                </ul>
                <input
                  type="text"
                  class="form-control"
                  name="hashTags"
                  pattern="(\s*[^\s]+\s*)+"
                  [(ngModel)]="hashTags"
                  id="hashTags"
                  (keydown)="pushData($event)"
                />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="description" class="col-sm-4 col-form-label"
              >Description</label
            >
            <div class="col-sm-8">
              <!-- <textarea class="form-control" [(ngModel)]="detail.pkg_desc" id="pkg_desc" name="pkg_desc" ngModel
                #pkg_desc="ngModel"></textarea> -->
              <!-- <ckeditor id="description" ngModel name="description" [(ngModel)]="detail.description"
                #description="ngModel" editorUrl="https://cdn.ckeditor.com/4.14.0/full/ckeditor.js"
                [config]="ckEditorConfig"></ckeditor> -->
              <ckeditor
                [config]="{
                  toolbar: [
                    'undo',
                    'redo',
                    '|',
                    'heading',
                    'fontFamily',
                    'fontSize',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'fontColor',
                    'fontBackgroundColor',
                    'highlight',
                    '|',
                    'alignment',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'indent',
                    'outdent',
                    '|',
                    'insertTable',
                    'blockQuote',
                    'specialCharacters'
                  ]
                }"
                [editor]="Editor"
                id="description"
                ngModel
                name="description"
                [(ngModel)]="detail.description"
                #description="ngModel"
                data="<p>{{
                  this.detail.description ? this.detail.description : ''
                }}</p>"
              >
              </ckeditor>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="detail.booking_type !== 'self_serve'"
          >
            <label class="col-sm-10 col-form-label">Enable queue?</label>
            <div class="col-sm-2">
              <ui-switch
                [(ngModel)]="detail.enable_queue"
                [ngModelOptions]="{ standalone: true }"
                name="enable_queue"
                #enable_queue="ngModel"
              ></ui-switch>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="detail.booking_type !== 'activities'"
          >
            <label class="col-sm-10 col-form-label"
              >Is it mandatory to take a picture at the end of the package for
              user?</label
            >
            <div class="col-sm-2">
              <ui-switch
                [(ngModel)]="detail.is_ending_pic_mandatory"
                [ngModelOptions]="{ standalone: true }"
                name="is_ending_pic_mandatory"
                #is_ending_pic_mandatory="ngModel"
              ></ui-switch>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-10 col-form-label"
              >Need to fill waiver form?</label
            >
            <div class="col-sm-2">
              <ui-switch
                [(ngModel)]="detail.isWaiverDisplay"
                [ngModelOptions]="{ standalone: true }"
                name="isWaiverDisplay"
                #isExclusive="ngModel"
              ></ui-switch>
            </div>
          </div>
          <div class="form-group row" *ngIf="detail.isWaiverDisplay">
            <label class="col-sm-10 col-form-label">Required License ?</label>
            <div class="col-sm-2">
              <ui-switch
                [(ngModel)]="detail.request_licence"
                [ngModelOptions]="{ standalone: true }"
                name="request_licence"
                #request_licence="ngModel"
                (ngModelChange)="onLicenseDisplayChange($event)"
              ></ui-switch>
            </div>
          </div>

          <div class="form-group row" *ngIf="detail.request_licence">
            <label class="col-sm-4 col-form-label">License Type ?</label>
            <div class="col-sm-8">
              <select
                class="form-control"
                #license_type="ngModel"
                name="license_type"
                [(ngModel)]="detail.license_type"
              >
                <option *ngFor="let type of licenseTypelist">{{ type }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-10 col-form-label"
              >User information required ? (On-Demand)</label
            >
            <div class="col-sm-2">
              <ui-switch
                [(ngModel)]="detail.is_user_info_required"
                [ngModelOptions]="{ standalone: true }"
                name="is_user_info_required"
                #is_user_info_required="ngModel"
              ></ui-switch>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="detail.booking_type == 'activities'"
          >
            <label class="col-sm-10 col-form-label">Enable Pay Later ? </label>
            <div class="col-sm-2">
              <ui-switch
                [(ngModel)]="detail.enable_pay_later"
                [ngModelOptions]="{ standalone: true }"
                name="enable_pay_later"
                #enable_pay_later="ngModel"
              ></ui-switch>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-10 col-form-label"
              >Present safety instruction video ?</label
            >
            <div class="col-sm-2">
              <ui-switch
                [(ngModel)]="detail.enable_video"
                [ngModelOptions]="{ standalone: true }"
                name="enable_video"
                #enable_video="ngModel"
              ></ui-switch>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label"
              >Safety instruction Link
            </label>
            <div class="col-sm-6">
              <input
                class="form-control"
                type="text"
                name="video_link"
                id="video_link"
                ngModel
                [(ngModel)]="detail.video_link"
                [required]="detail.enable_video"
                #video_link="ngModel"
              />
              <div
                *ngIf="
                  detail.enable_video &&
                  video_link.invalid &&
                  (video_link.dirty || video_link.touched)
                "
                class="text-danger"
              >
                <div *ngIf="video_link.errors?.required">
                  Video link is required when video is enabled.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>

    <!-- Queue Model -->

    <form [FormGroup]="editUser" *ngIf="modeltitle == 'Delete Package'">
      Are you sure want to Delete this Package ?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="deleteData()">
          Delete
        </button>
      </div>
    </form>
    <form [FormGroup]="editUser" *ngIf="modeltitle == 'QrCode'">
      <div class="modal-body">
        <img class="img-fluid" id="blah" width="430" [src]="detail.qr_code" />
      </div>
    </form>
  </div>
  <div class="modal-body" *ngIf="modeltitle == 'View Booking'">
    <div class="row">
      <div class="col-sm-6" *ngIf="detail.poster_image">
        <img
          id="blah"
          style="border-radius: 5px"
          *ngIf="detail.poster_image"
          width="200"
          [src]="detail.poster_image"
          class="img-fluid"
        />
      </div>
      <div class="col-sm-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Title:</label>
          <div class="col-sm-8 col-form-label">
            {{ detail?.title }}
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Booking Type:</label>
          <div class="col-sm-6 col-form-label">
            {{
              detail.booking_type == "activities"
                ? "Activities"
                : detail.booking_type == "self_serve"
                ? "Self Serve"
                : detail.booking_type == "rentx"
                ? "Rentx"
                : "-"
            }}
          </div>
        </div>
        <div class="form-group row" *ngIf="detail.booking_type == 'self_serve'">
          <label class="col-sm-4 col-form-label">Type:</label>
          <div class="col-sm-6 col-form-label">
            {{ detail.type }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="form-group row"
      *ngIf="
        detail.booking_type == 'activities' || detail.booking_type == 'rentx'
      "
    >
      <label class="col-sm-2 col-form-label">Category:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail.category_name }}
      </div>
      <label class="col-sm-3 col-form-label">Sub Category Name:</label>
      <div class="col-sm-3 col-form-label">
        {{ detail.sub_category_name }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Duration:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail.duration ? detail.duration : "-" }}
      </div>
      <label class="col-sm-2 col-form-label">Timing:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail.timing }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label"
        >{{
          this.operatorDetails &&
          this.operatorDetails.tax_type &&
          this.operatorDetails.tax_type
            ? this.operatorDetails.tax_type
            : "VAT"
        }}
        (%):</label
      >
      <div class="col-sm-4 col-form-label">
        {{ detail.vat }}
      </div>
      <label class="col-sm-2 col-form-label">Ending Picture Mandatory:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail.is_ending_pic_mandatory == true ? "true" : "false" }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Start Date:</label>
      <div class="col-sm-3.5 col-form-label">
        {{ detail.start_date | date : "short" }}
      </div>
      <label class="col-sm-3 col-form-label">Check In time:</label>
      <div class="col-sm-3 col-form-label">
        {{ detail.check_in_time }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">End Date:</label>
      <div class="col-sm-3.5 col-form-label">
        {{ detail.end_date | date : "short" }}
      </div>
      <label class="col-sm-3 col-form-label">Check out time:</label>
      <div class="col-sm-2 col-form-label">
        {{ detail.check_out_time }}
      </div>
    </div>
    <div class="form-group row" *ngIf="detail.booking_type == 'self_serve'">
      <label class="col-sm-4 col-form-label">Total Asset Allowed:</label>
      <div class="col-sm-8 col-form-label">
        {{ detail.total_asset_allow_in_pack }}
      </div>
    </div>
    <div class="form-group row" *ngIf="detail.booking_type == 'rentx'">
      <label class="col-sm-4 col-form-label">Total Asset Allowed:</label>
      <div class="col-sm-8 col-form-label">
        {{ detail.total_qtn_of_product_in_pack }}
      </div>
    </div>
    <div class="form-group row" *ngIf="detail.booking_type == 'activities'">
      <label class="col-sm-4 col-form-label">Total Asset Allowed:</label>
      <div class="col-sm-8 col-form-label">
        {{ detail.total_user_allow_in_pack }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label"
        >Is
        {{
          this.operatorDetails &&
          this.operatorDetails.tax_type &&
          this.operatorDetails.tax_type
            ? this.operatorDetails.tax_type
            : "VAT"
        }}
        Inclusive/Exclusive:</label
      >
      <div class="col-sm-8 col-form-label">
        {{ detail.isExclusive == false ? "Inclusive" : "Exclusive" }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Address:</label>
      <div class="col-sm-8 col-form-label">
        {{ detail.address }}
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
        Close
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="modeltitle == 'Queue Dashboard'">
    <div class="card bookingModalCard my-3">
      <div class="col-12">
        <dl class="row">
          <div class="fees-queue">
            {{ detail.price }}.00 {{ currency }}<br />For
            {{ detail.duration }}-{{ detail.timing }}
          </div>
          <img
            id="blah"
            *ngIf="detail?.poster_image"
            width="100%"
            height="200px !important"
            [src]="detail?.poster_image"
            style="border-top-left-radius: 10px; border-top-right-radius: 10px"
          />
        </dl>
        <h3>{{ detail.title }}</h3>
        <dl class="row">
          <dt class="col-sm-4">Operator</dt>
          <dd class="col-sm-6">
            {{ detail?.operatorDetails?.username }}
          </dd>

          <dt class="col-sm-4">Package Type</dt>
          <dd class="col-sm-6">
            {{
              detail.booking_type == "activities"
                ? "Activities"
                : detail.booking_type == "self_serve"
                ? "Self Serve"
                : detail.booking_type == "rentx"
                ? "RentX"
                : "-"
            }}
          </dd>
          <dt class="col-sm-4" *ngIf="detail.booking_type == 'self_serve'">
            Type
          </dt>
          <dd class="col-sm-6" *ngIf="detail.booking_type == 'self_serve'">
            {{ detail.type }}
          </dd>
          <dt
            class="col-sm-4"
            *ngIf="
              detail.booking_type == 'activities' ||
              detail.booking_type == 'rentx'
            "
          >
            Category
          </dt>
          <dd
            class="col-sm-6"
            *ngIf="
              detail.booking_type == 'activities' ||
              detail.booking_type == 'rentx'
            "
          >
            {{ detail.category_name }}
          </dd>
          <dt
            class="col-sm-4"
            *ngIf="
              detail.booking_type == 'activities' ||
              detail.booking_type == 'rentx'
            "
          >
            Sub-Category
          </dt>
          <dd
            class="col-sm-5"
            *ngIf="
              detail.booking_type == 'activities' ||
              detail.booking_type == 'rentx'
            "
          >
            {{ detail.sub_category_name }}
          </dd>
          <dt class="col-sm-4">Start Date</dt>
          <dd class="col-sm-6">{{ detail.start_date | date }}</dd>
          <dt class="col-sm-4">End Date</dt>
          <dd class="col-sm-5">{{ detail.end_date | date }}</dd>
          <dt class="col-sm-4">Start Time</dt>
          <dd class="col-sm-6">{{ detail.start_date | date : "shortTime" }}</dd>
          <dt class="col-sm-4">End Time</dt>
          <dd class="col-sm-5">{{ detail.end_date | date : "shortTime" }}</dd>
        </dl>
        <div class="address d-flex">
          <i class="mdi mdi-map-marker-multiple h5 mr-2"></i>
          <p class="h5">{{ detail?.address }}</p>
        </div>
      </div>
    </div>
    <div class="card bookingModalCard my-3">
      <div class="mod">
        <div class="mod-header">
          <h4 style="margin-top: 12px">NOW SERVING</h4>
          <span class="number">{{ now_serving }}</span>
        </div>
        <div class="mod-body">
          <div class="now-serving">
            <h4 style="margin-top: 12px">NEXT UP</h4>
            <span class="number">{{ next_number_of_queue }}</span>
          </div>
          <div class="next-up">
            <h4 style="margin-top: 12px">IN QUEUE</h4>
            <span class="number">{{ in_queue }}</span>
          </div>
        </div>
      </div>
      <div class="button-set">
        <button
          type="button"
          style="background-color: #d5d5d5; color: black"
          class="buttons"
          (click)="queueModel(queueModels)"
        >
          Manual
        </button>
        <button
          type="button"
          style="background-color: #212121; color: white"
          class="buttons"
          (click)="nextNumberQueue()"
        >
          Next
        </button>
      </div>
      <div class="button-set">
        <button
          type="button"
          class="btn btn-link"
          [routerLink]="['../all-queuers/', detail._id]"
          (click)="closeBtnClick()"
        >
          All Queuers
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #searchModal let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Search Your Location:
    <div class="map" id="map" class="match-parent"></div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-primary"
      (click)="saveLocation()"
      (click)="modal.dismiss('Cross click')"
      [disabled]="!mainObj"
    >
      Save
    </button>
  </div>
</ng-template>
<ng-template #queueModels let-modal>
  <form #specialityform="ngForm">
    <div class="modal-header">
      <h5 class="modal-title" id="editUserLabel">Manual Queue Number</h5>

      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-10">
      <div class="card-body">
        Notify that customer before taking the queue number manually.

        <div class="form-group row">
          <label for="queue_number" class="col-sm-4 col-form-label"
            >Queue Number</label
          >
          <div class="col-sm-8">
            <input
              type="number"
              class="form-control"
              name="queue_number"
              pattern="(\s*[^\s]+\s*)+"
              [(ngModel)]="detail.queue_number"
              id="queue_number"
              ngModel
              #queue_number="ngModel"
              required
            />
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.dismiss('Cross click')"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-secondary"
        (click)="manualNumberQueue()"
        [disabled]="!specialityform.valid"
      >
        Completed
      </button>
    </div>
  </form>
</ng-template>
<!-- <ng-template #nextModels let-modal>
  <form #specialityform="ngForm">

    <div class="card-body">
      <h4 style="text-align: center;">Queue Not Found!</h4>
      <div style="text-align: center;"><button type="button" class="next-buttons"
          (click)="modal.dismiss('Cross click')">
          Ok
        </button></div>
    </div>
  </form>
</ng-template> -->
