import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { Globals } from '../../common/global';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-track-assets',
  templateUrl: './track-assets.component.html',
  styleUrls: ['./track-assets.component.css']
})
export class TrackAssetsComponent implements OnInit {

  constructor(private apiCallingService: ApiCallingService, private modalService: NgbModal, public Globals: Globals
  ) { }

  ngOnInit(): void {
    this.getList();
  }
  modeltitle: any;
  page = 0;
  pageSize = 20;
  data = [];
  totalcount = 0;
  detail: any;
  type: string = 'Rent';
  book_type: string = 'advance_booking';

  status: string = 'Active';
  map: any;

  getList() {
    this.data = [];
    let body;
    if (this.type == 'Rent') {
      body = new HttpParams()
        .set('owner_id', this.Globals.getUser()._id)
        .set('in_use', "true")
        .set('limit', this.pageSize)
        .set('page', this.page)
        .set('type', this.type);
    }
    else if (this.type == 'Advance_Book') {
      body = new HttpParams()
        .set('owner_id', this.Globals.getUser()._id)
        .set('in_use', "true")
        .set('limit', this.pageSize)
        .set('page', this.page)
        .set('type', this.book_type);
    } else {
      body = new HttpParams()
        .set('owner_id', this.Globals.getUser()._id)
        .set('limit', this.pageSize)
        .set('page', this.page)
        .set('type', this.type);
    }
    this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.TRACK_ASSETS.LIST)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          //this.totalcount =  res.total;
        }
      });
  }

  endRide(data: any) {

    const body = new HttpParams()
      .set('ride_id', data.rideDetails._id)
      .set('end_battery_percentage', data.battery_percentage)
      .set('end_longitude', data.longitude)
      .set('end_latitude', data.latitude)
      .set('is_forced', false);
    this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.TRACK_ASSETS.END_RIDE)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.getList();
        }
      });

  }
  cancelPackage(data: any) {
    const body = new HttpParams()
      .set('booking_purchase_id', data.rideDetails._id)

    this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.TRACK_ASSETS.END_PACK)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.getList();
        }
      });
  }
  loadPage(page: number, type: string = "") {
    this.type = type ? type : this.type;
    this.page = page - 1;
    this.getList();
  }

  changeStatus(status: string) {
    this.status = status;
    this.getList();
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);

    console.log(this.detail);

    setTimeout(() => {
      (mapboxgl as any).accessToken = environment.mapbox.accessToken;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: 13,
        center: this.detail.location.coordinates
      });

      const el = document.createElement('div');
      el.className = 'marker';
      let markerUrl;
      if (this.detail.on_lease) {
        markerUrl = "assets/map/rideHire/icon.png";
      } else {
        markerUrl = "assets/map/rideHireRed/icon.png";
      }

      el.style.backgroundImage = `url('${markerUrl}')`;
      el.style.width = `40px`;
      el.style.height = `40px`;
      el.style.backgroundSize = '100%';

      // Set popup content 
      let htmlofPopup;
      if (this.detail.on_lease) {
        htmlofPopup =
          '<img src="' + this.detail.lock_image + '" width="50"><br>' +
          '<b>' + this.detail.lock_name + ' (' + this.detail.lock_type + ')</b><br>' +
          '<b>Lease : </b>' + this.detail.owner_id.name + '<img src="assets/images/battery.png" width="20">' + this.detail.battery_percentage + '% <br>' +
          '<b>Address : </b>' + this.detail.lock_address;
      } else {
        htmlofPopup =
          '<img src="' + this.detail.lock_image + '" width="50"><br>' +
          '<b>' + this.detail.lock_name + ' (' + this.detail.lock_type + ')</b><br>' +
          '<b>Rider : </b>' + this.detail.used_by.name + '<img src="assets/images/battery.png" width="20">' + this.detail.battery_percentage + '% <br>' +
          '<b>Address : </b>' + this.detail.lock_address;
      }

      let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlofPopup);
      // Add markers to the map.
      let m = new mapboxgl.Marker(el)
        .setLngLat(this.detail.location.coordinates)
        .setPopup(popup)
        .addTo(this.map);
    })

  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
}

