import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { NgForm } from "@angular/forms";
import { Globals } from "../../common/global";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-waiver-forms",
  templateUrl: "./waiver-forms.component.html",
  styleUrls: ["./waiver-forms.component.css"],
})
export class WaiverFormsComponent implements OnInit {
  operator_details: any;
  getUser: any;

  modeltitle: any;
  page = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  status = [
    {
      item: "approved",
      value: "approved",
    },
    {
      item: "rejected",
      value: "rejected",
    },
    {
      item: "pending",
      value: "pending",
    },
  ];
  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    public http: HttpClient,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    const operatorDetailsString = localStorage.getItem("operator_details");
    const userDetail = localStorage.getItem("Auth-user");

    if (operatorDetailsString) {
      this.operator_details = JSON.parse(operatorDetailsString);
    }
    if (userDetail) {
      this.getUser = JSON.parse(userDetail);
    }
    this.getList();
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    let page = this.page == 0 ? 0 : this.page - 1;

    // const query =
    //   "?user_id=" +
    //   user_id +
    //   "&limit=10&page=" +
    //   (this.page == 0 ? 0 : this.page - 1);
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.WAIVER_FORM.LIST +
          "/" +
          user_id +
          "?page=" +
          page +
          "&limit=" +
          this.pageSize
      )
      .subscribe((res) => {
        this.data = res.data;
        this.totalcount = res.total;
      });
  }

  loadPage(page: number) {
    this.page = page;
    this.getList();
  }
  image: any;
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
      size: title == "Update License" ? "md" : "lg",
    });

    this.modeltitle = title;
    this.detail = Object.assign({}, data);
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }

  checkForEdit(val: any) {
    if (val.license_id) {
      let licensedata = val.license_id;
      if (licensedata.verified_by) {
        return (
          licensedata.verified_by.toString() == this.getUser._id.toString()
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  updateLicenseStatus(f: any) {
    let reason =
      f?.value?.reject_reason == "" || f?.value?.reject_reason == null
        ? ""
        : f?.value?.reject_reason;
    if (f?.value.verification_status != "rejected") {
      reason = "";
    }
    console.log(this.detail);

    const body = new HttpParams()
      .set("verification_status", f.value.verification_status)
      .set("operator_id", this.operator_details?.user_id)
      .set("reject_reason", reason);

    let url = ConfigApi.URLS.LICENSE.UPDATE;
    let method = "POST";

    if (this.modeltitle == "Update License") {
      url = ConfigApi.URLS.LICENSE.UPDATE + "/" + this.detail?.license_id?._id;
      method = "POST";
    }
    this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
}
