<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title">Purchase history</h4>
      </div>
      <ngb-tabset>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <div (click)="loadPage(1, 'Rent')">Rent</div>
          </ng-template>
          <ng-template ngbTabContent> </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <div (click)="loadPage(1, 'Lease')">Book</div>
          </ng-template>
          <ng-template ngbTabContent> </ng-template>
        </ngb-tab>
      </ngb-tabset>
      <div class="d-flex mb-3 mt-3">
        <div class="col-md-6">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" [checked]="status == 'Active'"
              id="inlineRadio1" value="option1" (click)="changeStatus('Active')" />
            <label class="form-check-label" for="inlineRadio1">Active</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" [checked]="status == 'Expired'"
              id="inlineRadio2" value="option2" (click)="changeStatus('Expired')" />
            <label class="form-check-label" for="inlineRadio2">Expired</label>
          </div>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Operator</th>
              <th scope="col">Puchased on</th>
              <th scope="col">Expired on</th>
              <th scope="col">Week plan at</th>
              <th scope="col">Type</th>
              <!-- <th scope="col">Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.business_info?.name }}</td>
              <td>{{ d?.start_date | date : "medium" }}</td>
              <td>{{ d?.end_date | date : "medium" }}</td>
              <td>{{ d?.price }} {{ d?.currency?.code }}</td>
              <td>{{ d?.type }}</td>
              <!-- <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td> -->
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="5">No data found</td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="d-flex justify-content-center mt-5">
            <ngb-pagination  [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount"  [maxSize]="7" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
          </div> -->
      </div>
    </div>
  </div>
</div>