import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../common/global";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-package-addon",
  templateUrl: "./package-addon.component.html",
  styleUrls: ["./package-addon.component.css"],
})
export class PackageAddonComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  modeltitle: any;
  detail: any = {
    price: null,
    barcode_id: "",
    is_available: true,
  };
  imgfile: File | null = null;
  page = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  selectedFile: File | null = null;
  imageSrc: string | ArrayBuffer | null = null;
  operatorDetails: any = {};
  bookingType: any = [];
  user: any;

  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    let operator = localStorage.getItem("operator_details");
    if (operator) {
      try {
        this.operatorDetails = JSON.parse(operator);
      } catch (e) {
        console.error("Failed to parse operator details:", e);
      }
    }
    this.getPackageList();
    this.getList();
    let user = localStorage.getItem("Auth-user");
    if (user) {
      this.user = JSON.parse(user);
    }
  }
  getPackageList() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.PACKAGE_ADDON.PACKAGE_LIST +
          "?operator_id=" +
          this.operatorDetails?.user_id
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.bookingType = res.data;
            this.totalcount = res.total;
          } else {
            // this.toastr.error(res.msg);
            console.log(res.msg);
          }
        },
        (err) => {
          console.log(err.error.msg);
          // this.toastr.error(err.error.msg, "Error");
        }
      );
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }
  getList() {
    let page = this.page == 0 ? 0 : this.page - 1;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.PACKAGE_ADDON.GET_LIST +
          "?page=" +
          page +
          "&limit=" +
          this.pageSize
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.data = res.data;
            this.totalcount = res.total;
          } else {
            console.log(res.msg);
          }
        },
        (err) => {
          // this.toastr.error(err.error.msg, "Error");
          console.log(err.error.msg);
        }
      );
  }
  loadPage(page: number) {
    if (page) {
      this.page = page;
      this.getList();
    }
  }

  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.detail = Object.assign({}, data);
    this.modeltitle = title;
    if (this.modeltitle === "Create Package Addon") {
      this.detail.is_available = true;
    }
    if (this.modeltitle === "Edit Package Addon") {
      this.imageSrc = this.detail.image;
      this.detail.price = this.price(this.detail.price);
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }

  onCsvSubmit() {
    if (!this.selectedFile) {
      this.toastr.error("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("barcode_csv_file", this.selectedFile);
    formData.append("country_iso_code", this.user?.countryObject?.iso);

    this.apiCallingService
      .callAPI(formData, "POST", ConfigApi.URLS.PACKAGE_ADDON.UPLOAD_CSV)
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success("File uploaded successfully!");
            this.modalService.dismissAll();
            this.getList();
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.imgfile = file;
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
      this.imageSrc = file;
    }
  }
  onSubmit(f: any) {
    let uploadData = new FormData();
    if (this.imgfile) {
      uploadData.append("image", this.imgfile, this.imgfile.name);
    }
    uploadData.append("country_iso_code", this.user?.countryObject?.iso);
    uploadData.append("price", f.value.price);
    uploadData.append("title", f.value.title);
    uploadData.append("barcode_id", f.value.barcode_id);
    uploadData.append(
      "is_available",
      f.value.is_available == undefined ? false : f.value.is_available
    );
    if (f.value.package_id != undefined) {
      uploadData.append("package_id", f.value.package_id);
    }

    if (this.modeltitle == "Edit Package Addon") {
      let url = ConfigApi.URLS.PACKAGE_ADDON.EDIT + "/" + this.detail._id;
      let method = "PUT";
      this.apiCallingService.callAPI(uploadData, method, url).subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeBtnClick();
            this.getList();
            this.imageSrc = "";
            this.imgfile = null;
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
    } else {
      let url = ConfigApi.URLS.PACKAGE_ADDON.CREATE;
      let method = "POST";
      this.apiCallingService.callAPI(uploadData, method, url).subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeBtnClick();
            this.getList();
            this.imageSrc = "";
            this.imgfile = null;
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
    }
  }

  deleteData(id: any) {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.PACKAGE_ADDON.DELETE + "/" + this.detail._id
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.closeBtnClick();
            this.getList();
          }
        },
        (err) => {
          this.closeBtnClick();
          this.ngOnInit();
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }
  price(val: any) {
    return parseFloat(val) / 100;
  }
  trimLeadingWhitespace(value: any): string {
    return value.trimStart();
  }
}
