import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, startWith } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbServiceService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  getBreadcrumbs(): Observable<any[]> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(this.router), // Trigger initial load
      map(() => {
        let route = this.route.root;
        let breadcrumbs: any[] = [];
        while (route.children.length) {
          route = route.children[0];
          if (route?.snapshot?.data["breadcrumb"]) {
            breadcrumbs = [
              ...breadcrumbs,
              ...route.snapshot.data["breadcrumb"],
            ];
          }
        }
        return breadcrumbs;
      })
    );
  }
}
