import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { QuillModule } from "ngx-quill";

import { AdminRoutingModule } from "./admin-routing.module";

import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { QrCodeModule } from "ng-qrcode";
import { NgxPrintModule } from "ngx-print";

import { TermConditionsComponent } from "./term-conditions/term-conditions.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProfileComponent } from "./profile/profile.component";

import { SubscriptionComponent } from "./subscription/subscription.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { FaqComponent } from "./faq/faq.component";
import { TransactionsComponent } from "./transactions/transactions.component";
import { CustomerReviewComponent } from "./customer-review/review.component";
import { RideHistoryComponent } from "./ride-history/ride-history.component";
import { PurchaseHistoryComponent } from "./purchase-history/purchase-history.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { OperatorSupportComponent } from "./operator-support/operator-support.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { MyAssetsComponent } from "./my-assets/my-assets.component";
import { BusinessProfileComponent } from "./business-profile/business-profile.component";
import { RentPackageComponent } from "./rent-package/rent-package.component";
import { LeasePackageComponent } from "./lease-package/lease-package.component";
import { SalePackageComponent } from "./sale-package/sale-package.component";
import { TrackAssetsComponent } from "./track-assets/track-assets.component";
import { PaymentMethodComponent } from "./payment-method/payment-method.component";
import { DetailsComponent } from "./ride-history/details/details.component";
import { OperationComponent } from "./operation/operation.component";
import { UiSwitchModule } from "ngx-toggle-switch";
import { DetailmyassetComponent } from "./my-assets/detailmyasset/detailmyasset.component";
import { HomeComponent } from "./home/home.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { AddPaymentComponent } from "./add-payment/add-payment.component";
import { WaiverComponent } from "./waiver/waiver.component";
import { CouponsComponent } from "./coupons/coupons.component";
import { UsedCouponsComponent } from "./used-coupons/used-coupons.component";
import { WaiverFormsComponent } from "./waiver-forms/waiver-forms.component";
import { GeofenceMapComponent } from "./geofence-map/geofence-map.component";
import { PluddaComponent } from "./pludda/pludda.component";
import { PluddaDetailsComponent } from "./pludda/pludda-details/pludda-details.component";
import { ExternalApiComponent } from "./external-api/external-api.component";
import { ClipboardModule } from "ngx-clipboard";
import { GeofenceListComponent } from "./geofence-list/geofence-list.component";
import { BoundaryComponent } from "./geofence-list/boundary/boundary.component";

import {
  PercentageDirective,
  PercentageDirectiveModule,
} from "../shared/directives/percentage.directive";

import { MyAssetRideHistoryComponent } from "./my-asset-ride-history/my-asset-ride-history.component";
import { RideDetailsComponent } from "./my-asset-ride-history/ride-details/ride-details.component";
import { NgImageSliderModule } from "ng-image-slider";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { FrekisTermComponent } from "./frekis-term/frekis-term.component";
import { OdeTermComponent } from "./ode-term/ode-term.component";
import { SharekayakTermComponent } from "./sharekayak-term/sharekayak-term.component";
import { ParkingViolationComponent } from "./parking-violation/parking-violation.component";
import { ParkingViolationDetailComponent } from "./parking-violation/parking-violation-detail/parking-violation-detail.component";
import { SaleCouponsComponent } from "./sale-coupons/sale-coupons.component";
import { LeaseCouponsComponent } from "./lease-coupons/lease-coupons.component";
import { LeaseHistoryComponent } from "./lease-history/lease-history.component";
import { LockFallbackComponent } from "./lock-fallback/lock-fallback.component";
import { RequestPaymentComponent } from "./request-payment/request-payment.component";
import { BusinessAnalyticsComponent } from "./my-assets/business-analytics/business-analytics.component";
import { AdvanceBookingComponent } from "./advance-booking/advance-booking.component";
import { UpcomingBookingComponent } from "./upcoming-booking/upcoming-booking.component";
import { AdvanceBookCouponsComponent } from "./advance-book-coupons/advance-book-coupons.component";
import { MapComponent } from "./map/map.component";
import { FlatpickrModule } from "angularx-flatpickr";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AllQueuersComponent } from "./all-queuers/all-queuers.component";
// import { CKEditorModule } from 'ckeditor4-angular';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { PayLaterPaymentComponent } from "./pay-later-payment/pay-later-payment.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { PackageAddonComponent } from './package-addon/package-addon.component';
import { LicenseComponent } from './license/license.component';
@NgModule({
  imports: [
    NgImageSliderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(AdminRoutingModule),
    NgbModule,
    NgxIntlTelInputModule,
    NgbModalModule,
    HttpClientModule,
    UiSwitchModule,
    QrCodeModule,
    NgxPrintModule,
    NgApexchartsModule,
    PercentageDirectiveModule,
    QuillModule.forRoot(),
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
  ],
  declarations: [
    TermConditionsComponent,
    DashboardComponent,
    ProfileComponent,
    SubscriptionComponent,

    PrivacyPolicyComponent,
    FaqComponent,
    TransactionsComponent,
    CustomerReviewComponent,
    RideHistoryComponent,
    PurchaseHistoryComponent,
    NotificationsComponent,
    OperatorSupportComponent,
    FeedbackComponent,
    MyAssetsComponent,
    BusinessProfileComponent,
    RentPackageComponent,
    LeasePackageComponent,
    SalePackageComponent,
    TrackAssetsComponent,
    PaymentMethodComponent,
    DetailsComponent,
    OperationComponent,
    DetailmyassetComponent,
    HomeComponent,
    AddPaymentComponent,
    WaiverComponent,
    CouponsComponent,
    UsedCouponsComponent,
    WaiverFormsComponent,
    GeofenceMapComponent,
    PluddaComponent,
    PluddaDetailsComponent,
    MyAssetRideHistoryComponent,
    RideDetailsComponent,
    ExternalApiComponent,
    GeofenceListComponent,
    BoundaryComponent,
    FrekisTermComponent,
    SharekayakTermComponent,
    OdeTermComponent,
    ParkingViolationComponent,
    ParkingViolationDetailComponent,
    SaleCouponsComponent,
    LeaseCouponsComponent,
    LeaseHistoryComponent,
    LockFallbackComponent,
    RequestPaymentComponent,
    BusinessAnalyticsComponent,
    AdvanceBookingComponent,
    UpcomingBookingComponent,
    AdvanceBookCouponsComponent,
    MapComponent,
    AllQueuersComponent,
    PayLaterPaymentComponent,
    PackageAddonComponent,
    LicenseComponent,
  ],
  providers: [DecimalPipe, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
