<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title mb-0 ">Bussiness Profile</h4>
            </div>
            <div class="card-body">
                <form class="form" (ngSubmit)="onSubmitGenral(profileform)" #profileform="ngForm">

                    <div class="form-group row">
                        <label for="image" class="col-sm-4 col-form-label">Profile image</label>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control" pattern="(\s*[^\s]+\s*)+"   [(ngModel)]="detail.image" id="image" name="image" ngModel
                          required   #image="ngModel"> -->
                            <input type="file" class="form-control-file" (change)="readURL($event);"
                                accept="image/png, image/gif, image/jpeg" name="file" ngModel #file="ngModel">
                            <img id="blah" *ngIf="imageSrc" width="100" [src]="imageSrc" />

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="registration_number">Registration number</label>
                                <input type="text" class="form-control" [(ngModel)]="user.registration_number"
                                    name="registration_number" ngModel required #registration_number="ngModel">
                                <small class="form-text text-danger"
                                    *ngIf="!registration_number.valid && (registration_number.dirty || registration_number.touched)">Please
                                    enter registration_number!</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="companyName">Company name</label>
                                <input type="text" class="form-control" [(ngModel)]="user.companyName"
                                    name="companyName" ngModel required #companyName="ngModel">
                                <small class="form-text text-danger"
                                    *ngIf="!companyName.valid && (companyName.dirty || companyName.touched)">Please
                                    enter a company name!</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="organizationNumber">Organization number</label>
                                <input type="text" class="form-control" [(ngModel)]="user.organizationNumber"
                                    name="organizationNumber" ngModel required organizationNumber
                                    #organizationNumber="ngModel">
                                <small class="form-text text-danger"
                                    *ngIf="!organizationNumber.valid && (organizationNumber.dirty || organizationNumber.touched)">Please
                                    enter a
                                    organization number!</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="VATId">VAT Id</label>
                                <input type="text" class="form-control" [(ngModel)]="user.VATId" name="VATId" ngModel
                                    required VATId #VATId="ngModel">
                                <small class="form-text text-danger"
                                    *ngIf="!VATId.valid && (VATId.dirty || VATId.touched)">Please enter a
                                    valid VAT id!</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="country">Country</label>
                                <input type="text" class="form-control" [(ngModel)]="user.country" id="country"
                                    name="country" ngModel country #country="ngModel">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="username">Username</label>
                                <input type="text" class="form-control" [(ngModel)]="user.username" id="username"
                                    name="username" ngModel username #username="ngModel">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="phone_number">Phone number</label>
                                <input type="number" class="form-control" [(ngModel)]="user.phone_number"
                                    name="phone_number" ngModel phone_number #phone_number="ngModel">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="username">Email</label>
                                <input type="email" class="form-control" [(ngModel)]="user.email" name="email" ngModel
                                    email #email="ngModel">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="tax_type" class="col-sm-4 col-form-label">Tax Types</label>
                                <select class="form-control" #tax_type="ngModel" name="tax_type"
                                    [(ngModel)]="user.tax_type" (change)="changeType($event)">
                                    <option *ngFor="let t of taxLists" value="{{ t }}">{{ t }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-secondary btn-raised mr-1"
                                [disabled]="!profileform.valid">Submit</button>
                            <!-- <button type="button" class="btn btn-dark btn-raised">Cancel</button> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>