import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { Globals } from '../../common/global';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ride-history',
  templateUrl: './ride-history.component.html',
  styleUrls: ['./ride-history.component.css']
})
export class RideHistoryComponent implements OnInit {

  constructor(private apiCallingService: ApiCallingService,public Globals: Globals) { }

  ngOnInit(): void {
    this.getList();
  }
  modeltitle:any;
  page = 1;
  pageSize = 10;
  data=[];
  totalcount = 0;
  detail : any;
  getList() {
    let user_id = this.Globals.getUser()._id;
    let query = '?user_id='+user_id+'&limit=10&page='+this.page;
    var body= {
      'userId' : user_id,
      'page': this.page-1,
      'limit': this.pageSize
    }
    this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.GET_RIDE_HISTORY_LIST)
      .subscribe(res => {
          this.data =  res.data;
          this.totalcount =  res.total;
      });
  }
   
  loadPage(page: number){
    this.page=page;
    this.getList(); 
  }
}
