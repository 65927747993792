import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailmyasset',
  templateUrl: './detailmyasset.component.html',
  styleUrls: ['./detailmyasset.component.css']
})
export class DetailmyassetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
