<div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
            <div *ngIf="userinfo">
                <h3>Your stripe account </h3>
                <h4>Account :  {{userinfo.id}}</h4>
                <h4>Email : {{userinfo.email ? userinfo.email : 'Not specified' }} </h4>
            </div>
            <div *ngIf="!userinfo">
                <h3>Your stripe account not configured </h3>
            </div>
            <button class="btn btn-primary" (click)="openaddaccount()">Configure New</button>
           

        </div>
      </div>
    </div>
  </div>
  
  <ng-template #confirmModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="editUserLabel"> </h5>
      <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="modeltitle"></div> 
        <div class="modal-footer" >
          <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">Cancel</button>
          <button type="submit" *ngIf="stepscompleted" class="btn btn-success" (click)="change()">Yes,Continue</button>
        </div>
    </div>
  </ng-template>
  