<div class="row">
    <div class="col-12">
        <div class="card card-body">

            <ngb-tabset [activeId]="activeTab" (tabChange)="getLockTypes($event.nextId)">
                <ngb-tab id="waiting">
                    <ng-template ngbTabTitle>
                        <div>In Queue</div>
                    </ng-template>
                    <ng-template ngbTabContent> </ng-template>
                </ngb-tab>
                <ngb-tab id="completed">
                    <ng-template ngbTabTitle>
                        <div>Completed</div>
                    </ng-template>
                    <ng-template ngbTabContent> </ng-template>
                </ngb-tab>
                <ngb-tab id="leaved">
                    <ng-template ngbTabTitle>
                        <div>Leaved</div>
                    </ng-template>
                    <ng-template ngbTabContent> </ng-template>
                </ngb-tab>
            </ngb-tabset>
            <div class="">
                <table class="table table-striped border mt-4 ">
                    <thead>
                        <tr>
                            <th scope="col">UserName</th>
                            <th scope="col">User Email</th>
                            <th scope="col">Queue Number</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of data">
                            <td>{{ d.user_id.username }}</td>
                            <td>{{ d.user_id.email }}</td>
                            <td>{{ d.token_number }}</td>
                            <td>{{ d?.created_at | date : "medium" }}</td>
                    </tbody>
                </table>
                <div class="d-flex justify-content-center mt-5">
                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount" [maxSize]="7"
                        [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                        (pageChange)="loadPage($event)"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>