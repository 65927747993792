<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Transaction List
        </h4>
        <br />
        <div class="row content">
          <div class="col content-date">
            <div class="form-group row">
              <label for="startdate" class="col-sm-4 col-form-label"
                >Start date</label
              >
              <input
                type="date"
                class="form-control"
                onkeydown="return false"
                name="startdate"
                [(ngModel)]="startDate"
                id="startdate"
                ngModel
                #startdate="ngModel"
                required
              />
            </div>
          </div>
          &nbsp;
          <div class="col content-date">
            <div class="form-group row">
              <label for="enddate" class="col-sm-4 col-form-label">
                End date</label
              >
              <input
                type="date"
                class="form-control"
                onkeydown="return false"
                min="{{ startDate }}"
                name="enddate"
                [(ngModel)]="endDate"
                id="enddate"
                ngModel
                #enddate="ngModel"
                required
              />
            </div>
          </div>
          <div class="col-md-1 bttn">
            <a
              href="javascript: void(0);"
              class="btn btn-primary ml-auto"
              placement="top"
              (click)="filterbydate()"
              ngbTooltip="Search between days"
            >
              Search
            </a>
          </div>

          <div class="col-md-1 text-left bttn">
            <a
              href="javascript: void(0);"
              class="btn btn-primary pull-right"
              placement="top"
              (click)="getPdf()"
              ngbTooltip="pdf"
            >
              <i class="fas fa-file-alt"></i> PDF
            </a>
          </div>

          <div class="col-md-1 text-left bttn">
            <a
              href="javascript: void(0);"
              class="btn btn-primary pull-right"
              placement="top"
              (click)="getCsv()"
              ngbTooltip="Export as CSV"
            >
              <i class="fas fa-file-alt"></i> Export
            </a>
          </div>
        </div>
        <div class="form-group row"></div>
        <div class="d-flex mt-3"></div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4 table-responsive">
          <thead>
            <tr>
              <th scope="col">Time</th>
              <th scope="col">Invoice Number</th>
              <th scope="col">Transaction for</th>
              <th scope="col">From</th>
              <th scope="col">Coupon Code</th>
              <th scope="col">Type</th>
              <th scope="col">Asset Id</th>
              <th scope="col">Asset Name</th>
              <th scope="col">Reserved Amount</th>
              <th scope="col">Refund Amount</th>
              <th scope="col">Total Paid</th>
              <th scope="col">Stripe Fees</th>
              <th scope="col">Application fees</th>
              <th scope="col">Net Received</th>
              <th scope="col">Receipt</th>
            </tr>
          </thead>
          <tbody *ngFor="let d of data">
            <tr
              [style.background-color]="
                float(d?.stripe_response?.amount, d?.receipt?.refundAmount)
                  ? 'rgb(239, 166, 166)'
                  : ''
              "
            >
              <td>{{ d?.createdAt | date : "medium" }}</td>
              <td>
                <div>
                  {{ d?.stripe_response?.invoice_number }}
                </div>
              </td>
              <td>
                <div style="width: 80px">
                  {{
                    d?.receipt?.description
                      ? truncate(d.receipt.description)
                      : "-"
                  }}
                </div>
              </td>
              <td style="width: 80px">{{ d?.from_user_id?.username }}</td>
              <td>
                {{ d?.receipt?.couponCode }}
                {{
                  d?.receipt?.couponCodeType
                    ? "(type : " + d?.receipt?.couponCodeType + ")"
                    : "-"
                }}
              </td>
              <td>
                {{
                  d?.transaction_type == "lease_package_purchase"
                    ? "Book"
                    : d?.transaction_type == "sale_package_purchase"
                    ? "Sale"
                    : d?.transaction_type == "extra_payment"
                    ? "Extra"
                    : d?.transaction_type == "package_purchase"
                    ? "Rent"
                    : d?.transaction_type == "subscription_purchase"
                    ? "Subscription"
                    : d?.transaction_type == "request_payment"
                    ? "Request\npayment"
                    : d?.transaction_type == "self_serve_advance_booking"
                    ? "Self Serve Booking"
                    : d?.transaction_type == "activities_advance_booking"
                    ? "Activities Booking"
                    : ""
                }}
              </td>
              <td>
                <div style="width: 80px">
                  {{ d?.receipt.lock_id ? d?.receipt.lock_id : "-" }}
                  <!-- {{ d?.transaction_type == "sale_package_purchase" ? d?.receipt?.description.split("-").pop():"-" }} -->
                </div>
              </td>
              <td>{{ d?.receipt.lock_name ? d?.receipt.lock_name : "-" }}</td>
              <td>
                {{
                  d?.stripe_response?.amount
                    ? d?.stripe_response?.amount / 100
                    : 0
                }}
              </td>
              <td>{{ d?.receipt?.refundAmount }}</td>

              <td>
                <div>
                  {{ d?.stripe_response?.balance_transaction_data?.amount }}
                  {{
                    d?.stripe_response?.balance_transaction_data?.currency
                      | uppercase
                  }}
                </div>
              </td>
              <td>
                {{ d?.stripe_response?.balance_transaction_data?.stripe_fee }}
                {{
                  d?.stripe_response?.balance_transaction_data?.currency
                    | uppercase
                }}
              </td>
              <td>
                {{
                  d?.stripe_response?.balance_transaction_data &&
                  d.stripe_response.balance_transaction_data?.application_fee
                    ? d.stripe_response.balance_transaction_data
                        ?.application_fee
                    : 0
                }}
                {{
                  d?.stripe_response?.balance_transaction_data?.currency
                    | uppercase
                }}
              </td>
              <td>
                {{ d?.stripe_response?.balance_transaction_data?.net }}
                {{
                  d?.stripe_response?.balance_transaction_data?.currency
                    | uppercase
                }}
              </td>
              <td style="text-align: center">
                <a class="cursor-pointer" (click)="loadReceipt(d._id)"
                  ><i class="fas fa-print"></i
                ></a>
              </td>
            </tr>

            <tr *ngIf="data.length == 0">
              <td colspan="11">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
