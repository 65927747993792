import { Component, OnInit } from '@angular/core';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private apiCallingService: ApiCallingService) { }

  ngOnInit(): void {
    this.getList();
  }
  
  data=[]; 
  getList() {
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.SUPPORT.FAQ)
      .subscribe(res => {
          this.data =  res.data;
      });
  }
   
   

}
