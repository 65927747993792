<div
  class="card card-body"
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    text-align: center;
  "
>
  <div>
    <a
      href="javascript: void(0);"
      class="btn btn-primary ml-auto"
      placement="top"
      (click)="addRecord()"
      ngbTooltip="Generate new"
      style="margin-bottom: 20px"
    >
      Generate New Secret
    </a>

    <h4
      class="text-uppercase error-subtitle"
      *ngIf="msg != ''"
      style="text-align: center"
    >
      {{ msg }}
    </h4>
    <br />
    <!-- <a
      *ngIf="data.external_url"
      href="javascript: void(0);"
      #userinput
      (click)="copyInputMessage(userinput)"
      style="margin-top: 20px; text-align: center"
    >
      {{ data.external_url }}
    </a> -->
    <div style="display: flex; max-width: 1000px">
      <button
        style="margin: auto"
        class="btn btn-light copyButton"
        (click)="copyText()"
      >
        {{ btnText }}
      </button>

      <span> {{ data.external_url }}</span>
    </div>
  </div>
</div>
