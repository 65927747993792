import { Component, OnInit } from "@angular/core";
import { RouterModule, Router } from "@angular/router";
//import { MyserviceService } from './../myservice.service';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ApiCallingService } from "../services/api-calling.service";
import { Constants } from "../common/constants";
import { ConfigApi } from "../config-api";
//import { NgxLoadingModule } from 'ngx-loading';
import { Globals } from "../common/global";

import { SocketService } from "../services/socket.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  providers: [ApiCallingService],
})
export class LoginComponent {
  msg = "";
  connectionID: any = "";
  QRcode: any;
  user: any;
  allcountries: any;
  constructor(
    public routes: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    public globals: Globals,
    private SocketService: SocketService
  ) {
    this.apiCallingService
      .callAPI({}, "GET", "assets/countries.json")
      .subscribe((data) => {
        this.allcountries = data;
      });
  }

  ngOnInit() {
    this.SocketService.getConnection().subscribe((data) => {
      this.QRcode = data;
      this.QRcode = `CL:${this.QRcode?.socket_id}`;
      console.log("🚀 ~ file: login.component.ts:45 ~ LoginComponent ~ this.SocketService.getConnection ~ this.QRcode:", this.QRcode)
    });

    this.SocketService.getLogin().subscribe((data) => {
      this.user = data;
      this.apiCallingService
        .callAPI(
          {},
          "GET",
          ConfigApi.URLS.MY_PROFILE.GET_DETAIL +
          "/" +
          this.user.user_info.user_id._id
        )
        .subscribe((res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.globals.setUser(res.data);

            if (res?.data?.business_detail) {
              let detail = this.allcountries.find((c: any) => {
                return res?.data?.business_detail?.country == c?.name;
              });
              if (detail?.currencies.length > 0) {
                localStorage.setItem("CUR", detail?.currencies[0]?.code);
              } else {
                let cur =
                  this.globals.getUser().stripe_account_detail
                    ?.default_currency;
                localStorage.setItem("CUR", cur.toUpperCase());
              }

              if (localStorage.getItem("PENDING_PAYMENT")) {
                localStorage.removeItem("PENDING_PAYMENT");
              }

              if (res?.data?.business_detail?.isPluddaEnabled) {
                localStorage.setItem(
                  "isPluddaEnabled",
                  "" + res.data.business_detail &&
                    res.data.business_detail.isPluddaEnabled
                    ? res.data.business_detail.isPluddaEnabled
                    : false
                );
              } else {
                localStorage.setItem("isPluddaEnabled", "" + false);
              }
            }

            if (res.data.flags.is_assets_registered) {
              this.routes.navigate(["/user/home"]);
            } else {
              this.routes.navigate(["/user/dashboard"]);
            }
          }
        });
    });
  }

  loginform = true;
  recoverform = false;

  // showRecoverForm() {
  //   this.loginform = !this.loginform;
  //   this.recoverform = !this.recoverform;
  // }

  // // check(useremail: string, password: string) {

  // //   const body = new HttpParams()
  // //     .set(Constants.LOGIN_PRAMETER.KEY_API_EMAIL, useremail)
  // //     .set(Constants.LOGIN_PRAMETER.KEY_API_PASSWORD, password);

  // //   this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.LOGIN_URL)
  // //     .subscribe(res => {
  // //       if (this.apiCallingService.handleSuccess(res)) {
  // //         localStorage.setItem('Auth-token', res.data.token);
  // //         localStorage.setItem('Auth-user', JSON.stringify(res.data));

  // //         this.toastr.success('Welcome to admin');
  // //         if (res.data.role == "admin") {
  // //           this.routes.navigate(['/user/dashboard']);
  // //         }
  // //         console.log(res.data.role);
  // //       } else {

  // //         this.msg = res.message

  // //       }
  // //     }, error => {

  // //       this.msg = error.error.message;
  // //     });
  // // }
}
