<div class="row">
  <div class="col-12">
    <!--  <div class="row">
            <div class="col-5">

                <input type="text" #search class="form-control ng-pristine ng-valid "> &nbsp;
            </div>
            <div class="col-7">
                 <button class="btn btn-primary " (click)="searchGeofence(search.value)">Search</button>&nbsp;
            </div>
        </div> -->
    <!--  -->
    <div class="map" id="map" class="match-parent"></div>

    <br />

    <div class="row">
      <input
        type="text"
        #geofenceName
        required
        style="width: 500px; margin-right: 20px"
        class="form-control ng-pristine ng-valid"
        [(ngModel)]="name"
      />

      <button
        class="btn btn-primary"
        [disabled]="!name || !isShapeCreated"
        (click)="create()"
      >
        Save
      </button>

      <!-- <div class="col-7">
                <button class="btn btn-primary" (click)="createGeofence()" [disabled]="( geofenceName.value=='' || selectedShape==undefined )">{{isEdit?'Save':'Create'}}</button>&nbsp;
            </div> -->
    </div>
    <!--   <button type="button" class="btn btn-primary" (click)="deleteSelectedShape()">Delete Points</button> -->
  </div>
</div>
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Are you sure want to delete?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="deleteGeofence()">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>
