<div class="row">
  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h5 class="mt-2">User detail</h5>
        <div class="mt-2">
          <img [src]="detail?.user_id.user_image" width="80" />
        </div>

        <div class="mt-2">
          <b>Name</b> :
          {{ detail?.user_id.name }}
        </div>
        <div class="mt-2">
          <b>Email </b>:
          {{ detail?.user_id.email }}
        </div>
        <div class="mt-2">
          <b>Mobile </b>:
          {{ detail?.user_id.mobile }}
        </div>
        <br />
        <div>
          <h5 class="mt-2">Lock detail</h5>
        </div>

        <div>
          <i class="fa fa-lock"></i>
          {{ detail?.ride_id.lock_id?.lock_name }} ({{
            detail?.ride_id.lock_id?.lock_type
          }})
        </div>
        <br />
        <h5 class="mt-2">Location detail</h5>
        <div class="mt-2">
          <i class="mdi mdi-map-marker text-success"></i><b>Start point</b> :
          {{ detail?.ride_id.start_address }}
        </div>
        <div class="mt-2">
          <i class="mdi mdi-map-marker text-danger"></i><b>End point </b>:
          {{ detail?.ride_id.end_address }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-8">
    <div class="card">
      <div class="card-body">
        <div class="map" id="map" class="match-parent"></div>
      </div>
    </div>
  </div>
</div>
