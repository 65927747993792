import { Component, OnInit,ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams, HttpClient,HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Globals } from '../../common/global';
import { StripeService } from 'ngx-stripe';
import {Router,ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {
  @ViewChild('specialityform', {static:true}) specialityform: NgForm=Object.create(null);


  constructor( private modalService: NgbModal,
    private http: HttpClient,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    private route:ActivatedRoute,
    public routes: Router,
    public Globals: Globals,
    private stripeService: StripeService
    ) { }

  years : any = [];
  params:any=[];
  ngOnInit(): void {
    this.route.params.subscribe(params => this.params = params);
    if(this.params.pay=='pendingpay'){
      console.log('se');
    }else{
      console.log(this.params);
    }
    this.getList();
    let date = new Date();
    for (let year = date.getFullYear(); year < (date.getFullYear()+15); year++) {
      this.years.push(year);
    }
     
  }
   
  modeltitle:any;
  page = 1;
  pageSize = 10;
  data=[];
  totalcount = 0;
  detail : any;
  
 
  currency : any = localStorage.getItem('CUR');

   
  openModal(targetModal: NgbModal, data:any,title:any) {
    this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
    });
    this.modeltitle  = title;
    this.detail = Object.assign({},data);
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.PAYMENT.GET_CARDS+"?user_id="+user_id)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data =  res.data.data;
        } 
      });
    //   let user_id = this.Globals.getUser()._id;
    //   const body = new HttpParams()
    // .set('customer_id',this.Globals.getUser().stripe_customer_code)
    // .set('api_version','2020-03-02');
    //   this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.PAYMENT.EPHEMERAL_KEYS)
    //   .subscribe(res => {
    //     const body = new HttpParams()
    //     .set('type','card');
    //     let url = "https://api.stripe.com/v1/customers/cus_KUQNP2G9lTKIYV/payment_methods"
        
    //     let httpHeaders = new HttpHeaders();
    //     httpHeaders.append('Stripe-Version', '2020-03-02');
    //     httpHeaders.append('Authorization', '2020-03-02');
    //     this.apiCallingService.customAPI(httpHeaders,body, 'POST',url)
    //     .subscribe(res => {
    //         console.log('t');
    //     });
           
    //   });
  }
    
  
  delete(): void {
        let user_id = this.Globals.getUser()._id;
        this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.PAYMENT.DELETE_CARD+'?user_id='+user_id+'&cardId='+this.detail.id)
        .subscribe(res => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success('Successfully deleted');
            this.closeBtnClick();
            this.ngOnInit();
          } 
        });
  }
  onSubmit(f:any) {
    const body = new HttpParams()
    .set('user_id',this.Globals.getUser()._id)
    .set('number',f.value.cardnumber)
    .set('exp_month',f.value.month)
    .set('exp_year',f.value.year)
    .set('cvc',f.value.cvvnumber);
    let url = ConfigApi.URLS.PAYMENT.ADD_CARD;
    let method = 'POST';
    this.apiCallingService.callAPI(body, method, url)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          if(res.msg.code){
            this.toastr.error(res.msg.raw.message);
          }else{
            this.toastr.success('Card added successfully');
            this.closeBtnClick();
            this.ngOnInit();
          }
        } 
    });
  }
  pay(card:any){
    if(localStorage.getItem('PENDING_PAYMENT')){
      this.payPendingPayment(card);
    }else{
      let u = this.Globals.getUser();
      const body = new HttpParams()
      .set('user_id',u._id)
      .set('customer',u.stripe_customer_code)
      .set('country',u.business_detail.country)
      .set('price',100)
      .set('package_type','subscription')
      .set('package_id',u.subscription._id)
      .set('source',card.id)
      .set('stripe_customer_code',u.stripe_customer_code)
      .set('is_live',true)
      .set('business_detail',u.business_detail._id);
  
      let method = 'POST';
      this.apiCallingService.callAPI(body, method, ConfigApi.URLS.PAYMENT.PAY_FOR_SUBSCRIPTION)
        .subscribe(res => {
            this.getProfile();
            this.toastr.success('Subscription payment has been done.Your operator account is live now')
            this.routes.navigate(['/user/operation/paid']);
      });
    }
  }
  paloadpending : any ;
  intentid : any;
  payPendingPayment(card:any){
    this.paloadpending = localStorage.getItem('PENDING_PAYMENT');
    this.paloadpending= JSON.parse(this.paloadpending);
    this.paloadpending.source= card.id;
    let extra = this.paloadpending.extra;
    delete this.paloadpending.extra;
    console.log('create intent',this.paloadpending);
    this.apiCallingService.callAPI(this.paloadpending, 'POST', ConfigApi.URLS.PAYMENT.CREATE_INTENT)
    .subscribe(res => {
      this.intentid =res.id;
      let confirmIntentpayload = {
        intent_id: this.intentid,
        payment_method: "Card",
        destination: this.paloadpending.destination
      }
      console.log('confirm intent',this.paloadpending);
      this.apiCallingService.callAPI(confirmIntentpayload, 'POST', ConfigApi.URLS.PAYMENT.CONFIRM_INTENT)
      .subscribe(res => {
          let paymentCapturedata = {
            isPartner: this.paloadpending.isPartner,
            user_id: this.Globals.getUser()._id,
            destination: this.paloadpending.destination,
            packageId:  extra.packageId,
            description:this.paloadpending.description,
            currency: this.paloadpending.currency,
            commissionPercent: this.paloadpending.commissionPercent,
            notification_id: extra.notification_id,
            extraTime: extra.extraTime,
            amount_to_capture: this.paloadpending.amount,
            intent_id:this.intentid
          }
          console.log('capture intent',paymentCapturedata);
          //  intent_id: res.data.id
          this.apiCallingService.callAPI(paymentCapturedata, 'POST', ConfigApi.URLS.PAYMENT.CAPTURE_INTENT)
          .subscribe(res => {
                this.toastr.success('Payment paid successfully');
                localStorage.removeItem('PENDING_PAYMENT');
                this.routes.navigate(['/user/notifications']);
          });
      });
    });
  }
  getProfile() { 
    let username = this.Globals.getUser().username;
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.MY_PROFILE.GET_DETAIL+'/'+username)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          
          if(!res.data.business_detail.is_live){
            this.getProfile();
          }else{
            this.Globals.setUser(res.data);
          }
        } 
      });
  }

}
