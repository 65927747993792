<div class="row">
  <div class="col-3">
    <div class="card">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <i class="far fa-clock"></i> {{ detailfromlist?.time }}
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-clock-in"></i>{{ detailfromlist?.speed }} Km/h
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-multiplication"></i>{{ detailfromlist?.co2 }}
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-pulse"></i> {{ detailfromlist?.distance }} km
          </li>
          <li class="list-group-item">
            <i class="mdi mdi-vector-line"></i>{{ detailfromlist?.calorie }} cal
          </li>
        </ul>
        <div>
          <h5 class="mt-2">Asset detail</h5>
        </div>

        <div>
          <i class="fa fa-user"></i> Owner -
          {{ detailfromlist?.lock_id?.owner_id?.name }}
        </div>
        <div>
          <i class="fas fa-lock"></i>
          {{ detailfromlist?.lock_id?.lock_name }} &nbsp;&nbsp;
          <i class="fas fa-battery-full"></i
          >{{ detailfromlist?.lock_id?.battery_percentage }}%
        </div>
        <h5 class="mt-2">Location detail</h5>
        <div class="mt-2">
          <i class="mdi mdi-map-marker text-success"></i><b>Start point</b> :
          {{ detailfromlist?.start_address }}
        </div>
        <div class="mt-2">
          <i class="mdi mdi-map-marker text-danger"></i><b>End point </b>:
          {{ detailfromlist?.end_address }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="card">
      <div class="card-body">
        <div class="map" id="map" class="match-parent"></div>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="imageList.length != 0">
    <div class="card">
      <div class="card-body">
        <div class="mt-2">
          <h5 class="mt-2">Ride Proofs</h5>
          <ng-image-slider
            [images]="imageList"
            [imageSize]="{ width: 380, height: 300 }"
            #navigation
          ></ng-image-slider>
        </div>
      </div>
    </div>
  </div>
</div>
