<div class="row">
  <div class="col-12">
    <div class="card card-body">

      <div class="">
        <h4 class="card-title mb-0"><span class="lstick"></span>All Cards </h4>
        <div class="d-flex mt-3">
           <a href="javascript: void(0);" class="btn btn-primary ml-auto" placement="top"
                  (click)="openModal(editModal,{}, 'Add Card')" ngbTooltip="Add Card">
                  + Add Card 
                </a>
        </div>
      </div>
      <div class="">
  
        <table class="table table-striped border mt-4">
          <thead>
              <tr  >
                  <th scope="col">Type</th>
                  <th scope="col">Card number</th>
                  <th scope="col">Action</th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
                  <td>{{d.card.brand | uppercase}}  </td>
                  <td>Ending with {{d.card.last4}}</td>
                  <td>
                      <a href="javascript: void(0);" class="link mr-2" placement="top"
                        (click)="openModal(editModal,d, 'Delete Card')" ngbTooltip="Delete">
                        <i class="fa fa-trash"></i>
                      </a>
                      <a *ngIf="params.pay" href="javascript: void(0);" class="btn btn-primary   mr-2" placement="top"
                        (click)="pay(d)" >
                          Select to pay
                       </a>
                    </td>
              </tr>
              <tr *ngIf="data.length==0">
                <td colspan="3">No data found</td>
              </tr>
              
          </tbody>
      </table>

      </div>

    </div>
  </div>
</div>

<ng-template #editModal let-modal>

  <div class="modal-header">

    <h5 class="modal-title" id="editUserLabel">{{modeltitle}}</h5>

    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>

  <div class="modal-body">
   
    <form  *ngIf="modeltitle=='Add Card' || modeltitle=='Edit Package' " (ngSubmit)="onSubmit(specialityform)" #specialityform="ngForm">
      
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="ccnumber">Card Number</label>
                <div class="input-group">
                    <input class="form-control"  name="cardnumber" ngModel required
                    #cardnumber="ngModel" minlength="8" maxlength="19" type="text"  pattern="\d*" placeholder="0000 0000 0000 0000" autocomplete="email">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="mdi mdi-credit-card"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-sm-4">
            <label for="ccmonth">Month</label>
            <select class="form-control" id="ccmonth" name="month" ngModel 
            #month="ngModel" required>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
            </select>
        </div>
        <div class="form-group col-sm-4">
            <label for="ccyear">Year</label>
            <select class="form-control" id="ccyear" name="year" ngModel
            #year="ngModel" required>
                <option *ngFor="let y of years" [value]="y">{{y}}</option>
            </select>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label for="cvv">CVV/CVC</label>
                <input class="form-control" name="cvvnumber" id="cvv" type="text" placeholder="123" ngModel #cvvnumber="ngModel" required>
            </div>
        </div>
    </div>
       
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">Close</button>

        <button type="submit" class="btn btn-secondary btn-raised mr-1"
        [disabled]="!specialityform.valid">Submit</button>
       </div>
    </form>
     
     
    <div  *ngIf="modeltitle=='Delete Card'">
      Are you sure want to delete?
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">Close</button>
        <button type="submit" class="btn btn-danger" (click)="delete()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>
