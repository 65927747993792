import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  terms:any;
  content="";
  constructor(     
     private http: HttpClient,
     private toastr: ToastrService,
     private apiCallingService: ApiCallingService
  ) { }

  ngOnInit(): void {
     
  }
   
  updateTerms(data:any) { 
    const body = new HttpParams()
      .set("email", 'sakshi.devstree+2@gmail.com')
      .set("message", data.content)
      .set("log", "");
    this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.SUPPORT.FEEDBACK)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success('Feedback sent');
          this.content = ""
        } 
      });
  }
   
}
