<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0"><span class="lstick"></span>My assets</h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Brand Name</th>
              <th scope="col">Type</th>
              <th scope="col">Book</th>
              <th scope="col">Battery Status</th>
              <th scope="col">Owner</th>
              <th scope="col">Location</th>
              <th scope="col" (click)="sortBy('totalMinutes')">
                Total Hour{{ sortIcon("totalMinutes") }}
              </th>
              <th scope="col" (click)="sortBy('operatorEarned')">
                Total Earned {{ sortIcon("operatorEarned") }}
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td><img [src]="d?.lock_image" width="40" /></td>
              <td>{{ d?.lock_name }}</td>
              <td>
                {{
                  d.brand_name == "OdeServices"
                    ? "Shrvee"
                    : d.brand_name
                    ? d.brand_name
                    : d.isSharekayakLock
                    ? "Sharekayak"
                    : "Frekis"
                }}
                <!-- {{ d.brand_name ? d.brand_name : d.isSharekayakLock ? "Sharekayak" : "Frekis" }} -->
              </td>
              <td>{{ d?.lock_type }}</td>
              <td *ngIf="d?.on_lease == false">
                {{ d?.on_lease }}
              </td>
              <td
                *ngIf="d?.on_lease == true"
                style="background-color: red; color: white; opacity: 0.5"
              >
                {{ d?.on_lease }}
              </td>

              <td>{{ d?.battery_percentage }} %</td>

              <td>{{ d?.owner_id?.user_id?.username }}</td>
              <td>
                {{
                  d.lock_address
                    ? d?.lock_address.length > 40
                      ? d?.lock_address.substring(0, 30) + "..."
                      : d?.lock_address
                    : "-"
                }}
              </td>
              <td>{{ d?.totalMinutes }}</td>
              <td>{{ d?.operatorEarned }}</td>
              <td>
                <a
                  *ngIf="d?.on_lease == true"
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Cancel Book')"
                  ngbTooltip="Cancel Book"
                >
                  <i class="fa fa-times"></i>
                </a>

                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Edit Asset')"
                  ngbTooltip="Edit"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  (click)="openModal(editModal, d, 'Asset detail')"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="Asset detail"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <a
                  (click)="openModal(editModal, d, 'View QR')"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="View QR code"
                >
                  <i class="fas fa-qrcode"></i>
                </a>
                <a
                  [click]="['/user/business-analytics']"
                  (click)="getId(d._id)"
                  ngbTooltip="Business analytics"
                >
                  <i class="fa fa-list"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="9">No data found</td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="d-flex justify-content-center mt-5">
            <ngb-pagination  [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount"  [maxSize]="7" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
          </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="modeltitle == 'View QR'" class="text-center">
      <button
        (click)="printComponent('qrcodesec')"
        placement="top"
        ngbTooltip="Print QR code"
        class="btn btn-secondary float-right"
      >
        <i class="mdi mdi-printer"></i>
      </button>
      <div #qrDiv>
        <qr-code
          value="{{ detail.lock_id }}"
          id="qrcodesec"
          cssClass="coolQRCode"
          size="300"
          errorCorrectionLevel="M"
        >
        </qr-code>
        <h2>{{ detail.lock_id }}</h2>
      </div>
    </div>
    <div *ngIf="modeltitle == 'Asset detail'">
      <span>{{ detail.lock_id }}</span>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Lock</label>
        <label class="col-sm-8 col-form-label">
          {{ detail.lock_name }} ({{ detail.lock_type }})
          {{ detail.description }}
        </label>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <div class="map" id="map" class="match-parent"></div>
        </div>
      </div>
      <div class="form-group">
        <label for="typeahead-http">Search members:</label>
        <!-- <input
            id="typeahead-http"
            type="text"
            class="form-control"
            [class.is-invalid]="searchFailed"
            [(ngModel)]="model1"
            [ngbTypeahead]="search"
            [resultFormatter]="formatMatches"
            [inputFormatter]="formatMatches"
            placeholder="Wikipedia search"
          /> -->
        <input
          type="text"
          [(ngModel)]="model1"
          (keyup)="search()"
          class="form-control"
          placeholder="Search members to add"
        />
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let u of searchresult; let i = index"
          >
            <img
              class="rounded-circle"
              height="30"
              width="30"
              src="assets/map/newLock/icon.png"
            />
            {{ u.name }} - @{{ u.username }}
            <button
              *ngIf="u.is_group_shared"
              class="btn btn-rounded btn-xs btn-danger float-right"
              (click)="removeMember(u._id, i)"
            >
              Remove
            </button>
            <button
              *ngIf="!u.is_group_shared"
              class="btn btn-rounded btn-xs btn-success float-right"
              (click)="addMember(u._id, i)"
            >
              Add
            </button>
          </li>
        </ul>
        <span
          ><b>Note</b>: Access are granted based on asset type,Another asset
          with same asset type wii share same users.</span
        >
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <b>Shared members</b>
          <ul class="list-group">
            <li
              class="list-group-item"
              *ngFor="let u of detail.group; let i = index"
            >
              <img
                class="rounded-circle"
                height="30"
                width="30"
                [src]="u.user_image"
              />
              {{ u.name }}
              <button
                class="btn btn-rounded btn-xs btn-danger float-right"
                (click)="removeMember(u._id, i)"
              >
                Remove
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <form
      *ngIf="modeltitle == 'Edit Asset'"
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      <p>
        LOCK ID : <b>{{ detail.lock_id }}</b> | Battery
        <b>{{ detail.battery_percentage }} %</b>
      </p>

      <div class="form-group row">
        <label for="image" class="col-sm-4 col-form-label">Profile image</label>
        <div class="col-sm-8">
          <input
            type="file"
            class="form-control-file"
            (change)="readURL($event)"
            accept="image/png, image/gif, image/jpeg"
            name="file"
            ngModel
            #file="ngModel"
          />
          <img id="blah" *ngIf="imageSrc" width="100" [src]="imageSrc" />
        </div>
      </div>
      <div class="form-group row">
        <label for="lock_name" class="col-sm-4 col-form-label">Lock name</label>
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="lock_name"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.lock_name"
            id="lock_name"
            name="lock_name"
            ngModel
            #lock_name="ngModel"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="fullName" class="col-sm-4 col-form-label">Lock type</label>
        <div class="col-sm-8">
          <select
            class="form-control"
            #lock_type="ngModel"
            name="lock_type"
            [(ngModel)]="detail.lock_type"
          >
            <option *ngFor="let type of locktypes">{{ type }}</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="timing" class="col-sm-4 col-form-label"
          >Lock visible for</label
        >
        <div class="col-sm-8">
          <select
            class="form-control"
            name="brand_name"
            #brand_name="ngModel"
            [(ngModel)]="detail.brand_name"
          >
            <option value="Frekis">Frekis</option>
            <option value="Sharekayak">Sharekayak</option>
            <option value="OdeServices">Shrvee</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="description" class="col-sm-4 col-form-label"
          >Description</label
        >
        <div class="col-sm-8">
          <!-- <input type="text" class="form-control" name="description" pattern="(\s*[^\s]+\s*)+"   [(ngModel)]="detail.description" id="description" name="description" ngModel
               #description="ngModel" required> -->
          <textarea
            class="form-control"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.description"
            id="description"
            name="description"
            ngModel
            #description="ngModel"
          ></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Reservable</label>
        <div class="col-sm-8">
          <ui-switch
            [(ngModel)]="detail.is_reservable"
            [ngModelOptions]="{ standalone: true }"
            name="is_reservable"
            #isExclusive="ngModel"
          ></ui-switch>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Free Asset</label>
        <div class="col-sm-8">
          <ui-switch
            [(ngModel)]="detail.is_free_lock"
            [ngModelOptions]="{ standalone: true }"
            name="is_free_lock"
            #isExclusive="ngModel"
          ></ui-switch>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>

        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>
    <form [formGroup]="editUser" *ngIf="modeltitle == 'Cancel Lease'">
      Are you sure to cancel this Book?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="delete()">
          Delete
        </button>
      </div>
    </form>
  </div>
</ng-template>
