<div class="auth-wrapper d-flex no-block justify-content-center align-items-center"
    style="background:url(assets/images/big/auth-bg.jpg) no-repeat center center;">
    <div>
        <!-- <div class="alert alert-primary">
            <span class="mr-1">Username : admin@gmail.com</span> |
            <span class="ml-1">Password : admin@123</span>
        </div> -->
        <div class="auth-box">
            <div id="loginform" [ngClass]="{'d-none': recoverform}">
                <div class="logo">
                    <span class="db"><img src="assets/images/frekis-logo.png" alt="logo" /></span>

                    <h5 class="font-medium m-b-20">Sign In to Operator</h5>
                    <h6 class="font-medium m-b-20">Scan QR code from app to login</h6>

                </div>
                <div *ngIf="msg" class="alert alert-danger">{{ msg }}</div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <div class="text-center">
                            <qr-code value="{{QRcode}}" size="300" errorCorrectionLevel="M"></qr-code>
                        </div>


                        <!-- <form class="form-horizontal m-t-20" id="loginform" (ngSubmit)="check(u1.value, p2.value)">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                </div>
                                <input type="email" class="form-control form-control-lg" placeholder="Email" aria-label="Email"
                                aria-describedby="basic-addon1" #u1>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                </div>
                                <input type="password" class="form-control form-control-lg" placeholder="Password" aria-label="Password"
                                aria-describedby="basic-addon1" #p2>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Remember me</label>
                                      </div>
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info" type="submit">Log
                                    In</button>
                                </div>
                            </div>
                            
                        </form> -->
                    </div>
                </div>
            </div>
            <div id="recoverform" [ngClass]="{'d-block': recoverform}">
                <div class="logo">
                    <span class="db"><img src="assets/images/logo-icon.png" alt="logo" /></span>
                    <h5 class="font-medium m-b-20">Recover Password</h5>
                    <span>Enter your Email and instructions will be sent to you!</span>
                </div>
                <div class="row m-t-20">
                    <!-- Form -->
                    <form class="col-12">
                        <!-- email -->
                        <div class="form-group row">
                            <div class="col-12">
                                <input class="form-control form-control-lg" type="email" required=""
                                    placeholder="Username">
                            </div>
                        </div>
                        <!-- pwd -->
                        <div class="row m-t-20">
                            <div class="col-12">
                                <button class="btn btn-block btn-lg btn-danger" type="submit"
                                    name="action">Reset</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>