<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div style="width:100%;">
        <ngb-datepicker [(ngModel)]="model1" [minDate]="{year: model?.year, month: model?.month, day: model?.day}"
          [maxDate]="{year: end_Date?.year, month: end_Date?.month, day: end_Date?.day}"
          (dateSelect)="onDateSelection($event)">
        </ngb-datepicker>
      </div>
      <div class="slot-card-header">
        <div class="dateListBtns">
          <!-- <div class="dateContainer text-center py-2 px-3 rounded" *ngFor="let d of slotDateList">
                      {{d.date}}
                  </div> -->
          <!-- <div class="dateContainer text-center py-2 px-3 rounded" *ngFor="let d of slotDateList"
            (click)="selectedSlot(d)" [disabled]="d.disable == true" [ngClass]="
              activeSlot == d.date && d.disable == false
                ? 'active'
                : activeSlot !== d.date && d.disable == false
                ? 'notActive'
                : activeSlot !== d.date && d.disable == true
                ? 'disable'
                : 'notActive'
            ">
            <p class="my-0 h5">{{ d.date | date : "MMM d" }}</p>
            <p class="my-0">{{ d.date | date : "EEEE" }}</p>
          </div> -->
        </div>
      </div>

      <div class="solt-detail-container my-3 border rounded-10">
        <div class="slot-detail-card p-3">
          <div class="d-flex justify-content-between">
            <p class="title h4">{{ slotDetail?.name }} Pack</p>

            <a class="btn editPackBtn" href="javascript: void(0);" role="button" *ngIf="isPackEdit == true"
              ngbTooltip="Edit Pack Detail" (click)="openModal(editModal, slotDetail, 'Edit Pack Detail')">
              <i class="fa fa-edit"></i> Edit Pack</a>

          </div>


          <div class="row">
            <div class="col-sm-4">
              <dl class="row">
                <dt class="col-sm-2">Operator</dt>
                <dd class="col-sm-9">
                  {{ slotDetail?.operatorDetails?.username }}
                </dd>

                <dt class="col-sm-2">Package Type</dt>
                <dd class="col-sm-9">{{
                  slotDetail?.booking_type == "activities"
                  ? "Activities"
                  : slotDetail?.booking_type == "self_serve"
                  ? "Self Serve"
                  : slotDetail?.booking_type == "rentx"
                  ? "RentX"
                  : "-"
                  }}</dd>
                <dt class="col-sm-2">Deposit</dt>
                <dd class="col-sm-9">
                  {{ slotDetail?.security_deposit }}
                  {{ slotDetail?.countryObject?.currency }}
                </dd>
                <dt class="col-sm-2">check-In</dt>
                <dd class="col-sm-9">{{ slotDetail?.check_in_time }}</dd>
                <dt class="col-sm-2">check-Out</dt>
                <dd class="col-sm-9">{{ slotDetail?.check_out_time }}</dd>
              </dl>
              <div class="address d-flex">
                <i class="mdi mdi-map-marker-multiple h5 mr-2"></i>
                <p class="h5">{{ slotDetail?.address }}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <img id="blah" *ngIf="slotDetail?.poster_image" width="100%" [src]="slotDetail?.poster_image"
                class="previewImg img-fluid" />
            </div>
          </div>


        </div>

        <!-- <div class="slot-detail-card p-3">
                    <p class="mb-0">This Time Slot is Expired</p>
                </div> -->
      </div>


      <div class="add-SlotBtn" *ngIf="activeSlot && disableDate == false">
        <a href="javascript: void(0);" class="btn btn-outline-success d-flex my-2"
          (click)="openModal(editModal, {}, 'Add New Slot')">
          <i class="fa fa-plus mt-1 mr-2"></i>
          Add Slot</a>
      </div>
      <div class="solt-time-container my-3 px-3">
        <div class="main-slot-box p-3 my-3" *ngFor="let list of slotDetail.time_slot"
          [ngClass]="list.is_available == false ? 'inActiveSlot' : ''">
          <div class="solt-time-card d-flex">
            <div class="timeGap">
              <p class="my-0 font-weight-bold time">{{ list?.slot }}</p>
              <p class="my-0">{{ list?.asset_available_count }} {{
                slotDetail?.booking_type == "activities"
                ? "Members"
                : slotDetail?.booking_type == "self_serve"
                ? "Assets"
                : slotDetail?.booking_type == "rentx"
                ? "Available"
                : "-"
                }}</p>
            </div>
            <div class="actionBtn" *ngIf="list.is_available">
              <a href="javascript: void(0);" class="link mr-2" placement="top" ngbTooltip="Edit"
                (click)="showEdit(list)">
                <i class="fa fa-edit"></i>
              </a>
              <a href="javascript: void(0);" class="link mr-2" placement="top" ngbTooltip="Delete"
                (click)="openModal(editModal, list, 'Delete Slot')">
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </div>

          <div class="editDetails my-3" *ngIf="showEditField && formId == list.slot_id">
            <form (ngSubmit)="onEditSubmit(specialityform, list)" #specialityform="ngForm">
              <div class="form-group row justify-content-sm-center">
                <label for="check_in_time" class="col-sm-4 col-form-label">Check-in</label>
                <div class="col-sm-8">
                  <input name="check_in_time" (change)="changeStartTime($event)" type="time" ngModel
                    [(ngModel)]="detail.check_in_time" id="'check_in_time" #check_in_time="ngModel"
                    class="form-control" />
                </div>
              </div>

              <div class="form-group row justify-content-sm-center">
                <label for="check_out_time" class="col-sm-4 col-form-label">Check-out</label>
                <div class="col-sm-8">
                  <input name="check_out_time" (change)="changeEndTime($event)" type="time" ngModel
                    [(ngModel)]="detail.check_out_time" id="'check_out_time" #check_out_time="ngModel"
                    class="form-control" />
                </div>
              </div>

              <div class="form-group row justify-content-sm-center">
                <div class="card-action-footer mt-2">
                  <button type="button" class="btn btn-secondary mr-2" (click)="closeBtnClick()">
                    Close
                  </button>

                  <button type="submit" class="btn btn-secondary btn-raised mr-1" [disabled]="!specialityform.valid">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="main-slot-box p-3 my-3" *ngIf="slotDetail == 0">
          <p class="mb-0">No Time Slot Found</p>
        </div>
        <!-- <div class="main-slot-box  p-3 my-3" *ngIf="disableDate">
                    <p class="mb-0">No Time Slot Found</p>
                </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>
    <button type="button" class="close" (click)="closeModalBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="editUser" *ngIf="modeltitle == 'Delete Slot'">
      Are you sure want to Delete this Slot ?
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModalBtnClick()">
          Close
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteData()">
          Delete
        </button>
      </div>
    </form>
    <form *ngIf="modeltitle == 'Add New Slot'" (ngSubmit)="onEditSubmit(specialityform)" #specialityform="ngForm">
      <div class="form-group row justify-content-sm-center">
        <label for="slot_start_time" class="col-sm-4 col-form-label">Slot Start Time</label>
        <div class="col-sm-8">
          <input name="slot_start_time" [(ngModel)]="detail.slot_start_time" type="time" class="form-control"
            required />
        </div>
      </div>

      <div class="form-group row justify-content-sm-center">
        <label for="slot_end_time" class="col-sm-4 col-form-label">Slot End Time</label>
        <div class="col-sm-8">
          <input name="slot_end_time" [(ngModel)]="detail.slot_end_time" type="time" class="form-control" required />
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModalBtnClick()">
          Close
        </button>
        <button type="submit" class="btn btn-secondary btn-raised mr-1" [disabled]="!specialityform.valid">
          Submit
        </button>
      </div>
    </form>

    <form (ngSubmit)="onEditPackSubmit(specialityform, list)" #specialityform="ngForm"
      *ngIf="modeltitle == 'Edit Pack Detail'">
      <div class="form-group row justify-content-sm-center">
        <label for="start_date" class="col-sm-4 col-form-label">Package start Date</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" onkeydown="return true" [(ngModel)]="detail.start_date"
            min="{{ startDate }}" name="start_date" id="start_date" ngModel #start_date="ngModel" required />
        </div>
      </div>

      <div class="form-group row justify-content-sm-center">
        <label for="end_date" class="col-sm-4 col-form-label">Package End Date</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" onkeydown="return false" [(ngModel)]="detail.end_date"
            min="{{ startDate }}" name="end_date" id="end_date" ngModel #end_date="ngModel" required />
        </div>
      </div>

      <div class="form-group row justify-content-sm-center">
        <label for="check_in_time" class="col-sm-4 col-form-label">Check-in</label>
        <div class="col-sm-8">
          <input name="check_in_time" (change)="changeStartTime($event)" type="time" ngModel
            [(ngModel)]="detail.check_in_time" id="'check_in_time" #check_in_time="ngModel" class="form-control" />
        </div>
      </div>

      <div class="form-group row justify-content-sm-center">
        <label for="check_out_time" class="col-sm-4 col-form-label">Check-out</label>
        <div class="col-sm-8">
          <input name="check_out_time" (change)="changeEndTime($event)" type="time" ngModel
            [(ngModel)]="detail.check_out_time" id="'check_out_time" #check_out_time="ngModel" class="form-control" />
        </div>
      </div>

      <div class="form-group row justify-content-sm-center">
        <div class="card-action-footer mt-2">
          <button type="button" class="btn btn-secondary mr-2" (click)="closeModalBtnClick()">
            Close
          </button>

          <button type="submit" class="btn btn-secondary btn-raised mr-1" [disabled]="!specialityform.valid">
            Update
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>