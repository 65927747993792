<div class="row">
  <div class="col-8">
    <div class="map" id="map" class="match-parent"></div>
    <br />
    <div class="row" style="margin-left: 0px">
      <input type="text" #geofenceName required style="width: 500px; margin-right: 20px"
        class="form-control ng-pristine ng-valid" [(ngModel)]="selectedParking.name" />

      <button class="btn btn-primary" [disabled]="!selectedParking.name || !isShapeCreated" (click)="create()">
        {{ isCreateClicked ? "Save" : "Update" }}
      </button>
    </div>
  </div>
  <div class="col-4">
    <button class="btn btn-primary" [disabled]="isCreateClicked && parkings.length != 0" (click)="createShape()">
      Create
    </button>
    <table class="table table-striped border mt-4">
      <thead>
        <tr>
          <!-- <th scope="col">Name</th>
          <th scope="col">Actions</th> -->

          <th style="width: 100%; text-align: left">
            <label>Name</label>

            <label style="float: right">Actions</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of parkings; let i = index" (click)="editGeofence(d)" style="cursor: pointer">
          <a>
            <td style="width: 100%; text-align: left" class="btn" [class]="selectedParkingId == d._id ? 'active' : ''">
              {{ d.name }}
              <a style="float: right" href="javascript: void(0);" class="link mr-2" placement="top"
                (click)="openModal(deleteModal, d)" ngbTooltip="Delete">
                <i class="fa fa-trash"></i>
              </a>
            </td>
          </a>
          <!-- <td>{{ d.name }}</td>

          <td class="btn" [class]="selectedIndex == i ? 'active' : ''">
            <div>
              <a
                href="javascript: void(0);"
                class="link mr-2"
                placement="top"
                (click)="openModal(deleteModal, d)"
                ngbTooltip="Delete"
              >
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </td> -->
        </tr>

        <tr *ngIf="parkings.length == 0">
          <td colspan="3">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #deleteModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="editUserLabel">Delete Parking?</h5>

      <button type="button" class="close" (click)="closeModels()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Are you sure want to delete?
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeModels()">
            Close
          </button>
          <button type="submit" class="btn btn-danger" (click)="deleteGeofence()">
            Delete
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <!--   <ng-template #assetsModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="editUserLabel">Asset List</h5>

      <button type="button" class="close" (click)="closeModels()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table class="table table-striped border mt-4">
        <thead>
          <tr>
            <th scope="col">
              <input type="checkbox" [(ngModel)]="isMasterSel" name="list_name" value="h1"
                (change)="checkUncheckAll()" />
              All
            </th>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Owner</th>
            <th scope="col">Location</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
              let d of assetList
                | slice
                  : (assetPage - 1) * assetPageSize
                  : (assetPage - 1) * assetPageSize + assetPageSize
            ">
            <td>
              <input type="checkbox" [(ngModel)]="d.isSelected" name="list_name" value="{{ d._id }}"
                (change)="isAllSelected()" />
            </td>
            <td><img [src]="d?.lock_image" width="40" /></td>
            <td>{{ d?.lock_name }}</td>
            <td>{{ d?.lock_type }}</td>
            <td>{{ d?.owner_id?.name }}</td>
            <td>{{ d?.lock_address }}</td>
          </tr>
          <tr *ngIf="assetList.length == 0">
            <td colspan="4">No data found</td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center mt-5">
       <ngb-pagination
          [(page)]="page"
          [pageSize]="pageSize"
          [collectionSize]="assetList.length"
          [maxSize]="7"
          [rotate]="true"
          [ellipses]="false"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
        ></ngb-pagination> 

        <ngb-pagination [(page)]="assetPage" [pageSize]="assetPageSize" [collectionSize]="assetList.length"
          [maxSize]="7" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
      </div>
    </div>
    <div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModels()">
          Close
        </button>
        <button type="submit" class="btn btn-primary" (click)="createAssets()">
          Save
        </button>
      </div>
    </div>
  </ng-template> -->
</div>