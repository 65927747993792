<div class="row">
    <div class="col-12">
        <div class="card card-body">
            <div class="">
                <h4 class="card-title mb-0">
                    <span class="lstick"></span>Request Payment
                </h4>
                <div class="d-flex mt-3">
                    <a href="javascript: void(0);" class="btn btn-primary ml-auto" placement="top"
                        (click)="openModal(AddModal, {}, 'Create Package')" ngbTooltip="Create Package">
                        + Create
                    </a>
                </div>

                <div class="">
                    <table class="table table-striped border mt-4">
                        <thead>
                            <tr>
                                <!-- <th scope="col">Image</th> -->
                                <th scope="col">Email</th>
                                <th scope="col">Username</th>
                                <th scope="col">Price</th>
                                <th scope="col">Description</th>
                                <th scope="col">Is {{this.operatorDetails&& this.operatorDetails.tax_type&&
                                    this.operatorDetails.tax_type ?
                                    this.operatorDetails.tax_type :"VAT"}} Inclusive/Exclusive</th>
                                <th scope="col">Chargable Amount</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of listData">
                                <!-- <td><a
                                    href="javascript: void(0);"
                                    class="link mr-2"
                                    placement="top"
                                    (click)="openImageModal(imageModel, d)"
                                    ngbTooltip="Images"
                                  >
                                    <i class="fa fa-eye"></i>
                                  </a>
                                </td> -->
                                <td>{{d.email}}</td>
                                <td>{{d.operator_id?.username}}</td>
                                <td>{{d.price}}</td>
                                <td>{{d.description}}</td>
                                <td *ngIf="d.isExclusive == false">Inclusive</td>
                                <td *ngIf="d.isExclusive == true">Exclusive</td>
                                <td>{{d.chargeableAmount}}</td>
                                <td>
                                    <a href="javascript: void(0);" class="link mr-2" placement="top"
                                        (click)="openModal(AddModal, d, 'Edit Package')" ngbTooltip="Edit">
                                        <i class="fa fa-edit"></i>
                                    </a>
                                    <a href="javascript: void(0);" class="link mr-2" placement="top"
                                        (click)="openModal(AddModal, d, 'Delete Package')" ngbTooltip="Delete">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- image
email
username
description
price
chargableamount
vat
inclusive -->

<ng-template #AddModal let-modal>
    <div class="p-3">
        <div class="modal-header">
            <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>
            <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <form *ngIf="modeltitle == 'Create Package' || modeltitle == 'Edit Package'" [formGroup]="specialityform"
                (ngSubmit)="onSubmit(specialityform.value)">
                <div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Email</label>
                        <input class="form-control" type="text" name="model1" [ngModel]="data?.email"
                            formControlName="email" [(ngModel)]="model1" (keyup)="search($event)"
                            placeholder="Search members to add" />
                        <ul class="list-group">
                            <li class="form-control" (click)="OnClick(u)" class="list-group-item"
                                *ngFor="let u of searchresult ; let i = index">
                                {{u.email}}
                            </li>
                        </ul>

                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Estimated Delivery Date</label>
                        <div class="col-sm-8">
                            <input formControlName="estimated_delivery_date" name="estimated_delivery_date" type="date"
                                [(ngModel)]="detail.estimated_delivery_date" id="estimated_delivery_date"
                                class="form-control" required />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Estimated Delivery Time</label>
                        <div class="col-sm-8">
                            <input formControlName="estimated_completion_time" name="estimated_completion_time"
                                type="time" [(ngModel)]="detail.estimated_completion_time" class="form-control"
                                required />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Price</label>
                        <div class="col-sm-8">
                            <input formControlName="price" [(ngModel)]="detail.price" name="price" type="double"
                                class="form-control" required />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">{{this.operatorDetails&& this.operatorDetails.tax_type&&
                            this.operatorDetails.tax_type ?
                            this.operatorDetails.tax_type :"VAT"}} (%)</label>
                        <div class="col-sm-8">
                            <input formControlName="vat" [(ngModel)]="detail.vat" name type="double"
                                class="form-control" pattern="(\s*[^\s]+\s*)+" id="vat" step="0.01" min="0" name="vat"
                                required />
                        </div>
                    </div>


                    <div class="row form-group">
                        <label for="timing" class="col-sm-4 col-form-label">Is {{this.operatorDetails&&
                            this.operatorDetails.tax_type&&
                            this.operatorDetails.tax_type ?
                            this.operatorDetails.tax_type :"VAT"}} inculsive or exclusive?</label>
                        <div class="col-sm-8 ">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" formControlName="isExclusive" type="radio"
                                    name="isExclusive" id="inlineRadio1" [(ngModel)]="detail.isExclusive"
                                    [value]="false" />
                                <label class="form-check-label" for="inlineRadio1">Inculsive</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" [(ngModel)]="detail.isExclusive"
                                    formControlName="isExclusive" type="radio" name="isExclusive" id="inlineRadio2"
                                    [value]="true" />
                                <label class="form-check-label" for="inlineRadio2">Exclusive</label>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label for="pkg_desc" class="col-sm-4 col-form-label">Description</label>
                        <div class="col-sm-8">
                            <input formControlName="description" [(ngModel)]="detail.description" type="text"
                                class="form-control" name="description" pattern="(\s*[^\s]+\s*)+" id="pkg_desc"
                                maxlength="100" />
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label for="image" class="col-sm-4 col-form-label">Images</label>
                        <div class="col-sm-8">
                            <input formControlName="request_payment_images" [(ngModel)]="detail.image" type="file"
                                class="form-control" name="request_payment_images"  id="request_payment_images" (change)="onSelectFile($event)" multiple/>
                                <div *ngFor='let url of urls;let i=index'>
                                    <div style="position: relative;height:120px;width: 120px;">
                                    <img [src]="url" height="100" width="100px">
                                    <i class="fa fa-trash fa-danger" (click)="deleteimage(i)" style="position: absolute;top: 0%;right: 0%;cursor: pointer;"></i>
                                    </div>
                                </div>
                                 <br/>
                        </div>
                    </div> -->

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
                        Close
                    </button>

                    <button type="submit" class="btn btn-secondary btn-raised mr-1">
                        Submit
                    </button>
                </div>
            </form>
            <form [formGroup]="editUser" *ngIf="modeltitle == 'Delete Package'">
                Are you sure want to delete?
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
                        Close
                    </button>
                    <button type="submit" class="btn btn-danger" (click)="deleteData()">
                        Delete
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #imageModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="lockerImageLabel"> Images</h5>
        <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div>
                <img width="100%" style="margin-top: 10px" />
            </div>
        </div>
    </div>
    <div></div>
</ng-template>