import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { ApiCallingService } from "../../services/api-calling.service";
import { CsvService } from "../../services/csv.service";
import { ConfigApi } from "../../config-api";
import { NgForm } from "@angular/forms";
import { DatePipe } from "@angular/common";

import { Globals } from "../../common/global";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";

@Component({
  selector: "app-lock-fallback",
  templateUrl: "./lock-fallback.component.html",
  styleUrls: ["./lock-fallback.component.css"],
})
export class LockFallbackComponent implements OnInit {
  today: any = moment(new Date()).format("YYYY-MM-DD");
  startday: any;
  endday: any;
  constructor(
    private modalService: NgbModal,
    private CsvService: CsvService,
    private sanitizer: DomSanitizer,
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    public http: HttpClient,
    public datepipe: DatePipe
  ) {}
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  ngOnInit(): void {
    this.getList();
    console.log(this.data);
  }

  getList() {
    let user_id = this.Globals.getUser()._id;
    var body = {
      userId: user_id,
      page: this.page - 1,
      limit: this.pageSize,
    };
    this.apiCallingService
      .callAPI(
        body,
        "GET",
        ConfigApi.URLS.LOCK_FALLBACK_HISTORY_LIST + "/" + user_id
      )
      .subscribe((res) => {
        this.data = res.data;
        console.log(this.data);

        this.totalcount = res.data?.length;
      });
  }

  loadPage(page: number) {
    this.page = page;
    this.getList();
  }
  openImageModal(imageModel: NgbModal, data: any) {
    this.detail = data?.lock_fallback_images;
    console.log(this.detail);

    if (this.detail?.length > 0 && this.detail?.length != undefined) {
      this.modalService.open(imageModel, {
        centered: true,
        backdrop: "static",
      });
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  loadReceipt(id: any) {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.GET_TRANSACTIONS_RECEIPT + "?_id=" + id
      )
      .subscribe((res) => {
        if (!Array.isArray(res.data)) {
          window.open(res.data);
        } else {
          let aa = new Uint8Array(res.data);
          let file = new Blob([aa], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      });
  }
  loadReceiptLeasePackage(URL: any) {
    if (URL) {
      window.open(URL);
    }
  }
  formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  getCsv() {
    var dt = new Date();
    var month = (dt.getMonth() + 1).toString().padStart(2, "0");
    var year = dt.getFullYear();
    var firstDay = this.formatDate(
      new Date(dt.getFullYear(), dt.getMonth(), 1)
    );
    var lastDay = this.formatDate(
      new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
    );
    let body = new HttpParams()
      .set("operator_id", this.Globals.getUser()._id)
      .set("request_type", "csv");
    if (this.startday && this.endday) {
      body = new HttpParams()
        .set("operator_id", this.Globals.getUser()._id)
        .set("startOf", this.startday)
        .set("endOf", this.endday)
        .set("request_type", "csv");
    }
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.GET_TRANSACTIONS_CSV)
      .subscribe((res) => {
        let receptdata = res.data.list;

        let c = [];
        for (let index = 0; index < receptdata.length; index++) {
          if (receptdata[index].receipt != null) {
            receptdata[index].receipt.Date = this.datepipe.transform(
              receptdata[index].createdAt,
              "yyyy-MM-dd"
            );
            receptdata[index].receipt.UserName =
              receptdata[index].from_user_id.name;
            receptdata[index].receipt.EmailID =
              receptdata[index].from_user_id.email;
            receptdata[index].receipt.PackageType = receptdata[index].receipt
              .packageType
              ? receptdata[index].receipt.packageType
              : "-";
            receptdata[index].receipt.InvoiceNumber = receptdata[index]
              .stripe_response.invoice_number
              ? receptdata[index].stripe_response.invoice_number
              : "";
            receptdata[index].receipt.TransactionID = receptdata[index]
              .stripe_response.id
              ? receptdata[index].stripe_response.id
              : "";
            receptdata[index].receipt.ISExclusive =
              receptdata[index].receipt.isExclusive;
            //receptdata[index].receipt.ApplicationFee = receptdata[index].stripe_response.application_fee_amount ? receptdata[index].stripe_response.application_fee_amount / 100 : 0;
            receptdata[index].receipt.PackageAmount = receptdata[index].receipt
              .packageAmount
              ? receptdata[index].receipt.packageAmount
              : 0;
            receptdata[index].receipt.RefundedAmount = receptdata[index].receipt
              .refundAmount
              ? receptdata[index].receipt.refundAmount
              : 0;
            receptdata[index].receipt.CouponCodeType = receptdata[index].receipt
              .couponCodeType
              ? receptdata[index].receipt.couponCodeType
              : "-";
            receptdata[index].receipt.CouponCode = receptdata[index].receipt
              .couponCode
              ? receptdata[index].receipt.couponCode
              : "-";
            receptdata[index].receipt.RideTime = receptdata[index].receipt
              .rideTime
              ? receptdata[index].receipt.rideTime
              : 0;
            receptdata[index].receipt.BasePrice = receptdata[index].receipt
              .basePrice
              ? receptdata[index].receipt.basePrice
              : 0;
            receptdata[index].receipt.WithoutVat = receptdata[index].receipt
              .finalAmountWithoutVAT
              ? receptdata[index].receipt.finalAmountWithoutVAT
              : 0;
            receptdata[index].receipt.VatPercentage = receptdata[index].receipt
              .vatPercentage
              ? receptdata[index].receipt.vatPercentage
              : 0;
            receptdata[index].receipt.Vat = receptdata[index].receipt.vat
              ? receptdata[index].receipt.vat
              : 0;
            receptdata[index].receipt.Currency = receptdata[index]
              .stripe_response.currency
              ? receptdata[index].stripe_response.currency.toUpperCase()
              : "-";
            //receptdata[index].receipt.totalPaid = receptdata[index].receipt.totalPaid ? receptdata[index].receipt.totalPaid : 0;
            receptdata[index].receipt.totalPaid =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data.amount
                ? `${receptdata[index].stripe_response.balance_transaction_data.amount}`
                : 0;
            receptdata[index].receipt.StripeFees =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data
                .stripe_fee
                ? `${receptdata[index].stripe_response.balance_transaction_data.stripe_fee}`
                : 0;
            receptdata[index].receipt.ApplicationFee =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data
                .application_fee
                ? `${receptdata[index].stripe_response.balance_transaction_data.application_fee}`
                : 0;
            receptdata[index].receipt.NetReceived =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data.net
                ? `${receptdata[index].stripe_response.balance_transaction_data.net}`
                : 0;
            c.push(receptdata[index].receipt);
          } else {
            receptdata[index].stripe_response.Date = moment(
              receptdata[index].createdAt
            ).format("YYYY-MM-DD");
            receptdata[index].stripe_response.UserName =
              receptdata[index].from_user_id.name;
            receptdata[index].stripe_response.EmailID =
              receptdata[index].from_user_id.email;
            receptdata[index].stripe_response.PackageType = "-";
            receptdata[index].stripe_response.InvoiceNumber = receptdata[index]
              .stripe_response.number
              ? receptdata[index].stripe_response.number
              : "";
            receptdata[index].stripe_response.TransactionID = receptdata[index]
              .stripe_response.id
              ? receptdata[index].stripe_response.id
              : "";
            receptdata[index].stripe_response.ISExclusive = "-";
            //receptdata[index].stripe_response.ApplicationFee = receptdata[index].stripe_response.application_fee_amount ? receptdata[index].stripe_response.application_fee_amount / 100 : 0;
            receptdata[index].stripe_response.PackageAmount = 0;
            receptdata[index].stripe_response.RefundedAmount = 0;
            receptdata[index].stripe_response.CouponCodeType = "-";
            receptdata[index].stripe_response.CouponCode = "-";
            receptdata[index].stripe_response.RideTime = 0;
            receptdata[index].stripe_response.BasePrice = 0;
            receptdata[index].stripe_response.WithoutVat = 0;
            receptdata[index].stripe_response.VatPercentage = 0;
            receptdata[index].stripe_response.Vat = 0;
            receptdata[index].stripe_response.Currency = receptdata[index]
              .stripe_response.currency
              ? receptdata[index].stripe_response.currency.toUpperCase()
              : "-";
            //receptdata[index].stripe_response.totalPaid = receptdata[index].stripe_response.total ? (receptdata[index].stripe_response.total/100).toFixed(2) : 0;

            receptdata[index].stripe_response.totalPaid =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data.amount
                ? `${receptdata[index].stripe_response.balance_transaction_data.amount}`
                : 0;
            receptdata[index].stripe_response.StripeFees =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data
                .stripe_fee
                ? `${receptdata[index].stripe_response.balance_transaction_data.stripe_fee}`
                : 0;
            receptdata[index].stripe_response.ApplicationFee =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data
                .application_fee
                ? `${receptdata[index].stripe_response.balance_transaction_data.application_fee}`
                : 0;
            receptdata[index].stripe_response.NetReceived =
              receptdata[index].stripe_response &&
              receptdata[index].stripe_response.balance_transaction_data &&
              receptdata[index].stripe_response.balance_transaction_data.net
                ? `${receptdata[index].stripe_response.balance_transaction_data.net}`
                : 0;
            c.push(receptdata[index].stripe_response);
          }
        }
        console.log(c);
        this.CsvService.downloadFile(c, "Transaction_History");
      });
  }
}
