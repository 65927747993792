import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { ApiCallingService } from "../../services/api-calling.service";

import { ConfigApi } from "../../config-api";
import { NgForm } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { Globals } from "../../common/global";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-review",
  templateUrl: "./review.component.html",
  styleUrls: ["./review.component.css"],
})
export class CustomerReviewComponent implements OnInit {
  today: any = moment(new Date()).format("YYYY-MM-DD");
  startday: any;
  endday: any;
  show = true;
  showSecond = false;
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  status: any;
  type: string = "reviews";

  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    public http: HttpClient,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getList();
  }
  getList() {
    let page = this.page == 0 ? 0 : this.page - 1;

    let query =
      "?type=" +
      this.type +
      "&operator_id=" +
      this.Globals.getUser()._id +
      "&page=" +
      page +
      "&limit=" +
      this.pageSize;

    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.CUSTOMER_REVIEW + query)
      .subscribe((res) => {
        this.data = res.data;
        this.totalcount = res.total;
      });
  }

  loadPage(page: number) {
    if (page) {
      this.page = page;
      this.getList();
    }
  }
  loadPageForReview(type: string) {
    if (type) {
      this.type = type;
      this.getList();
    }
  }

  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
      size: "lg",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
    this.status =
      this.detail.status == "" || this.detail.status == undefined
        ? "publish"
        : this.detail.status;
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }

  onSubmit(f: any) {
    const body = new HttpParams()
      .set("operator_reply", f.value.operator_reply)
      .set("status", this.status);
    this.apiCallingService
      .callAPI(
        body,
        "POST",
        ConfigApi.URLS.UPDATE_CUSTOMER_REPLY_OPRATOR + "/" + this.detail._id
      )
      .subscribe((res) => {
        this.getList();
        this.modalService.dismissAll();
      });
  }
  changeStatus() {
    const body = new HttpParams().set("status", this.status);
    this.apiCallingService
      .callAPI(
        body,
        "POST",
        ConfigApi.URLS.UPDATE_CUSTOMER_REPLY_OPRATOR + "/" + this.detail._id
      )
      .subscribe((res) => {});
  }
}
