import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as mapboxgl from "mapbox-gl";

import { ToastrService } from "ngx-toastr";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { environment } from "src/environments/environment";
import { Globals } from "../../../common/global";

@Component({
  selector: "app-parking-violation-detail",
  templateUrl: "./parking-violation-detail.component.html",
  styleUrls: ["./parking-violation-detail.component.css"],
})
export class ParkingViolationDetailComponent implements OnInit {
  map!: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  lat = 37.75;
  lng = -122.41;
  params: any;
  dashboard: any;
  detail: any;
  parkings: any[] = [];
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    private route: ActivatedRoute,
    public Globals: Globals
  ) {
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }

  ngOnInit(): void {
    //mapbox
    this.initMap();
    this.route.params.subscribe((params) => (this.params = params));
    this.getDetail();
  }
  initMap(): void {
    this.map = new mapboxgl.Map({
      container: "map",
      style: this.style,
      zoom: 13,
      center: [this.lng, this.lat],
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    /*   this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
      })
    ); */

    this.map.on("draw.create", (e) => {});

    this.map.on("draw.modechange", (e) => {});

    this.map.on("load", () => {
      this.getList();
    });
  }
  getDetail() {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.PARKING_VIOLATION.GET + this.params.id)
      .subscribe((res) => {
        this.detail = res.data;

        this.lat = this.detail.ride_id.start_latitude;
        this.lng = this.detail.ride_id.start_longitude;
        // // Add map controls
        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            // When active the map will receive updates to the device's location as it changes.
            trackUserLocation: true,
            // Draw an arrow next to the location dot to indicate which direction the device is heading.
            showUserHeading: true,
          }),
          "top-left"
        );
        const startpointtext = new mapboxgl.Popup({ offset: 25 }).setText(
          "Start : " + this.detail.ride_id.start_address
        );
        const endpointtext = new mapboxgl.Popup({ offset: 25 }).setText(
          "End : " + this.detail.ride_id.end_address
        );

        // //adding marker
        new mapboxgl.Marker({ color: "red" })
          .setLngLat([
            this.detail.ride_id.start_longitude,
            this.detail.ride_id.start_latitude,
          ])
          .setPopup(startpointtext)
          .addTo(this.map);
        if (this.detail.ride_id.end_longitude) {
          new mapboxgl.Marker({ color: "red" })
            .setLngLat([
              this.detail.ride_id.end_longitude,
              this.detail.ride_id.end_latitude,
            ])
            .setPopup(endpointtext)
            .addTo(this.map);
        }
        // //focus on marker position
        this.map.flyTo({
          center: [
            this.detail.ride_id.start_longitude,
            this.detail.ride_id.start_latitude,
          ],
          zoom: 10,
          essential: true,
        });
      });
  }
  // getDashboard(id: any) {
  //   this.apiCallingService
  //     .callAPI({}, "GET", ConfigApi.URLS.GET_RIDE_HISTORY_DETAILS + "/" + id)
  //     .subscribe((res) => {
  //       if (this.apiCallingService.handleSuccess(res)) {
  //         if (res.data.length > 0) {
  //           let coordinates = res.data.map((coordinate: any, index: any) => {
  //             return [coordinate.longitude, coordinate.latitude];
  //           });

  //           this.DrowLinOnMap(coordinates);
  //         }
  //       }
  //     });
  // }

  // DrowLinOnMap(coordinates: any) {
  //   this.map.on("load", () => {
  //     this.map.addSource("route", {
  //       type: "geojson",
  //       data: {
  //         type: "Feature",
  //         properties: {},
  //         geometry: {
  //           type: "LineString",
  //           coordinates: coordinates,
  //         },
  //       },
  //     });
  //     this.map.addLayer({
  //       id: "route",
  //       type: "line",
  //       source: "route",
  //       layout: {
  //         "line-join": "round",
  //         "line-cap": "round",
  //       },
  //       paint: {
  //         //'line-color': '##FF0000',
  //         "line-width": 8,
  //       },
  //     });
  //   });
  // }
  getList() {
    let user_id = this.Globals.user._id;
    let query = "?operator_id=" + user_id;
    // let query = "?operator_id=615aa5142886af45d9edcaba"
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GEOFENCE.LIST + query)
      .subscribe((res) => {
        //PolyLine

        res.data.forEach((el: any) => {
          this.map.addSource(el._id, {
            type: "geojson",
            data: el.geometry,
          });
          this.map.addLayer({
            id: el._id,
            type: "line",
            source: el._id,
            layout: {},
            paint: {
              "line-color": "#DC143C",
              "line-width": 3,
            },
          });
          el.parking_areas.forEach((parking: any) => {
            this.map.addSource(parking._id, {
              type: "geojson",
              data: parking.geometry,
            });
            this.map.addLayer({
              id: parking._id,
              type: "fill",
              source: parking._id,
              layout: {},
              paint: {
                "fill-color": "#1565C0",
                "fill-opacity": 0.6,
              },
            });
          });
        });
      });
  }
}
