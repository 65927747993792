import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Globals } from '../../common/global';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.css']
})
export class OperationComponent implements OnInit {

  operation:boolean=this.Globals.getUser().business_detail.is_live;
  modeltitle : any;
  params:any=[];
  stepscompleted:boolean =true;
  constructor(private modalService: NgbModal,
    public routes: Router,
    private route:ActivatedRoute,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals) { 
      this.route.params.subscribe(params => this.params = params);
      if(this.params.paid){
       
        setTimeout(() => { 
          this.getProfile();
          this.getProfile();
          this.getProfile();
        },2000)
      }
       
      
    }

  ngOnInit(): void {
    console.log('aaayu',this.Globals.getUser().business_detail.is_live);
    this.getProfile();
    
  }
  openModal(targetModal: NgbModal) {
    this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
    });
    if(
      this.Globals.getUser().flags.is_business_detail_added && 
      this.Globals.getUser().flags.is_assets_registered &&
      this.Globals.getUser().flags.is_stripe_connected && 
      this.Globals.getUser().flags.is_package_created
       ){
          this.stepscompleted = true;
          if(!this.Globals.getUser().subscription.is_paid)
          {
            let price=this.Globals.getUser().subscription.price;
            let curr =this.Globals.getUser().subscription.currency.code;
            this.modeltitle="Your Payment of "+price+" "+curr+"fo subscription is pending.\n Please add/select payment source to make all your assets functional and publicly available";
          }else{
            this.modeltitle = !this.operation ? 'Your account will be functional and operative,All your asset will be publicly available.' : 'Are you sure? Inoperative mode will deactivate all your assets';
          }

       }else{
        this.stepscompleted = false;
        console.log(this.operation);
        if(this.operation==false){
          let step1 = this.Globals.getUser().flags.is_business_detail_added ? "<i class='fas fa-check-circle'></i>" : "-Pending";
          let step2 = this.Globals.getUser().flags.is_assets_registered ? "<i class='fas fa-check-circle'></i>" : "-Pending";
          let step3 = this.Globals.getUser().flags.is_stripe_connected ? "<i class='fas fa-check-circle'></i>" : "-Pending";
          let step4 = this.Globals.getUser().flags.is_package_created ? "<i class='fas fa-check-circle'></i>" : "-Pending";


          this.modeltitle="<p>Please complete all 4 steps to make your operator account live</p> <p>1.Complete business profile "+step1+"</p><p>2.Register asset "+step2+"</p><p>3.Verify payment account "+step3+"</p><p>4.Rent package should be created "+step4+"</p>";
        }else{
          this.modeltitle = !this.operation ? 'Your account will be functional and operative,All your asset will be publicly available.' : 'Are you sure? Inoperative mode will deactivate all your assets';
        }
        
       }
   
    
  }
  closeBtnClick() {
    this.modalService.dismissAll();
    this.operation=!this.operation;
  }
  change(){
    if(!this.Globals.getUser().subscription?.is_paid){
      this.modalService.dismissAll();
      this.routes.navigate(['/user/payment-method/pay']);
    }else{
      let user_id = this.Globals.getUser()._id;
      let query = '?user_id='+user_id+'&is_live='+this.operation;
      this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.PUT_IN_OPERATION+query)
        .subscribe(res => {
          this.toastr.success(res.msg);
          this.Globals.setUser(res.data);
          this.modalService.dismissAll();
        });
    }
  }
  getProfile() { 
    let username = this.Globals.getUser().username;
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.MY_PROFILE.GET_DETAIL+'/'+username)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.Globals.setUser(res.data);
          this.operation = res.data.business_detail.is_live;
        } 
      });
  }

}
