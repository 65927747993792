<div class="row">
    <div class="col-12">
      <div class="card card-body">
  
        <!-- <div class="">
          <h4 class="card-title mb-0"><span class="lstick"></span>FAQ </h4>
           
        </div> -->
        <div class="col-12">
              
             <div class="" *ngFor="let f of data;let i = index">
               <h4>Q {{i+1}}: {{f.question}}</h4>
               <label>Ans : {{f.answer}}</label>
             </div>

          
        </div>
  
      </div>
    </div>
  </div>
   