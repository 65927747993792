import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { NgForm } from "@angular/forms";
import { Globals } from "../../common/global";
import { environment } from "../../../environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import * as mapboxgl from "mapbox-gl";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  @ViewChild("profileform", { static: true }) floatingLabelForm: NgForm =
    Object.create(null);
  @ViewChild("changepasswordform", { static: true })
  changepasswordform: NgForm = Object.create(null);

  user: any = {};
  sessiondata: any;
  imageSrc: any;
  file: any;
  style = "mapbox://styles/mapbox/streets-v11";
  allcountry: any;
  isDivVisible: boolean = false;
  mainObj: any;
  map: any = mapboxgl.Map;
  detail: any;
  lat: any;
  lng: any;
  countryShortCode: any;
  country: any;
  hideCountry: boolean = true;
  latitude: any;
  longitude: any;
  countryCode: string = "";

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    private modalService: NgbModal,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.getProfile();
    this.http.get("assets/countries.json").subscribe((data) => {
      this.allcountry = data;
    });
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.getCurrentCountry(this.lng, this.lat);
      });
    }

    setTimeout(() => {
      this.map = new mapboxgl.Map({
        container: "map",
        style: this.style,
        zoom: 13,
        center: [this.lng, this.lat],
      });
      this.map.addControl(new mapboxgl.FullscreenControl());
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "top-left"
      );
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken, // Set the access token
        placeholder: "Search with address or name",
        marker: false,
      });
      this.map.addControl(geocoder);
      this.map.on("load", () => {
        this.getDetails(this.lng, this.lat);
      });
    }, 1);
  }

  getCurrentCountry(lat: any, lng: any) {
    let url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      +lng +
      ", " +
      lat +
      ".json?access_token=" +
      environment.mapbox.accessToken;
    let geodata;
    this.apiCallingService.callAPI({}, "GET", url).subscribe((res) => {
      geodata = res.features;
      let c = geodata.find((cn: any) => {
        if (cn.id.includes("country")) {
          return cn;
        }
      });
      this.country = c?.text;
    });
  }
  getProfile() {
    let username = this.Globals.user.username;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.MY_PROFILE.GET_DETAIL + "/" + username)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.user = res.data;
          console.log(this.user.location);
          this.longitude = this.user.location.coordinates[0];
          this.latitude = this.user.location.coordinates[1];
          this.countryCode = this.user.dialling_code;
          this.imageSrc = this.user.user_image;
        }
      });
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.file = file;
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
      this.imageSrc = file;
    }
  }
  onSubmitGenral(f: any) {
    console.log(this.latitude, this.longitude);
    console.log("location data", f.value.address);
    const uploadData = new FormData();
    if (this.file) {
      uploadData.append("user_image", this.file, this.file.name);
    }
    uploadData.append("name", f.value.name);
    uploadData.append("mobile", f.value.mobile.number);
    uploadData.append("email", f.value.email);
    uploadData.append("user_profile_status", f.value.status);
    uploadData.append("longitude", this.longitude);
    uploadData.append("latitude", this.latitude);
    uploadData.append(
      "address",
      f.value.address ? f.value.address : this.detail.address
    );
    uploadData.append("dialling_code", this.countryCode);
    let username = this.Globals.getUser().username;
    console.log(uploadData);

    this.apiCallingService
      .callAPIwithFile(
        uploadData,
        "POST",
        ConfigApi.URLS.MY_PROFILE.GET_DETAIL + "/" + username
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.Globals.setUser(res.data);
          this.toastr.success(res.msg);
          this.getProfile();
        }
      });
  }
  onCountryChange(event: any) {
    this.countryCode = `+${event.dialCode}`;
  }
  openMapBoxModel(targetModal: NgbModal) {
    this.isDivVisible = true;
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    setTimeout(() => {
      (mapboxgl as any).accessToken = environment.mapbox.accessToken;
      this.map = new mapboxgl.Map({
        container: "map",
        style: this.style,
        zoom: 13,
        center: [this.lng, this.lat],
      });
      this.map.addControl(new mapboxgl.FullscreenControl());
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "top-left"
      );
      const geocoder = new MapboxGeocoder({
        accessToken: environment.mapbox.accessToken,
        mapboxgl: mapboxgl,
      });
      this.map.addControl(geocoder);
      geocoder.on("result", (event) => {
        console.log(event);

        this.mainObj = event.result;
        // Extract the coordinates from the search result
        this.latitude = event.result.center[1];
        this.longitude = event.result.center[0];
        console.log(this.latitude, this.longitude);

        // Update the map's center to the selected coordinates
        this.map.setCenter([this.longitude, this.latitude]);
      });
      // let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlofPopup);
      // let m = new mapboxgl.Marker()
      //   .setLngLat([this.mainObj.center[0], this.mainObj.center[1]])
      //   .setPopup(popup)
      //   .addTo(this.map);
    });
  }

  saveLocation() {
    this.user.address = this.mainObj.place_name ? this.mainObj.place_name : "";
  }

  getDetails(lat: any, lng: any) {
    console.log("lat data", lat, "lng data", lng);
    console.log(this.mainObj);
    let url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      +lng +
      ", " +
      lat +
      ".json??country=" +
      this.countryShortCode +
      "&types=address&access_token=" +
      environment.mapbox.accessToken;
    let geodata;
    this.apiCallingService.callAPI({}, "GET", url).subscribe((res) => {});
  }
}
