import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { Globals } from "../../common/global";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTheme,
  ApexGrid,
} from "ng-apexcharts";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  @ViewChild("chartObj") chart: any = ChartComponent;

  terms: any;
  salesChartOptions: any;
  rideChartOptions: any;
  curr: any;
  select1: any = "day";
  select2: any = "day";
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    public Globals: Globals
  ) {
    this.curr = this.Globals.getUser().subscription?.currency?.code;

    this.chart1Set();
  }

  chart1Set() {
    this.salesChartOptions = {
      series: [
        { name: "Revenue", data: [0, 500, 600, 800, 2800, 900, 800, 2200] },
      ],
      chart: {
        fontFamily: "Nunito Sans,sans-serif",
        height: 250,
        type: "area",
        toolbar: { show: false },
      },
      dataLabels: { enabled: false },
      markers: { size: 3, strokeColors: "transparent" },
      stroke: { curve: "smooth", width: "2" },
      colors: ["#2962ff", "#4fc3f7"],
      legend: { show: false },
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: "rgba(0,0,0,0.1)",
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: true } },
      },
      xaxis: {
        type: "category",
        categories: ["Feb", "Mar", "Apr", "May", "Jun"],
        labels: { style: { colors: "#a1aab2" } },
      },
      tooltip: { theme: "dark" },
    };
    this.rideChartOptions = {
      series: [
        { name: "Revenue", data: [0, 500, 600, 800, 2800, 900, 800, 2200] },
      ],
      chart: {
        fontFamily: "Nunito Sans,sans-serif",
        height: 250,
        type: "area",
        toolbar: { show: false },
      },
      dataLabels: { enabled: false },
      markers: { size: 3, strokeColors: "transparent" },
      stroke: { curve: "smooth", width: "2" },
      colors: ["#1EAA20", "#4fc3f7"],
      legend: { show: false },
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: "rgba(0,0,0,0.1)",
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: true } },
      },
      xaxis: {
        type: "category",
        categories: ["Feb", "Mar", "Apr", "May", "Jun"],
        labels: { style: { colors: "#a1aab2" } },
      },
      tooltip: { theme: "dark" },
    };
  }

  ngOnInit(): void {
    this.getPackageCount();
    this.getAllPackageCount();
    this.getRidesCount();
    this.getRevenue();
    this.getRides();
    this.getLockCount();
    this.getTotalIncome();
  }

  ridesCount = 0;
  lockCount = 0;
  packageCount = 0;
  rentpackageCount = 0;
  leasepackageCount = 0;
  upcomingBookingCount = 0;
  allpackageCount = 0;
  revenue = 0;
  rides = 0;
  totalincome = 0;
  totalincome1 = 5559.75;
  advanceBooking = 0;
  getRidesCount() {
    const body = new HttpParams().set("userId", this.Globals.getUser()._id);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_RIDES_COUNT)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.ridesCount = res.data;
        }
      });
  }
  getLockCount() {
    const body = new HttpParams()
      .set("userId", this.Globals.getUser()._id)
      .set("in_use", false);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_LOCK_COUNT)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.lockCount = res.data;
        }
      });
  }
  getPackageCount() {
    const body = new HttpParams().set("userId", this.Globals.getUser()._id);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_PACKAGE)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.packageCount = res.data.count;
        }
      });
  }
  getAllPackageCount() {
    const body = new HttpParams().set("userId", this.Globals.getUser()._id);
    this.apiCallingService
      .callAPI(
        body,
        "GET",
        ConfigApi.URLS.DASHBOARD.ALL_GET_PACKAGE +
        "/" +
        this.Globals.getUser()._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          // console.log("🚀 ~ file: home.component.ts:170 ~ HomeComponent ~ .subscribe ~ res:", res)
          this.rentpackageCount = res.data.Rent;
          this.allpackageCount = res.data.Sale;
          this.leasepackageCount = res.data.Lease;
          this.upcomingBookingCount = res.data.Upcoming_booking;

          this.advanceBooking = res.data.Advance_booking;
        }
      });
  }
  getRevenue() {
    const body = new HttpParams()
      .set("userId", this.Globals.getUser()._id)
      .set(this.select1, 1);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_REVENUE)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          //this.revenue= res.data[res.data.length-1].Sunday;
          let keys = [];
          let values = [];
          for (var k of res.data) {
            keys.push(Object.keys(k)[0]);
            values.push(Object.values(k)[0]);
          }
          if (this.select1 == "week") {
            keys = ["Week 2", "Week 1", "Last week"];
          }
          this.salesChartOptions.xaxis = {
            type: "category",
            categories: keys,
            labels: {
              style: {
                colors: "#a1aab2",
              },
            },
          };
          this.salesChartOptions.series = [
            {
              name: "Revenue in " + this.curr,
              data: values,
            },
          ];
        }
      });
  }
  getTotalIncome() {
    const body = new HttpParams().set("userId", this.Globals.getUser()._id);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_REVENUE)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.totalincome = res.data[0].Total;
        }
      });
  }
  getRides() {
    const body = new HttpParams()
      .set("userId", this.Globals.getUser()._id)
      .set(this.select2, 1);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_RIDES)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.rides = res.data;
          let keys = [];
          let values = [];
          for (var k of res.data) {
            keys.push(Object.keys(k)[0]);
            values.push(Object.values(k)[0]);
          }
          if (this.select2 == "week") {
            keys = ["Week 2", "Week 1", "Last week"];
          }
          this.rideChartOptions.xaxis = {
            type: "category",
            categories: keys,
            labels: {
              style: {
                colors: "#a1aab2",
              },
            },
          };
          this.rideChartOptions.series = [
            {
              name: "Rides",
              data: values,
            },
          ];
        }
      });
  }
  changeSaleschart() {
    this.getRevenue();
  }
  changeRidechart() {
    this.getRides();
  }
}
