import { Component, AfterViewInit, EventEmitter, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiCallingService } from "../../services/api-calling.service";
import { Constants } from "../../common/constants";
import { ConfigApi } from "../../config-api";
import { Globals } from "../../common/global";
import { ToastrService } from "ngx-toastr";
import { BnNgIdleService } from "bn-ng-idle"; // import it to your component

declare var $: any;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    public routes: Router,
    private http: HttpClient,
    private router: Router,
    private apiCallingService: ApiCallingService,
    public globals: Globals,
    private bnIdle: BnNgIdleService,
    private toastr: ToastrService
  ) {
    translate.setDefaultLang("en");
    this.user = this.globals.getUser();
    this.activeplan = this.user?.subscription?.name;
    this.planePrice = this.user?.subscription?.price;
    this.planePrice = this.planePrice ? this.planePrice : 0;
    this.isAssetsRegistered = this.user.flags.is_assets_registered;
    this.renewon = this.user?.subscription?.end_date;
    this.commission = this.user?.commissionPercent;
    this.getRides();
    this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.toastr.error("Sorry,Timout due to inactivities");
        this.logout();
      }
    });
  }

  user: any = "";
  ride: any;
  activeplan: any;
  renewon: any;
  commission: any;
  planePrice: any;
  isAssetsRegistered: boolean = false;
  modeltitle: any;
  allcountry: any;
  ngAfterViewInit() { }
  getRides() {
    let user_id = this.user._id;
    let query = "?user_id=" + user_id + "&limit=10&page=10";
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_RIDE_HISTORY + query)
      .subscribe((res) => {
        this.ride = res.count;
      });
  }
  changeLanguage(lang: any) {
    this.translate.use(lang.code);
  }
  openModal(targetModal: NgbModal, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    if (title == "Cancel") {
      this.modeltitle = "Cancel plan";
    } else {
      this.modeltitle = "Remove account completely";
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  logout() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.LOGOUT_URL + "/" + this.globals.getUser()._id
      )
      .subscribe((res) => {
        localStorage.removeItem("Auth-user");
        localStorage.removeItem("operator_details");
        location.reload();
        this.router.navigate(["/login"]);
      });
  }

  removeAccount() {
    const body = new HttpParams()
      .set("email", this.globals.getUser().email)
      .set("in_blocked", "true");
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.REMOVE_ACCOUNT)
      .subscribe((res) => {
        this.toastr.success(res.msg);
        this.closeBtnClick();
        localStorage.removeItem("Auth-user");
        this.router.navigate(["/login"]);
      });
  }

  cancelPlan() {
    let param =
      "?user_id=" + this.user._id + "&package_id=" + this.user.subscription._id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.CANCEL_PLAN + param)
      .subscribe((res) => {
        this.toastr.success(res.msg);
        this.globals.setUser(res.data);
        location.reload();
        this.closeBtnClick();
      });
  }
}
