import { Component, OnInit } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { Globals } from "../../common/global";
import { NgForm } from "@angular/forms";
@Component({
  selector: "app-lease-history",
  templateUrl: "./lease-history.component.html",
  styleUrls: ["./lease-history.component.css"],
})
export class LeaseHistoryComponent implements OnInit {
  constructor(
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    private modalService: NgbModal
  ) {}
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  ngOnInit(): void {
    this.getList();
    console.log(this.data);
  }

  getList() {
    let user_id = this.Globals.getUser()._id;
    let query =
      "?operator_id=" +
      user_id +
      "&page=" +
      (this.page - 1) +
      "&limit=" +
      this.pageSize;

    var body = {
      userId: user_id,
      page: this.page - 1,
      limit: this.pageSize,
    };
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_LEASE_HISTORY_LIST + query)
      .subscribe((res) => {
        this.data = res.data?.list;
        this.totalcount = res.data?.totalCount;
      });
  }

  loadPage(page: number) {
    this.page = page;
    this.getList();
  }
  openImageModal(imageModel: NgbModal, data: any) {
    this.detail = data?.lease_package_subscribe_id?.user_locker_info?.images;

    if (this.detail?.length > 0 && this.detail?.length != undefined) {
      this.modalService.open(imageModel, {
        centered: true,
        backdrop: "static",
      });
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
}
