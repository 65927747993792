import { Component, OnInit } from "@angular/core";
import { ApiCallingService } from "../../../services/api-calling.service";
import { ConfigApi } from "../../../config-api";
import { ActivatedRoute } from "@angular/router";
import { Globals } from "../../../common/global";
import * as moment from "moment";
import { environment } from "../../../../environments/environment";
import * as mapboxgl from "mapbox-gl";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-ride-details",
  templateUrl: "./ride-details.component.html",
  styleUrls: ["./ride-details.component.css"],
})
export class RideDetailsComponent implements OnInit {
  map: any = mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  lat = 37.75;
  lng = -122.41;
  params: any;
  dashboard: any;
  alldata: any;
  rideDetail: any;
  imageList: any = [];
  start_ride_time: String = "";
  ode_end_checklist_time: String = "";

  constructor(
    private apiCallingService: ApiCallingService,
    private route: ActivatedRoute,
    public globals: Globals,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    //
    //mapbox
    this.route.params.subscribe((params) => (this.params = params));
    this.getDetail(this.params.id);
    /*  if ("geolocation" in navigator) { 
      (mapboxgl as any).accessToken  = environment.mapbox.accessToken;
      navigator.geolocation.getCurrentPosition(position => { 
          this.lat=position.coords.latitude, 
          this.lng=position.coords.longitude; 
          this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style,
            zoom: 13,
            center: [this.lng, this.lat]
          });

          // Add map controls
          this.map.addControl(new mapboxgl.FullscreenControl());
          this.map.addControl(
            new mapboxgl.GeolocateControl({
              positionOptions: {
              enableHighAccuracy: true
              },
              // When active the map will receive updates to the device's location as it changes.
              trackUserLocation: true,
              // Draw an arrow next to the location dot to indicate which direction the device is heading.
              showUserHeading: true,
            }),
            'top-left'
          );
      }); 
    }   */
  }
  getDetail(id: any) {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.MY_ASSET_RIDE_HISTORY_DETAILS + "/" + id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.rideDetail = res.data;
          (mapboxgl as any).accessToken = environment.mapbox.accessToken;
          this.lat = this.rideDetail.start_latitude;
          this.lng = this.rideDetail.start_longitude;
          this.start_ride_time =
            this.rideDetail && this.rideDetail.start_ride_image_time
              ? moment(this.rideDetail.start_ride_image_time).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "";
          this.ode_end_checklist_time =
            this.rideDetail &&
            this.rideDetail.ode_end_checklist &&
            moment(this.rideDetail.ode_end_checklist.time).format(
              "YYYY-MM-DD HH:mm:ss"
            );

          this.map = new mapboxgl.Map({
            container: "map",
            style: this.style,
            zoom: 13,
            center: [this.lng, this.lat],
          });

          // Add map controls
          this.map.addControl(new mapboxgl.FullscreenControl());
          this.map.addControl(
            new mapboxgl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true,
              },
              // When active the map will receive updates to the device's location as it changes.
              trackUserLocation: true,
              // Draw an arrow next to the location dot to indicate which direction the device is heading.
              showUserHeading: true,
            }),
            "top-left"
          );
          if (this.rideDetail.endRideProofImages) {
            this.rideDetail.endRideProofImages.forEach((element: any) => {
              this.imageList.push({
                image: element,
                thumbImage: element,
              });
            });
          }
          const startpointtext = new mapboxgl.Popup({ offset: 25 }).setText(
            "Start : " + this.rideDetail.start_address
          );
          const endpointtext = new mapboxgl.Popup({ offset: 25 }).setText(
            "End : " + this.rideDetail.end_address
          );

          //adding marker
          new mapboxgl.Marker({ color: "#36bea6" })
            .setLngLat([
              this.rideDetail.start_longitude,
              this.rideDetail.start_latitude,
            ])
            .setPopup(startpointtext)
            .addTo(this.map);
          if (this.rideDetail.end_longitude) {
            new mapboxgl.Marker({ color: "#f62d51" })
              .setLngLat([
                this.rideDetail.end_longitude,
                this.rideDetail.end_latitude,
              ])
              .setPopup(endpointtext)
              .addTo(this.map);
          }
          //focus on marker position
          this.map.flyTo({
            center: [
              this.rideDetail.start_longitude,
              this.rideDetail.start_latitude,
            ],
          });
          this.getDashboard(this.params.id);
        }
      });
  }
  getDashboard(id: any) {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_RIDE_HISTORY_DETAILS + "/" + id)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          if (res.data.length > 0) {
            let coordinates = res.data.map((coordinate: any, index: any) => {
              return [coordinate.longitude, coordinate.latitude];
            });
            this.drowLineOnMap(coordinates);
          }
        }
      });
  }

  drowLineOnMap(coordinates: any) {
    this.map.on("load", () => {
      this.map.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        },
      });
      this.map.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          //'line-color': '##FF0000',
          "line-width": 8,
        },
      });
    });
  }
}
