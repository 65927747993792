<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">My Profile</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="status">Country</label>
              <select class="form-control" [(ngModel)]="country">
                <option *ngFor="let c of allcountry" [value]="c.name">
                  {{ c.name }} - {{ c.currencies[0].code }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <form
          class="form"
          (ngSubmit)="onSubmitGenral(profileform)"
          #profileform="ngForm"
        >
          <div class="form-group row">
            <label for="image" class="col-sm-4 col-form-label"
              >Profile image</label
            >
            <div class="col-sm-8">
              <!-- <input type="text" class="form-control" pattern="(\s*[^\s]+\s*)+"   [(ngModel)]="detail.image" id="image" name="image" ngModel
                          required   #image="ngModel"> -->
              <input
                type="file"
                class="form-control-file"
                (change)="readURL($event)"
                accept="image/png, image/gif, image/jpeg"
                name="file"
                ngModel
                #file="ngModel"
              />
              <img id="blah" *ngIf="imageSrc" width="100" [src]="imageSrc" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="user.name"
                  id="name"
                  name
                  name="name"
                  required
                  #name="ngModel"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="!name.valid && (name.dirty || name.touched)"
                  >Please enter name!</small
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="username">Username</label>
                <input
                  type="text"
                  disabled
                  class="form-control"
                  [(ngModel)]="user.username"
                  id="username"
                  name="username"
                  username
                  required
                  #username="ngModel"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="
                    !username.valid && (username.dirty || username.touched)
                  "
                  >Please enter a lastname!</small
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="mobile">Mobile</label>
                <ngx-intl-tel-input
                  style="display: block; width: 100%"
                  class="country_code"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.National"
                  [(ngModel)]="user.mobile"
                  name="mobile"
                  required
                  (countryChange)="onCountryChange($event)"
                  #mobile="ngModel"
                >
                </ngx-intl-tel-input>
                <small
                  class="form-text text-danger"
                  *ngIf="!mobile.valid && (mobile.dirty || mobile.touched)"
                  >Please enter a valid mobile!</small
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="floatEmail">Email</label>
                <input
                  type="email"
                  class="form-control"
                  [(ngModel)]="user.email"
                  id="email"
                  name="email"
                  required
                  email
                  #email="ngModel"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="!mobile.valid && mobile.touched"
                  >Please enter a valid email!</small
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="status">Status</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="user.user_profile_status"
                  id="status"
                  name="status"
                  status
                  #status="ngModel"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="location">Location</label>
                <input
                  type="text"
                  class="form-control"
                  name="address"
                  pattern="(\s*[^\s]+\s*)+"
                  [(ngModel)]="user.address"
                  id="address"
                  name="address"
                  ngModel
                  #address="ngModel"
                  required
                  (click)="openMapBoxModel(searchModal)"
                />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <button
                type="submit"
                class="btn btn-secondary btn-raised mr-1"
                [disabled]="!profileform.valid"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #searchModal let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Search Your Location:
    <div class="map" id="map" class="match-parent"></div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-primary"
      (click)="saveLocation()"
      (click)="modal.dismiss('Cross click')"
      [disabled]="!mainObj"
    >
      Save
    </button>
  </div>
</ng-template>
<!-- <div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title mb-0 ">Change password</h4>
            </div> 
            <div class="card-body">
                <form class="form" (ngSubmit)="onSubmitChangePassword(changepasswordform)" #changepasswordform="ngForm">
                     
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="oldpassword">Old Password</label>
                                <input type="password" class="form-control" id="oldpassword" name="oldpassword" ngModel
                                    required   #oldpassword="ngModel"  >
                                <small class="form-text text-danger"
                                    *ngIf="!oldpassword.valid && (oldpassword.dirty || oldpassword.touched)">Please enter a
                                     old password!</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="newpassword">New Password</label>
                                <input type="password" class="form-control" id="newpassword" name="newpassword" ngModel
                                    required   #newpassword="ngModel"  >
                                <small class="form-text text-danger"
                                    *ngIf="!newpassword.valid && (newpassword.dirty || newpassword.touched)">Please enter a
                                      new password!</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="confirmpassword">Confirm Password</label>
                                <input type="password" class="form-control" id="confirmpassword" name="confirmpassword" ngModel
                                    required   #confirmpassword="ngModel"  >
                                <small class="form-text text-danger"
                                    *ngIf="!confirmpassword.valid && (confirmpassword.dirty || oldpassword.touched)">Please enter a
                                      confirm password!</small>
                            </div>
                        </div>
                    </div>
                     
                     
                    <div class="form-group row">
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-secondary btn-raised mr-1"
                                [disabled]="!changepasswordform.valid">Submit</button>
                         </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->
