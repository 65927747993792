import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ApiCallingService } from "../../../services/api-calling.service";
import { ConfigApi } from "../../../config-api";
import { ActivatedRoute } from "@angular/router";
import { Globals } from "../../../common/global";

import { environment } from "../../../../environments/environment";
import * as mapboxgl from "mapbox-gl";
@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.css"],
})
export class DetailsComponent implements OnInit {
  map: any = mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  lat = 37.75;
  lng = -122.41;
  params: any;
  dashboard: any;
  alldata: any;
  detailfromlist: any;
  imageList: any = [];
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    private route: ActivatedRoute,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    //
    //mapbox
    this.route.params.subscribe((params) => (this.params = params));

    this.getList();
    /*  if ("geolocation" in navigator) { 
      (mapboxgl as any).accessToken  = environment.mapbox.accessToken;
      navigator.geolocation.getCurrentPosition(position => { 
          this.lat=position.coords.latitude, 
          this.lng=position.coords.longitude; 
          this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style,
            zoom: 13,
            center: [this.lng, this.lat]
          });

          // Add map controls
          this.map.addControl(new mapboxgl.FullscreenControl());
          this.map.addControl(
            new mapboxgl.GeolocateControl({
              positionOptions: {
              enableHighAccuracy: true
              },
              // When active the map will receive updates to the device's location as it changes.
              trackUserLocation: true,
              // Draw an arrow next to the location dot to indicate which direction the device is heading.
              showUserHeading: true,
            }),
            'top-left'
          );
      }); 
    }   */
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    let query = "?user_id=" + user_id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_RIDE_HISTORY + query)
      .subscribe((res) => {
        this.alldata = res.data;
        let detail = this.alldata.find((c: any) => {
          return this.params.id == c._id;
        });
        this.detailfromlist = detail;

        if (this.detailfromlist.endRideProofImages) {
          this.detailfromlist.endRideProofImages.forEach((element: any) => {
            this.imageList.push({
              image: element,
              thumbImage: element,
            });
          });
        }
        console.log("DDD", this.detailfromlist);
        (mapboxgl as any).accessToken = environment.mapbox.accessToken;
        this.lat = this.detailfromlist.start_latitude;
        this.lng = this.detailfromlist.start_longitude;

        this.map = new mapboxgl.Map({
          container: "map",
          style: this.style,
          zoom: 13,
          center: [this.lng, this.lat],
        });

        // Add map controls
        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            // When active the map will receive updates to the device's location as it changes.
            trackUserLocation: true,
            // Draw an arrow next to the location dot to indicate which direction the device is heading.
            showUserHeading: true,
          }),
          "top-left"
        );
        const startpointtext = new mapboxgl.Popup({ offset: 25 }).setText(
          "Start : " + this.detailfromlist.start_address
        );
        const endpointtext = new mapboxgl.Popup({ offset: 25 }).setText(
          "End : " + this.detailfromlist.end_address
        );

        //adding marker
        new mapboxgl.Marker({ color: "red" })
          .setLngLat([
            this.detailfromlist.start_longitude,
            this.detailfromlist.start_latitude,
          ])
          .setPopup(startpointtext)
          .addTo(this.map);
        if (this.detailfromlist.end_longitude) {
          new mapboxgl.Marker({ color: "red" })
            .setLngLat([
              this.detailfromlist.end_longitude,
              this.detailfromlist.end_latitude,
            ])
            .setPopup(endpointtext)
            .addTo(this.map);
        }
        //focus on marker position
        this.map.flyTo({
          center: [
            this.detailfromlist.start_longitude,
            this.detailfromlist.start_latitude,
          ],
        });

        this.getDashboard(this.params.id);
      });
  }
  getDashboard(id: any) {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_RIDE_HISTORY_DETAILS + "/" + id)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          if (res.data.length > 0) {
            let coordinates = res.data.map((coordinate: any, index: any) => {
              return [coordinate.longitude, coordinate.latitude];
            });

            this.DrowLinOnMap(coordinates);
          }
        }
      });
  }

  DrowLinOnMap(coordinates: any) {
    this.map.on("load", () => {
      this.map.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        },
      });
      this.map.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          //'line-color': '##FF0000',
          "line-width": 8,
        },
      });
    });
  }
}
