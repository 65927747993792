import { Injectable, Output, EventEmitter } from "@angular/core";

@Injectable()
export class Globals {
  static getUser() {
    throw new Error("Method not implemented.");
  }
  @Output() fire: EventEmitter<any> = new EventEmitter();

  loader = false;

  user: any = "";
  operatorDetails: any = "";

  setUser(value: any) {
    this.user = value;
    localStorage.setItem("Auth-user", JSON.stringify(this.user));
    localStorage.setItem(
      "operator_details",
      JSON.stringify(this.user.business_detail)
    );
  }
  change() {
    // console.log('change started');
    this.fire.emit(true);
  }

  getEmittedValue() {
    return this.fire;
  }

  getUser() {
    this.user = localStorage.getItem("Auth-user");
    this.user = JSON.parse(this.user);
    return this.user;
  }

  getOperatorDetails() {
    this.operatorDetails = localStorage.getItem("operator_details");
    this.operatorDetails = JSON.parse(this.operatorDetails);
    console.log("this.operatorDetails ", this.operatorDetails);
    return this.operatorDetails;
  }
}
