 <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="card-group">
                    <div *ngFor="let d of data" class="card m-t-30 border-top border-left">
                        <div class="p-15 text-center">
                            <h4 class="card-title m-t-10">{{d.name}}</h4>
                            <h2 class="display-5 position-relative m-t-20 m-b-10">
                                <span class="price-sign">{{d.currency.symbol}}</span>{{d.price}}
                            </h2>
                            <p>Per {{d.timing}}</p>
                        </div>
                        <div class="border-top">
                            <ul class="list-style-none text-center">
                                <li class="border-bottom p-20"  >
                                    <p *ngFor="let f of d.features">
                                        <i class="mdi mdi-star-circle m-r-5"></i>{{f}}
                                    </p>
                                   
                                </li>
                                
                                <li class="border-bottom p-20">
                                    <button class="btn btn-primary waves-effect waves-light" *ngIf="subscribed==d.name">Subscribed</button>
                                    <button class="btn btn-success waves-effect waves-light" *ngIf="subscribed!=d.name"  (click)="openModal(editModal,d)" >Upgrade</button>

                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <!-- <div class="card bg-light border-top border-left">
                        <h5 class="price-label text-white text-center bg-info">Popular</h5>
                        <div class="p-15 text-center">
                            <h4 class="card-title m-t-40 text-center">Platinum</h4>
                            <h2 class="display-5 position-relative m-t-20 m-b-10">
                                <span class="price-sign">$</span>45
                            </h2>
                            <p>Per Month</p>
                        </div>
                        <div class="border-top">
                            <ul class="list-style-none text-center">
                                <li class="border-bottom p-20">
                                    <i class="ti-user m-r-5"></i>10 Members
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-mobile m-r-5"></i>Single Device
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-server m-r-5"></i>120GB Storage
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-reload m-r-5"></i>Monthly Backups
                                </li>
                                <li class="border-bottom p-20">
                                    <button class="btn btn-lg btn-info waves-effect waves-light">Sign up</button>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                      
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #editModal let-modal>
  
    <div class="modal-header">
  
      <h5 class="modal-title" id="editUserLabel">Confirmation</h5>
  
      <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
  
    </div>
  
    <div class="modal-body">
      <div>
        Are you sure want to subscribe the plan?
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">Cancel</button>
          <button type="submit" class="btn btn-danger" (click)="subscribe()">Yes,Continue</button>
        </div>
      </div>
    </div>
  </ng-template>
  