import { Component, OnInit,ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';

import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../../common/global';
@Component({
  selector: 'app-used-coupons',
  templateUrl: './used-coupons.component.html',
  styleUrls: ['./used-coupons.component.css']
})
export class UsedCouponsComponent implements OnInit {
 

  constructor( private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals
    ) { }

  ngOnInit(): void {
    this.getList();
  }
 
  page = 0;
  pageSize = 10;
  totalcount = 0;
  data=[];
  
  timings = ['Day','Week','Month','3 Month','6 Month','Yearly'];
  currency : any = localStorage.getItem('CUR');

  getList() {
    const body = new HttpParams()
    .set('operator_id',this.Globals.getUser()._id);
    // .set('page',this.page)
    // .set('limit',this.pageSize);
    let query = '?&limit=10&page='+(this.page==0 ? 0 : this.page-1);

    this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.COUPON.USED_LIST+query)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data =  res.data.data;
          this.totalcount = res.data.count;
        } 
      });
  }
  loadPage(page: number){
    this.page=page;
    this.getList(); 
  }
}

