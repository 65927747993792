<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Pay Later Payments
        </h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Username</th>
              <th scope="col">Package Type</th>
              <th scope="col">Pending Amount</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>
                {{ d.user_id?.email }}
              </td>
              <td>
                {{ d.user_id?.username }}
              </td>
              <td>
                {{ d.package_type ? d.package_type : "-" }}
              </td>

              <td>
                {{ d.user_id?.countryObject?.symbol }}
                {{ d.pending_payment_amount / 100 }}
              </td>
              <td>
                {{ d.start_date | date : "medium" }}
              </td>
              <td>
                {{ d.end_date | date : "medium" }}
              </td>
              <td>{{ d?.status }}</td>
              <td>
                <a href="javascript: void(0);" class="link mr-2" placement="top"
                  (click)="openModal(editModal, d, 'Payment Pending')" ngbTooltip="Edit">
                  <i class="fa fa-edit"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="9">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount" [maxSize]="7"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form *ngIf="modeltitle == 'Payment Pending'" (ngSubmit)="onSubmit(specialityform,detail)" #specialityform="ngForm">
      <div class="form-group row">
        <label for="pending_payment_amount" class="col-sm-2 col-form-label">Amount</label>
        <div class="col-sm-10">
          <div class="input-group-prepend">
            <span class="input-group-text">{{
              detail.package_id?.countryObject?.currency
              }}</span>
            <input type="number" class="form-control" name="pending_payment_amount" pattern="(\s*[^\s]+\s*)+"
              [(ngModel)]="amount" id="pending_payment_amount" name="pending_payment_amount" ngModel
              #pending_payment_amount="ngModel" required />
          </div>

          <span>Amount including {{ detail?.package_id?.vat }}%
            {{ detail?.operatorDetails?.tax_type }}.</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="booking_amount_paid" class="col-sm-2 col-form-label">&nbsp;</label>
        <div class="col-sm-10">
          <div class="custom-control custom-checkbox mr-sm-2 m-b-15">
            <!-- {{ detail.package_id?.countryObject?.currency }} -->

            <input type="checkbox" name="booking_amount_paid" [(ngModel)]="detail.booking_amount_paid"
              class="custom-control-input" id="checkbox0" value="check" ngModel #booking_amount_paid="ngModel" />
            <label class="custom-control-label" for="checkbox0">Received pending payment</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
          Close
        </button>

        <button type="submit" class="btn btn-secondary btn-raised mr-1" [disabled]="!specialityform.valid">
          Submit
        </button>
      </div>
    </form>
  </div>
</ng-template>