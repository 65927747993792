<div class="row">
     <div class="col-12">
          <div class="card card-body">
               <div class="">
                    <h4 class="card-title mb-4"><span class="lstick"></span>Business Analytics</h4>
               </div>
               <div>
                    <p><b>Total Unlock Count :</b>&nbsp;{{lockunlockdata}}</p>
               </div>
               <ngb-tabset [activeId]="activeTab" (tabChange)="getLockTypes($event.nextId)">
                    <ngb-tab id="Rent">
                         <ng-template ngbTabTitle>
                              <div>Rent</div>
                         </ng-template>
                         <ng-template ngbTabContent> </ng-template>
                    </ngb-tab>
                    <ngb-tab id="Sale">
                         <ng-template ngbTabTitle>
                              <div>Vending</div>
                         </ng-template>
                         <ng-template ngbTabContent> </ng-template>
                    </ngb-tab>
                    <ngb-tab id="Lease">
                         <ng-template ngbTabTitle>
                              <div>Book</div>
                         </ng-template>
                         <ng-template ngbTabContent> </ng-template>
                    </ngb-tab>
               </ngb-tabset>

               <div class="boxData mt-3">
                    <div *ngIf="show1" class="d-flex text-right">
                         <p class="box">Total Hour Spend : {{totalHour}}</p>
                         <p class="box">Total Earned : {{totalEarn}}</p>
                    </div>
                    <div *ngIf="show2" class="d-flex text-right">
                         <p class="box">Total Earned : {{totalEarn}}</p>
                    </div>
                    <div *ngIf="show3" class="d-flex text-right">
                         <p class="box">Total Earned : {{totalEarn}}</p>
                    </div>
               </div>
               <div class="">
                    <table class="table table-striped border mt-4">
                         <thead>
                              <tr>
                                   <th scope="col">Created At</th>
                                   <th scope="col">UserName</th>
                                   <th scope="col">Lock Name</th>
                                   <th scope="col">Brand Name</th>
                                   <th scope="col">Type</th>
                                   <th scope="col">Battery Status</th>
                                   <th scope="col">Location</th>
                                   <th *ngIf="showdata" scope="col">Total Hour</th>
                              </tr>
                         </thead>
                         <tbody>
                              <tr *ngFor="let d of data">
                                   <td>{{ d?.created_at | date : "medium" }}</td>
                                   <td>{{ d?.user_id.username }}</td>
                                   <td>{{ d?.lock_id.lock_name }}</td>
                                   <td>
                                        {{ d.lock_id.brand_name == 'OdeServices' ? "Shrvee": d.lock_id.brand_name ?
                                        d.lock_id.brand_name :
                                        d.isSharekayakLock
                                        ?"Sharekayak" : "Frekis" }}
                                   </td>
                                   <td>{{ d?.lock_id.lock_type }}</td>
                                   <td>{{ d?.lock_id.battery_percentage }} %</td>
                                   <td>
                                        {{
                                        d?.lock_id.lock_address.length > 40
                                        ? d?.lock_id.lock_address.substring(0, 30) + "..."
                                        : d?.lock_id.lock_address
                                        }}
                                   </td>
                                   <td *ngIf="showdata">{{d?.totalHour}}</td>
                              </tr>
                              <tr *ngIf="data.length == 0">
                                   <td colspan="9">No data found</td>
                              </tr>
                         </tbody>
                    </table>
                    <div class="d-flex justify-content-center mt-5">
                         <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount"
                              [maxSize]="7" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                              (pageChange)="loadPage($event)"></ngb-pagination>
                    </div>
               </div>
          </div>
     </div>
</div>