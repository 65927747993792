import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { Globals } from "../../common/global";

@Component({
  selector: "app-lease-package",
  templateUrl: "./lease-package.component.html",
  styleUrls: ["./lease-package.component.css"],
})
export class LeasePackageComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);

  operatorDetails = this.Globals.getOperatorDetails();
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  locktypes = [];
  isWaiverDisplay: boolean = true;
  timings = ["Hour", "Day", "Week", "Month", "3 Month", "6 Month", "Yearly"];
  currency: any = localStorage.getItem("CUR");
  maxValue: number = 24;
  request_licence: boolean = false;
  licenseTypelist: any[] = [];

  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.getList();
    this.getLockTypes();
    this.getLicenseType();
  }

  changeTiming(e: any) {
    if (e.target.value == "Minute") {
      this.maxValue = 60;
    } else if (e.target.value == "Hour") {
      this.maxValue = 24;
    } else if (e.target.value == "Day") {
      this.maxValue = 365;
    } else if (e.target.value == "Week") {
      this.maxValue = 52;
    } else if (e.target.value == "Month") {
      this.maxValue = 12;
    }
  }
  onLicenseDisplayChange(request_licence: boolean) {
    if (!request_licence) {
      this.detail.license_type = this.licenseTypelist[0];
    }
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);

    if (Object.keys(data).length == 0) {
      this.detail.isExclusive = false;
      this.detail.type = this.locktypes[0];
      this.detail.duration = 1;
      this.detail.isWaiverDisplay = false;
      this.detail.request_licence = false;
      this.detail.license_type = this.licenseTypelist[0];
      this.detail.vat = 6.0;
    } else {
      if (this.detail.timing == "Minute") {
        this.maxValue = 60;
      } else if (this.detail.timing == "Hour") {
        this.maxValue = 24;
      } else if (this.detail.timing == "Day") {
        this.maxValue = 365;
      } else if (this.detail.timing == "Week") {
        this.maxValue = 52;
      } else if (this.detail.timing == "Month") {
        this.maxValue = 12;
      }
    }
  }
  getLicenseType() {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.RENT_PACKAGE.GET_LICENSE_TYPE)
      .subscribe((res) => {
        this.licenseTypelist = res.data;
      });
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.LEASE_PACKAGE.LIST + "?user_id=" + user_id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = res.data.count;
        }
      });
  }
  getLockTypes() {
    let user_id = this.Globals.getUser()._id;
    let query = "?user_id=" + user_id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_LOCK_TYPE + query)
      .subscribe((res) => {
        this.locktypes = res.data;
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }

  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.LEASE_PACKAGE.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  onSubmit(f: any) {
    console.log("f : ", f.value);

    let body = new HttpParams()

      .set("user_id", this.Globals.getUser()._id)
      .set("timing", f.value.timing)
      .set("duration", f.value.duration)
      .set("price", f.value.price)
      .set("name", "Per " + f.value.timing)
      .set("pkg_desc", f.value.pkg_desc)
      .set("type", f.value.type)
      .set("vat", f.value.vat)
      .set("isWaiverDisplay", this.detail.isWaiverDisplay)
      .set("request_licence", this.detail.request_licence)
      .set("isExclusive", f.value.isExclusive);
    if (this.detail.request_licence) {
      body = body.set("license_type", f.value.license_type);
    }

    let url = ConfigApi.URLS.LEASE_PACKAGE.CREATE;
    let method = "POST";
    console.log("lease Body", body);
    if (this.modeltitle == "Edit Package") {
      url = ConfigApi.URLS.LEASE_PACKAGE.UPDATE + "/" + this.detail._id;
      method = "POST";
    }
    this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
}
