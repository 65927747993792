import { Component, OnInit } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { Globals } from "../../common/global";

@Component({
  selector: "app-external-api",
  templateUrl: "./external-api.component.html",
  styleUrls: ["./external-api.component.css"],
})
export class ExternalApiComponent implements OnInit {
  data: any = {};
  msg = "";
  btnText = "copy";
  constructor(
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    private clipboardApi: ClipboardService
  ) {}

  ngOnInit(): void {
    this.getRecord();
  }
  getRecord() {
    this.btnText = "copy";
    let query = "?user_id=" + this.Globals.getUser()._id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.SECRET.GET + query)
      .subscribe((res) => {
        if (res.data) {
          this.data = res.data;
        } else this.msg = "No secret key found, Please generate new secret key";
      });
  }

  addRecord() {
    this.btnText = "copy";
    let user_id = this.Globals.getUser()._id;
    var body = {
      user_id: user_id,
    };
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.SECRET.GENERATE)
      .subscribe((res) => {
        if (res.data) {
          this.data = res.data;
        } else this.msg = "No secret key found, Please generate new secret key";
      });
  }

  /*   copyInputMessage(inputElement: any) {
    console.log('inputElement',inputElement);
    
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
  } */

  copyText() {
    this.clipboardApi.copyFromContent(this.data.external_url);
    this.btnText = "copied";
  }
}
