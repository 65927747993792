<div class="row">
  <!-- Column -->

  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Feedback</h4>

        <form class="m-t-20" #f="ngForm" (ngSubmit)="updateTerms(f.value)">
          <div class="form-group">
            <quill-editor [style]="{height: '500px'}" required [(ngModel)]="content"  placeholder="Write your feedback here" name="content" #floatTerm="ngModel">
            </quill-editor>
            <small class="form-text text-danger"
              *ngIf="!floatTerm.valid && (floatTerm.dirty || floatTerm.touched)">Please enter a
              valid feedback!</small>
          </div>
          <button [disabled]="!f.valid" type="submit" class="btn btn-secondary">Send</button>
          <br><br>
          OR <a href="mailto:dev.atz.se">Click here to contact us</a>
        </form>
      </div>
    </div>
  </div>
  <!-- Column -->
</div>
