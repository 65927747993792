<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Notifications
        </h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Notifications for</th>
              <th scope="col">Message</th>
              <th scope="col">Received on</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.payload?.title }}</td>
              <td>{{ d?.payload?.message }}</td>
              <td>{{ d?.created_at | date : "medium" }}</td>
              <td>
                <a
                  *ngIf="
                    d?.payload?.type == 'ride_started' ||
                    d?.payload?.type == 'ride_ended' ||
                    d?.payload?.type == 'shared_asset' ||
                    d?.payload?.type == 'stash_asset'
                  "
                  (click)="openModal(editModal, d, 'Asset detail')"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="View Asset detail"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <!-- <a *ngIf="d?.payload?.type=='payment_pending'" class="link mr-2" placement="top"
                           ngbTooltip="View Asset detail">
                            Pay 
                           </a> -->
                <button
                  *ngIf="d?.payload?.type == 'payment_pending'"
                  type="button"
                  (click)="payPendingPayment(d)"
                  class="btn btn-rounded btn-xs btn-info"
                >
                  Pay
                </button>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="4">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="modeltitle == 'Asset detail'">
      <span>{{ detail.lock_id.lock_id }}</span>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Lock</label>
        <label class="col-sm-8 col-form-label">
          {{ detail.lock_id.lock_name }} ({{ detail.lock_id.lock_type }})
          {{ detail.lock_id.description }}
        </label>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <div class="map" id="map" class="match-parent"></div>
        </div>
      </div>
      <div class="form-group">
        <label for="typeahead-http">Search members:</label>
        <input
          type="text"
          [(ngModel)]="model1"
          (keyup)="search()"
          class="form-control"
          placeholder="Search members to add"
        />
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let u of searchresult; let i = index"
          >
            <img
              class="rounded-circle"
              height="30"
              width="30"
              src="assets/map/newLock/icon.png"
            />
            {{ u.name }} - @{{ u.username }}
            <button
              *ngIf="u.is_group_shared"
              class="btn btn-rounded btn-xs btn-danger float-right"
              (click)="removeMember(u._id, i)"
            >
              Remove
            </button>
            <button
              *ngIf="!u.is_group_shared"
              class="btn btn-rounded btn-xs btn-success float-right"
              (click)="addMember(u._id, i)"
            >
              Add
            </button>
          </li>
        </ul>
        <span
          ><b>Note</b>: Access are granted based on asset type,Another asset
          with same asset type wii share same users.</span
        >
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <b>Shared members</b>
          <ul class="list-group">
            <li
              class="list-group-item"
              *ngFor="let u of detail.lock_id.group; let i = index"
            >
              <img
                class="rounded-circle"
                height="30"
                width="30"
                [src]="u.user_image"
              />
              {{ u.name }}
              <button
                class="btn btn-rounded btn-xs btn-danger float-right"
                (click)="removeMember(u._id, i)"
              >
                Remove
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
