import * as $ from "jquery";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";

import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";

import { NavigationComponent } from "./shared/header-navigation/navigation.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";

import { Approutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpinnerComponent } from "./shared/spinner.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./auth.guard";
import { UiSwitchModule } from "ngx-toggle-switch";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";
import { Globals } from "./common/global";
import { GlobalHttpInterceptorService } from "./services/global-http-interceptor.service";
import { GlobalErrorHandlerService } from "./services/global-error-handler.service";
import { NgHttpLoaderModule } from "ng-http-loader"; //
import { BnNgIdleService } from "bn-ng-idle"; // import bn-ng-idle service

import { AdminModule } from "./admin/admin.module";
import { QrCodeModule } from "ng-qrcode";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
const config: SocketIoConfig = { url: "https://frekisprod.immerch.com" }; //production
// const config: SocketIoConfig = { url: "https://atzid.com" };
//old const config: SocketIoConfig = { url: 'https://atzid.com', options: {transports: ['websocket', 'polling', 'flashsocket']} };
import { NgxStripeModule } from "ngx-stripe";
import { CsvService } from "./services/csv.service";
import { NgImageSliderModule } from "ng-image-slider";
import { WebcamModule } from "ngx-webcam";
import { TimeSlotComponent } from "./admin/time-slot/time-slot.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { DemoUtilsModule } from "./demo-utils/demo-utils.module";
import { FlatpickrModule } from "angularx-flatpickr";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

import { ReactiveFormsModule } from "@angular/forms";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    LoginComponent,
    TimeSlotComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModalModule,
    NgHttpLoaderModule.forRoot(),
    AdminModule,
    NgbModule,
    RouterModule.forRoot(Approutes, { useHash: true }),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    QrCodeModule,
    FlatpickrModule.forRoot(),
    NgImageSliderModule,
    NgxStripeModule.forRoot("pk_test_qhlpDXqdhSuAVDStY3AfkjVI"),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    DemoUtilsModule,
    // ReactiveFormsModule,
    // NgxStripeModule.forRoot('sk_test_0Vgu6MBzGoJwLInqMEaeilNp'),
    UiSwitchModule,
    WebcamModule,
    NgbDatepickerModule,
    SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    //  { provide: HTTP_INTERCEPTORS,    useClass: GlobalHttpInterceptorService,    multi: true  },
    //   { provide: ErrorHandler, useClass:GlobalErrorHandlerService},
    {
      provide: Globals,
    },
    AuthGuard,
    BnNgIdleService,
    CsvService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
