<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <!-- Column -->
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box bg-info cursor-pointer text-center" [routerLink]="['/user/rent-package']">
                <h1 class="font-light text-white" style="display: grid">
                  {{ rentpackageCount }}
                </h1>
                <h6 class="text-white">Rental Packages</h6>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box bg-success cursor-pointer text-center" [routerLink]="['/user/sale-package']">
                <h1 class="font-light text-white" style="display: grid">
                  {{ allpackageCount }}
                </h1>
                <h6 class="text-white">Sale Packages</h6>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box bg-danger cursor-pointer text-center" [routerLink]="['/user/book_package']">
                <h1 class="font-light text-white" style="display: grid">
                  {{ leasepackageCount }}
                </h1>
                <h6 class="text-white">Book Packages</h6>
              </div>
            </div>
          </div>

          <!-- Column -->
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box bg-primary cursor-pointer text-center" [routerLink]="['/user/advance_booking']">
                <h1 class="font-light text-white" style="display: grid">
                  {{ advanceBooking }}
                </h1>
                <h6 class="text-white">Advance Bookings</h6>
              </div>
            </div>
          </div>

          <!-- Column -->
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box upcomingBookingCard cursor-pointer text-center" [routerLink]="['/user/users-booking']">
                <h1 class="font-light text-white" style="display: grid">
                  {{ upcomingBookingCount }}
                </h1>
                <h6 class="text-white">Users Bookings</h6>
              </div>
            </div>
          </div>

          <!-- Column -->
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box bg-warning cursor-pointer text-center" [routerLink]="['/user/my-asset-ride-history']">
                <h1 class="font-light text-white" style="display: grid">
                  {{ ridesCount }}
                </h1>
                <h6 class="text-white">Total Rides</h6>
              </div>
            </div>
          </div>

          <!-- Column -->
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box bg-secondary cursor-pointer text-center" [routerLink]="['/user/my-assets']">
                <h1 class="font-light text-white" style="display: grid">
                  {{ lockCount }}
                </h1>
                <h6 class="text-white">Total Locks</h6>
              </div>
            </div>
          </div>

          <!-- Column -->

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div class="card card-hover">
              <div class="box totalIncomeBox cursor-pointer text-center">
                <h1 class="font-light text-white" style="display: grid">
                  {{ totalincome }}
                </h1>
                <h6 class="text-white">Total Income ({{ curr }})</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div>
            <h4 class="card-title">Revenue</h4>
            <h5 class="card-subtitle">Overview of Latest records</h5>
          </div>
          <div class="ml-auto">
            <div class="dl">
              <select class="custom-select" [(ngModel)]="select1" (change)="changeSaleschart()">
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <apx-chart [series]="salesChartOptions.series" #chartObj [chart]="salesChartOptions.chart"
            [xaxis]="salesChartOptions.xaxis" [yaxis]="salesChartOptions.yaxis" [grid]="salesChartOptions.grid"
            [stroke]="salesChartOptions.stroke" [tooltip]="salesChartOptions.tooltip"
            [dataLabels]="salesChartOptions.dataLabels" [legend]="salesChartOptions.legend"
            [colors]="salesChartOptions.colors" [markers]="salesChartOptions.markers">
          </apx-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div>
            <h4 class="card-title">Rides</h4>
            <h5 class="card-subtitle">Overview of Latest records</h5>
          </div>
          <div class="ml-auto">
            <div class="dl">
              <select class="custom-select" [(ngModel)]="select2" (change)="changeRidechart()">
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <apx-chart [series]="rideChartOptions.series" [chart]="rideChartOptions.chart"
            [xaxis]="rideChartOptions.xaxis" [yaxis]="rideChartOptions.yaxis" [grid]="salesChartOptions.grid"
            [stroke]="rideChartOptions.stroke" [tooltip]="rideChartOptions.tooltip"
            [dataLabels]="rideChartOptions.dataLabels" [legend]="rideChartOptions.legend"
            [colors]="rideChartOptions.colors" [markers]="rideChartOptions.markers">
          </apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>