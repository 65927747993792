import { Component, OnInit } from '@angular/core';
import { Globals } from '../../common/global';
import {DomSanitizer} from "@angular/platform-browser";
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.css']
})
export class AddPaymentComponent implements OnInit {

  userinfo : any;
  constructor(public Globals: Globals,
    private apiCallingService: ApiCallingService,
    private domSanitizer : DomSanitizer) { }

  ngOnInit(): void {
    this.getProfile();
    this.userinfo = this.Globals.getUser().stripe_account_detail;
  }
  getProfile() { 
    let username = this.Globals.getUser().username;
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.MY_PROFILE.GET_DETAIL+'/'+username)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.Globals.setUser(res.data);
        } 
      });
  }
  url:any;
  openaddaccount(){
   let k = window.open(this.Globals.getUser().flags.stripe_connect_url,"_blank", "width=500,height=500");
   k?.focus();
   console.log(document.referrer);
   ///this.url =this.domSanitizer.bypassSecurityTrustResourceUrl( this.Globals.getUser().flags.stripe_connect_url);
  // let newTab = window.open(this.Globals.getUser().flags.stripe_connect_url);
  // newTab?.focus();
  // console.log(newTab?.location?.href);
  }

}
