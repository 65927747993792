import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Globals } from "src/app/common/global";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { environment } from "../../../../environments/environment";
import * as mapboxgl from "mapbox-gl";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

@Component({
  selector: "app-boundary",
  templateUrl: "./boundary.component.html",
  styleUrls: ["./boundary.component.css"],
})
export class BoundaryComponent implements OnInit {
  geometryData: any;
  name: string = "";
  isShapeCreated: boolean = false;
  map!: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  boundaryId: string = "";
  draw: any;
  boundaryDetail = {};
  lat = 37.75;
  lng = -122.41;
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    public globals: Globals,
    public http: HttpClient,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    public Globals: Globals
  ) {
    mapboxgl!.accessToken = environment.mapbox.accessToken;

    this.route.params.subscribe((params) => {
      this.boundaryId = params.id;
      if (this.boundaryId) {
        this.getDetails();
      }
    });
  }

  ngOnInit(): void {
    this.initMap();

    /*  

  getDetails() {
    let user_id = this.globals.user._id;
    let query = "?operator_id=" + user_id;
    // let query = "?operator_id=615aa5142886af45d9edcaba"
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.GEOFENCE.BOUNDARY_DETAIL + "/" + this.boundaryId
      )
      .subscribe((res) => {
        // this.data = res.data;
        // this.edit(res.data)
      });
  }
  edit() {
    this.map.on("load", () => {
      // Add a data source containing GeoJSON data.
      this.map.addSource("maine", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [-67.13734, 45.13745],
                [-66.96466, 44.8097],
                [-68.03252, 44.3252],
                [-69.06, 43.98],
                [-70.11617, 43.68405],
                [-70.64573, 43.09008],
                [-70.75102, 43.08003],
                [-70.79761, 43.21973],
                [-70.98176, 43.36789],
                [-70.94416, 43.46633],
                [-71.08482, 45.30524],
                [-70.66002, 45.46022],
                [-70.30495, 45.91479],
                [-70.00014, 46.69317],
                [-69.23708, 47.44777],
                [-68.90478, 47.18479],
                [-68.2343, 47.35462],
                [-67.79035, 47.06624],
                [-67.79141, 45.70258],
                [-67.13734, 45.13745],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "maine",
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });
    });
  }

  /* initMap(): void {
    const mapOptions: google.maps.MapOptions = {
      center: new google.maps.LatLng(17.412127, 78.474921),
      zoom: 15,
    };

    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    this.infoWindow = new google.maps.InfoWindow();
    const locationButton = document.createElement("button");
    locationButton.innerHTML =
      '<img style="width:38px;background-color:#fff" src="./assets/images/icon.png"/>';
    locationButton.classList.add("custom-map-control-button");
    locationButton.classList.add("btn");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      locationButton
    );
    //   if ("geolocation" in navigator) {
    //   console.log("Allow");
    // } else {
    // } 
    console.log("----", navigator.permissions.query({ name: "geolocation" }));
    if (!navigator.geolocation) {
      locationButton.disabled = true;
    } 
    locationButton.addEventListener("click", () => {
      if (navigator.geolocation) {
        this.navigateToCurrentLocation();
      } else {
        locationButton.disabled = true;
        this.handleLocationError(false, this.infoWindow, this.map.getCenter()!);
      }
    });
    this.setDrawingmanager();
  }


 */
  }

  initMap(): void {
    this.map = new mapboxgl.Map({
      container: "map",
      style: this.style,
      zoom: 13,

      center: [this.lng, this.lat],
    });
    const geocoder = new MapboxGeocoder({
      // Initialize the geocoder
      accessToken: mapboxgl.accessToken, // Set the access token
      //mapboxgl: mapboxgl, // Set the mapbox-gl instance
      marker: false, // Do not use the default marker style
      placeholder: "Search with address or name",
    });

    // Add the geocoder to the map
    this.map.addControl(geocoder);

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: false,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: "draw_polygon",
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(this.draw);
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
      })
    );

    this.map.on("draw.create", (e) => {
      this.geometryData = e.features[0].geometry;
      this.isShapeCreated = true;
    });
    this.map.on("draw.update", (e) => {
      this.geometryData = e.features[0].geometry;
      this.isShapeCreated = true;
    });

    this.map.on("draw.delete", (e) => {
      this.isShapeCreated = false;
    });
    this.map.on("draw.modechange", (e) => {
      const data = this.draw.getAll();
      if (data.features.length == 0) return;

      //if (this.draw.getMode() == "draw_polygon") {
      var pids: any[] = [];

      // ID of the added template empty feature
      const lid = data.features[data.features.length - 1].id;

      data.features.forEach((f: any) => {
        if (f.geometry.type === "Polygon" && f.id !== lid) {
          pids.push(f.id);
        }
      });
      this.draw.delete(pids);

      // console.log(`draw.modechange  ${this.draw.getMode()}`);
      // const data = this.draw.getAll();
      // //if (this.draw.getMode() == "draw_polygon") {
      // var pids: any[] = [];

      // // ID of the added template empty feature
      // const lid = data.features[data.features.length - 1].id;

      // data.features.forEach((f: any) => {
      //   if (f.geometry.type === "Polygon" && f.id !== lid) {
      //     pids.push(f.id);
      //   }
      // });
      // console.log(pids);

      // this.draw.delete(pids);
      //}
    });
    // this.map.on("draw.delete", this.updateArea);
    // this.map.on("draw.update", this.updateArea);

    this.map.on("load", () => {
      if (this.boundaryId) {
        this.draw.add({
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: this.geometryData.coordinates,
          },
        });
        var x = 0;
        var y = 0;
        this.geometryData.coordinates[0].forEach((element: any) => {
          x = x + element[0];
          y = y + element[1];
        });

        this.map.flyTo({
          center: [
            x / this.geometryData.coordinates[0].length,
            y / this.geometryData.coordinates[0].length,
          ],

          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      } else this.navigateToCurrentLocation();
    });
  }

  getDetails() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.GEOFENCE.BOUNDARY_DETAIL + "/" + this.boundaryId
      )
      .subscribe((res) => {
        this.boundaryDetail = res.data;
        this.geometryData = res.data.geometry;
        this.name = res.data.name;
        this.isShapeCreated = true;
      });
  }
  navigateToCurrentLocation(): void {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          zoom: 12,
        };
        console.log(position.coords.latitude);
        console.log(position.coords.longitude);

        // this.map.flyTo({
        //   center: [position.coords.latitude, position.coords.longitude],

        //   essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        // });
        // this.infoWindow?.open(this.map);
        this.map.setCenter(pos);
        // this.map.flyTo({
        //   center: [position.coords.latitude, position.coords.longitude],
        //   zoom: 12,

        //   essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        // });
        // this.map.jumpTo({ center: [position.coords.latitude, position.coords.longitude] });
      },
      (error: GeolocationPositionError) => {
        //this.handleLocationError(true, this.infoWindow, this.map.getCenter()!);
        alert(error.message);
      }
    );
  }
  create() {
    let url = ConfigApi.URLS.GEOFENCE.CREATE;
    let method = "POST";
    var x = 0;
    var y = 0;
    this.geometryData.coordinates[0].forEach((element: any) => {
      x = x + element[0];
      y = y + element[1];
    });
    var centerLatLong = {
      type: "Point",
      coordinates: [
        x / this.geometryData.coordinates[0].length,
        y / this.geometryData.coordinates[0].length,
      ],
    };
    var data = {
      operator_id: this.Globals.getUser()._id,
      name: this.name,
      centerLatLong: centerLatLong,
      geometry: this.geometryData,
      fenceType: "boundary",
    };
    if (this.boundaryId)
      url = ConfigApi.URLS.GEOFENCE.UPDATE + "/" + this.boundaryId;

    this.apiCallingService.callAPI(data, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.router.navigate(["../user/geofence"]);
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
}
