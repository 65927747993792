import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/user/home",
    title: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/geofence",
    title: "Geofence",
    icon: "fas fa-map-marker",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/dashboard",
    title: "Assets",
    icon: "fas fa-location-arrow",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/users",
    title: "On Boarding",
    icon: "fas fa-briefcase",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/user/my-assets",
        title: "My assets",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/operation",
        title: "Put into Operation",
        icon: "mdi mdi-star-circle",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/business-profile",
        title: "Update your profile",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },

      {
        path: "/user/add-payment",
        title: "Add Payment account",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/rent-package",
        title: "Rent Package",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/request_payment",
        title: "Request Payment",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/pay-later-payment",
        title: "Pay Later Payments",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/book_package",
        title: "Book Package",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/advance_booking",
        title: "Advance Booking",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/package_addon",
        title: "Package Addon",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/sale-package",
        title: "Sale Package",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      // {
      //   path: "/user/waiver",
      //   title: "Waiver",
      //   icon: "mdi mdi-adjust",
      //   class: "",
      //   extralink: false,
      //   submenu: [],
      // },
      {
        path: "/user/coupons",
        title: "Coupon Code (Rent)",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/book_coupons",
        title: "Coupon Code (Book)",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/sale-coupons",
        title: "Coupon Code (Sale)",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/advance_book_coupons",
        title: "Coupon Code (Advance Book)",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/used-coupons",
        title: "Used Coupon codes",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/user/track-assets",
    title: "Track Assets",
    icon: "mdi mdi-label",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/parking-violation",
    title: "Parking Violation",
    icon: "mdi mdi-label",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/purchase-history",
    title: "Purchase History",
    icon: "mdi mdi-alert-circle",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/waiver-forms",
    title: "Waiver Forms(Customer)",
    icon: "mdi mdi-format-align-justify",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/external-api",
    title: "External Apis",
    icon: "mdi mdi-format-align-justify",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/waiver-terms",
    title: " Waiver Terms",
    icon: "mdi mdi-car",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/user/waiver-terms/frekis",
        title: "Frekis",
        icon: "mdi mdi-arrange-bring-forward",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/waiver-terms/sharekayak",
        title: "Sharekayak",
        icon: "mdi mdi-arrange-bring-forward",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/waiver-terms/shrvee",
        title: "Shrvee Services",
        icon: "mdi mdi-arrange-bring-forward",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/user/license",
    title: "License",
    icon: "mdi mdi-file-lock",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "/user/my-ride-history",
    title: "History",
    icon: "mdi mdi-history",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/user/my-ride-history",
        title: "My Ride History",
        icon: "mdi mdi-arrange-bring-forward",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/my-asset-ride-history",
        title: "My Asset Ride History",
        icon: "mdi mdi-arrange-bring-forward",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/my-book-history",
        title: "My Book History",
        icon: "mdi mdi-arrange-bring-forward",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/lock-fallback",
        title: "Lock Fallback History",
        icon: "mdi mdi-lock",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/user/transactions",
    title: "Transactions",
    icon: "mdi mdi-wallet",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/pludda",
    title: "Pludda",
    icon: "mdi mdi-archive",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "/user/customer-review",
    title: "Customer Review",
    icon: "mdi mdi-star",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/notifications",
    title: "Notifications",
    icon: "mdi mdi-bell-ring",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/privacy-policy",
    title: "My profile",
    icon: "mdi mdi-file-lock",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/user/privacy-policy",
        title: "Privacy-policy",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/term-conditions",
        title: "Term & Conditions",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/user/payment-method",
    title: "Payment method",
    icon: "mdi mdi-adjust",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/user/help-faq",
    title: "Support",
    icon: "mdi mdi-headset",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/user/support/faq",
        title: "FAQ",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/support/operator-support",
        title: "Operator Support",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/support/feedback",
        title: "Feedback",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },

  // {
  //   path: '/custom-changes',
  //   title: 'Custom Changes',
  //   icon: 'mdi mdi-adjust',
  //   class: '',
  //   extralink: true,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'Component',
  //   icon: 'mdi mdi-bullseye',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/component/accordion',
  //       title: 'Accordion',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/alert',
  //       title: 'Alert',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/carousel',
  //       title: 'Carousel',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/dropdown',
  //       title: 'Dropdown',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/modal',
  //       title: 'Modal',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/pagination',
  //       title: 'Pagination',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/poptool',
  //       title: 'Popover & Tooltip',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/progressbar',
  //       title: 'Progressbar',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/rating',
  //       title: 'Ratings',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/tabs',
  //       title: 'Tabs',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/timepicker',
  //       title: 'Timepicker',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/buttons',
  //       title: 'Button',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/cards',
  //       title: 'Card',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/notifier',
  //       title: 'Notifier',
  //       icon: 'mdi mdi-adjust',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // }
];
