<div class="card">
  <div class="card-body">
    <form
      *ngIf="odeData"
      class="m-t-20"
      #f="ngForm"
      (ngSubmit)="updateTerms(f.value)"
    >
      <div class="form-group">
        <label for="price" class="col-form-label">Title </label>
        <div>
          <input
            type="text"
            class="form-control"
            placeholder="Enter title"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="odeData.title"
            id="title"
            name="titlename"
            ngModel
            #titlename="ngModel"
            required
          />
        </div>
      </div>
      <div class="form-group">
        <label for="price" class="col-form-label">Term and condition </label>
        <div>
          <quill-editor
            [style]="{ height: '500px' }"
            required
            [(ngModel)]="odeData.terms"
            placeholder="Write your Waiver Term and conditions"
            name="content"
            #floatTerm="ngModel"
          >
          </quill-editor>
        </div>
      </div>
      <div class="form-group">
        <button [disabled]="!f.valid" type="submit" class="btn btn-secondary">
          Save
        </button>
      </div>

      <br /><br />
    </form>
  </div>
</div>
