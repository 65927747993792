import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { NgForm } from '@angular/forms';
import { Globals } from '../../common/global';


@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.css']
})
export class BusinessProfileComponent implements OnInit {
  @ViewChild('profileform', { static: true }) floatingLabelForm: NgForm = Object.create(null);
  user: any;
  sessiondata: any;
  imageSrc: any;
  file: any;
  taxLists = ["VAT", "Sales Tax", "GST"];
  tax_type = "VAT";
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    public Globals: Globals) { }

  ngOnInit(): void {
    this.getProfile();
  }

  changeType(e: any) {
    this.tax_type = e.target.value;
    console.log("🚀 ~ ProfileComponent ~ changeType ~ this.tax_type:", this.tax_type)
  }

  getProfile() {
    let username = this.Globals.getUser().username;
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.MY_PROFILE.GET_DETAIL + '/' + username)
      .subscribe(res => {
        console.log("🚀 ~ BusinessProfileComponent ~ getProfile ~ res:", res)
        if (this.apiCallingService.handleSuccess(res)) {
          this.user = res.data.business_detail;
          console.log('U', this.user);
          this.imageSrc = this.user.operator_image;
        }
      });
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.file = file;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
      this.imageSrc = file;
    }
  }
  onSubmitGenral(f: any) {
    console.log(f.value);
    const uploadData = new FormData()
    if (this.file) {
      uploadData.append('operator_image', this.file, this.file.name);
    }
    uploadData.append('user_id', this.Globals.getUser()._id)
    uploadData.append('registration_number', f.value.registration_number)
    uploadData.append('organizationNumber', f.value.organizationNumber)
    uploadData.append('companyName', f.value.companyName)
    uploadData.append('phone_number', f.value.phone_number)
    uploadData.append('email', f.value.email)
    uploadData.append('VATId', f.value.VATId);
    uploadData.append('tax_type', f.value.tax_type);
    this.apiCallingService.callAPIwithFile(uploadData, 'POST', ConfigApi.URLS.UPDATE_OPERATOR)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
        }
      });
  }



}
