<div class="row">
    <!-- Column -->
  
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Privacy & Policy</h4>
          <h1>PRIVACY POLICY</h1>
          <p>Data protection and data security for customers and users have a high priority for iTransa AB. We therefore attach
            great importance to the protection of personal data in all business processes.&nbsp;</p>
          <p>This privacy statement explains what kind of information is collected at the iTransa AB during a visit to one of our
            Web sites or apps and how we use this information.&nbsp;</p>
          <p><strong>COLLECTION AND PROCESSING OF PERSONAL DATA</strong></p>
          <p>For the purpose of system security, when you visit our websites or apps, our Web server temporarily registers the
            domain name or the IP address of the requesting computer as well as the date of access, the file request of the
            client (file name and URL), the HTTP response code and the Web site from which you are visiting us, and the number
            of bytes transferred during the session. We might also, in some cases, store some information in the form of &
            cookies& on your PC so that we can optimize our Web site according to your preferences. We will not collect any
            other personal data such as name, address, telephone number or e-mail address unless you provide this information
            voluntarily, e.g., when placing an online order for a product or service, a survey, a competition, or a request for
            information. Personal data collected during a visit to our Web site is processed exclusively in accordance with the
            legal provisions of the country in which the respective company is headquartered.</p>
          <p><strong>USE AND FORWARDING OF PERSONAL DATA</strong></p>
          <p>We will use your personal data exclusively for the technical administration of the Web site or apps and to meet your
            wishes and requests. Only in cases in which you have given us prior consent do we also use this data for
            product-related surveys and marketing purposes, but only to the extent needed in each specific case. We will not
            disclose any data to government agencies except where required by law. Our employees and business offices are
            obligated to treat the data as confidential.</p>
          <p><strong>CUSTOMERS & DECISION</strong></p>
          <p>iTransa AB like to contact customers to provide them with information about special offers and news on products and
            services. If you register on our Web site or place an order for our products and services, you will be asked to
            indicate whether you would like to receive advertising information directly altough there will be NO advertisment in
            our apps. Irrespective of your consent, it goes without saying that, as a iTransa AB customer, you will continue to
            be notified of any important changes relating to existing service agreements (e.g., subscription).</p>
          <p><strong>FREEDOM OF INFORMATION</strong></p>
          <p>Upon written request, the respective iTransa AB will inform you of the personal data stored on you (e.g., name,
            address).</p>
          <p><strong>SECURITY</strong></p>
          <p>We have taken extensive technical and operational precautions to protect the personal data retained by us against
            unauthorized access and misuse. Our security procedures are revised regularly and adapted to reflect technological
            progress.</p>
        </div>
      </div>
    </div>
    <!-- Column -->
  </div>
  