<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Waiver forms (Customer)
        </h4>
      </div>
      <div>
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Username</th>
              <th scope="col">Phone number</th>
              <th scope="col">Email</th>
              <th scope="col">Date of birth</th>
              <th scope="col">Date of Sign</th>
              <th scope="col">Brand</th>
              <th scope="col">License Type</th>
              <th scope="col">License Verified</th>
              <th scope="col">License FrontImage</th>
              <th scope="col">License Issue Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td routerLink="/user/license" style="cursor: pointer">
                {{
                  d?.fullName
                    ? d?.fullName
                    : d?.initial + " . " + d?.firstName + " " + d?.middleName
                }}
              </td>
              <td>{{ d?.user_id?.username }}</td>
              <td>{{ d?.phone_number }}</td>
              <td>{{ d?.email }}</td>
              <td>{{ d?.date_of_birth }}</td>
              <td>{{ d?.created_at | date : "mediumDate" }}</td>
              <td>
                {{
                  d?.brand_name == undefined
                    ? ""
                    : d?.brand_name == "OdeServices"
                    ? "Shrvee"
                    : d?.brand_name
                }}
              </td>
              <td>{{ d?.license_id?.license_type || "-" }}</td>
              <td>{{ d?.license_id?.is_verified ? "Yes" : "No" }}</td>
              <td>
                <div *ngIf="d?.license_id; else noLicense">
                  <img
                    [src]="
                      d?.license_id?.front_image
                        ? d?.license_id?.front_image
                        : './assets/images/No-image-found.jpg'
                    "
                    width="40"
                  />
                </div>
                <ng-template #noLicense> - </ng-template>
              </td>
              <td>{{ d?.license_id?.created_at | date : "mediumDate" }}</td>

              <td>
                <a
                  (click)="openModal(editModal, d, 'Waiver form detail')"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="View detail"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <a
                  (click)="openModal(licenseModal, d, 'Update License')"
                  class="link mr-2"
                  placement="top"
                  ngbTooltip="Update license"
                  *ngIf="checkForEdit(d)"
                >
                  <i class="fa fa-edit"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="12">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #licenseModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      *ngIf="modeltitle === 'Update License'"
      (ngSubmit)="updateLicenseStatus(specialityform)"
      #specialityform="ngForm"
    >
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Front Image:</label>
        <div class="col-sm-8 col-form-label">
          <img
            [src]="
              detail.license_id?.front_image
                ? detail.license_id?.front_image
                : './assets/images/No-image-found.jpg'
            "
            alt=""
            style="width: 100%; height: 170px"
          />
        </div>
        <label class="col-sm-4 col-form-label">Back Image:</label>
        <div class="col-sm-8 col-form-label">
          <img
            [src]="
              detail.license_id?.back_image
                ? detail.license_id?.back_image
                : './assets/images/No-image-found.jpg'
            "
            alt=""
            style="width: 100%; height: 170px"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">User Video:</label>
        <div class="col-sm-8 col-form-label">
          <div *ngIf="detail.license_id?.user_video; else noVideo">
            <video width="100%" height="200" autoplay controls>
              <source [src]="detail.license_id?.user_video" type="video/mp4" />
            </video>
          </div>
          <ng-template #noVideo>
            <img
              src="./assets/images/no-video-available.jpg"
              alt=""
              width="100%"
            />
          </ng-template>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Is verified</label>
        <div class="col-sm-8 col-form-label">
          {{ detail?.license_id?.is_verified ? "Yes" : "No" }}
        </div>
        <label class="col-sm-4 col-form-label">License Type:</label>
        <div class="col-sm-8 col-form-label">
          {{ detail?.license_id?.license_type }}
        </div>

        <label class="col-sm-4 col-form-label">verification_status</label>
        <div class="col-sm-8 col-form-label">
          <select
            class="form-control"
            name="verification_status"
            [(ngModel)]="detail.license_id.verification_status"
            required
          >
            <option *ngFor="let s of status" [value]="s.value">
              {{ s.item }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="detail.license_id?.verification_status === 'rejected'"
      >
        <label for="rejectReason" class="col-sm-4 col-form-label"
          >Reject Reason</label
        >
        <div class="col-sm-8 col-form-label">
          <input
            type="text"
            class="form-control"
            name="reject_reason"
            [(ngModel)]="detail.license_id.reject_reason"
            required
          />
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>

        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Name:</label>
      <div class="col-sm-4 col-form-label">{{ detail?.user_id?.username }}</div>
      <label class="col-sm-2 col-form-label">Phone number:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.phone_number }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Email:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.email }}
      </div>
      <label class="col-sm-2 col-form-label">Date of birth:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.date_of_birth | date : "mediumDate" }}
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Emergency contact name:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.emergency_contact_name }}
      </div>
      <label class="col-sm-2 col-form-label">Emergency contact number:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.emergency_contact_number }}
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Insurance carrier:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.insurance_carrier }}
      </div>
      <label class="col-sm-2 col-form-label">Insurance policy number:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.insurance_policy_number }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Issuing state:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.issuing_state }}
      </div>
      <label class="col-sm-2 col-form-label">License Type:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.license_id?.license_type }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Date of Sign:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.created_at | date : "mediumDate" }}
      </div>
      <label class="col-sm-2 col-form-label">License Status:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.license_id?.verification_status }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">License Issue Date:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.license_id?.created_at | date : "mediumDate" }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-6 col-form-label">
        <label class="col-form-label">License Front Image:</label>
        <img
          [src]="
            detail.license_id?.front_image
              ? detail.license_id?.front_image
              : './assets/images/No-image-found.jpg'
          "
          alt=""
          style="width: 80%; height: 170px"
        />
      </div>
      <div class="col-sm-6 col-form-label">
        <label class="col-form-label">License Back Image:</label>
        <img
          [src]="
            detail.license_id?.back_image
              ? detail.license_id?.back_image
              : './assets/images/No-image-found.jpg'
          "
          alt=""
          style="width: 80%; height: 170px"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-6 col-form-label">
        <label class="col-form-label">User Video:</label>
        <div *ngIf="detail.license_id?.user_video; else noVideo">
          <video width="100%" height="200" autoplay controls>
            <source [src]="detail.license_id?.user_video" type="video/mp4" />
          </video>
        </div>
        <ng-template #noVideo>
          <img
            src="./assets/images/no-video-available.jpg"
            alt=""
            width="100%"
          />
        </ng-template>
      </div>
    </div>
    <div *ngIf="detail.minors.length > 0">
      <div class="form-group row">
        <h5 class="modal-title col-sm-12">Minor Information</h5>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <table class="table table-striped border col-sm-12">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <th scope="col">Middle Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">DOB</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let d of detail.minors">
                <td>{{ d?.minorFirstName ? d.minorFirstName : "-" }}</td>
                <td>{{ d?.minorMiddleName ? d.minorMiddleName : "-" }}</td>
                <td>{{ d?.minorLastName ? d.minorLastName : "-" }}</td>
                <td>
                  {{ d?.minor_date_of_birth ? d.minor_date_of_birth : "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Terms:</label>
      <div
        class="col-sm-10 col-form-label terms"
        [innerHtml]="detail?.terms"
      ></div>
    </div>
  </div>
</ng-template>
