<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Used coupons
        </h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Coupon Code</th>
              <th scope="col">Types</th>
              <th scope="col">Used by</th>
              <th scope="col">Coupon Type</th>
              <th scope="col">Offered for</th>
              <th scope="col">Used on time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.couponCode }}</td>
              <td>
                {{
                  d?.couponCodeId?.coupon_for == "lease_package"
                    ? "Book"
                    : d?.couponCodeId?.coupon_for == "sale_package"
                    ? "Sale"
                    : d?.couponCodeId?.coupon_for == "rent_package"
                    ? "Rent"
                    : d?.couponCodeId?.coupon_for == "advance_booking"
                    ? "Advance Booking"
                    : "-"
                }}
              </td>
              <td>{{ d?.user_id?.username }}</td>
              <td>{{ d?.couponType }}</td>
              <td>
                {{
                  d?.couponType == "time"
                    ? (d?.time ? d?.time : "") + " " + d?.type
                    : ""
                }}
                {{
                  d?.couponType != "time"
                    ? d?.discountPercent
                      ? d?.discountPercent + "%"
                      : ""
                    : ""
                }}
              </td>
              <td>{{ d?.updatedAt | date : "medium" }}</td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="6">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
