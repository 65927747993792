import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  constructor(private socket: Socket) {}
  getLogin(){
    return this.socket.fromEvent("qrCodeVerify").pipe(map( data => data )); 
  }
  getConnection(){
    return this.socket.fromEvent("connection_success").pipe(map( data => data )); 
  }
}
