import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { DatePipe } from '@angular/common';
import { Globals } from '../../common/global';

import * as moment from "moment";
@Component({
  selector: "app-request-payment",
  templateUrl: "./request-payment.component.html",
  styleUrls: ["./request-payment.component.css"],
})
export class RequestPaymentComponent implements OnInit {

  specialityform!: FormGroup;
  EmailData: any;
  modeltitle: any;
  searchresult: any;
  model1: any;
  listData: any;
  data: any;
  detail: any;
  detail1 = [];
  operatorDetails = this.Globals.getOperatorDetails();
  length: number = 0;
  urls: any[] = [];
  today: any = moment(new Date()).format("YYYY-MM-DD");

  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private fb: FormBuilder,
    public Globals: Globals
  ) { }

  ngOnInit(): void {
    this.search();
    this.getPaymentList();

    this.specialityform = this.fb.group({
      email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      estimated_delivery_date: ["", Validators.required],
      estimated_completion_time: ["", Validators.required],
      price: ["", Validators.required],
      vat: ["", Validators.required],
      isExclusive: ["", Validators.required],
      description: ["", Validators.required],
      request_payment_images: ["", Validators.required],
    });
  }

  // onSelectFile(e:any) {
  //   for (var i = 0; i < e.target.files.length; i++) { 
  //     this.urls.push(e.target.files[i]);

  //   }
  //   console.log(this.urls)

  //       // var filesAmount = event.target.files.length;
  //       // for (let i = 0; i < event.target.files.length; i++) {
  //       //         var reader = new FileReader();
  //       //         reader.onload = (event:any) => {
  //       //            this.urls.push(event.target.result); 
  //       //             console.log(this.urls);

  //       //         }
  //       //     reader.readAsDataURL(event.target.files[i]);
  //       // }
  //     //  console.log("this.urls : " , this.urls);


  // }
  deleteimage(img: any) {
    this.urls.splice(img, 1);
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.searchresult = [];
    this.model1 = "";
    this.modeltitle = title;

    this.detail = Object.assign({}, data);
    if (this.modeltitle == "Create Package") {
      this.detail.isExclusive = true;

    }

    this.detail.estimated_delivery_date = moment(
      this.detail.estimated_delivery_date ?? new Date()
    ).format("YYYY-MM-DD");

    this.detail.estimated_completion_time = moment(
      this.detail.estimated_completion_time ?? new Date()
    ).format("hh:mm");
    console.log(this.detail);
  }
  closeBtnClick() {
    this.modalService.dismissAll();
    this.urls = [];
  }
  search() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.REQUEST_PAYMENT.EMAIL_GET +
        "search_users?search=" +
        this.model1
      )
      .subscribe((res) => {
        if (this.model1 !== "") {
          this.searchresult = res.userslists;
        } else {
          this.searchresult = [];
        }
      });
  }

  openImageModal(imageModel: NgbModal, detail: any) {
    this.detail1 = detail?.urls;
    console.log(this.detail1);

    this.modalService.open(imageModel, {
      centered: true,
      backdrop: "static",
    });

  }

  onSubmit(f: any) {
    console.log(f.isExclusive);

    const operator_id = this.data?._id;

    const uploadData = new FormData();

    uploadData.append("estimated_delivery_date", f.estimated_delivery_date);
    uploadData.append("estimated_completion_time", f.estimated_completion_time);
    uploadData.append("price", f.price);
    uploadData.append("vat", f.vat);
    uploadData.append("isExclusive", f.isExclusive);
    uploadData.append("description", f.description);
    uploadData.append("request_payment_images", f.urls);


    if (this.modeltitle == "Edit Package") {
      let url =
        ConfigApi.URLS.REQUEST_PAYMENT.PUT_REQUEST_PAYMENT +
        "/" +
        this.detail._id;
      let method = "PUT";
      this.apiCallingService.callAPI(uploadData, method, url).subscribe((res) => {
        console.log(res);
        this.ngOnInit();
        this.closeBtnClick();
      });
    }
    else {
      uploadData.append("operator_id", this.Globals.getUser()._id);
      uploadData.append("email", f.email);
      let url = ConfigApi.URLS.REQUEST_PAYMENT.POST_REQUEST_PAYMENT;
      let method = "POST";
      this.apiCallingService.callAPI(uploadData, method, url).subscribe((res) => {
        console.log(res);
        this.ngOnInit();
        this.closeBtnClick();
      });
    }

  }
  getPaymentList() {
    const data: any = localStorage.getItem("Auth-user");
    const id = JSON.parse(data);
    const id1 = id?._id;
    console.log(id1);

    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.REQUEST_PAYMENT.GET_REQUEST_PAYMENT +
        "?operator_id=" +
        id1
      )
      .subscribe((res) => {
        this.listData = res?.data;
        console.log(this.listData);
      });
  }
  deleteData(id: any) {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.REQUEST_PAYMENT.DELETE_REQUEST_PAYMENT +
        "/" +
        this.detail._id
      )
      .subscribe((res) => {
        console.log(res);
        this.closeBtnClick();
        this.ngOnInit();
      });
  }
  OnClick(d: any) {
    this.data = d;
    this.searchresult = [];
    console.log(this.data);
  }
}
