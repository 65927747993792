import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute, Data } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { BreadcrumbServiceService } from "../../services/breadcrumb-service.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
})
export class BreadcrumbComponent implements OnInit {
  pageInfo: Data = Object.create(null);
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private breadcrumbService: BreadcrumbServiceService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === "primary"))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) => {
        this.titleService.setTitle(event["title"]);
        this.pageInfo = event;
        console.log("pageInfo", this.pageInfo);
      });

    this.breadcrumbService.getBreadcrumbs().subscribe((breadcrumbs) => {
      if (breadcrumbs.length) {
        console.log(this.pageInfo);

        this.pageInfo.urls = breadcrumbs;
      }
    });
  }
  ngOnInit() {}
}
