<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Boundary List
        </h4>
        <div class="d-flex mt-3">
          <a
            href="javascript: void(0);"
            class="btn btn-primary ml-auto"
            placement="top"
            [routerLink]="['/user/add-geofence-boundary']"
            ngbTooltip="Add Boundary"
          >
            Add Boundary
          </a>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <!-- <th scope="col">Type</th> -->
              <th scope="col">Total Parking</th>
              <th scope="col">Total Assets</th>
              <th scope="col">Created At</th>
              <th scope="col">Action</th>
              <th scope="col">Parking</th>
              <th scope="col">Asset</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.name }}</td>
              <!--  <td>{{d?.geometry?.type}}</td> -->
              <td>{{ d?.parking_areas.length }}</td>
              <td>{{ d.lock_ids ? d.lock_ids.length : "-" }}</td>
              <td>{{ d?.created_at | date : "medium" }}</td>
              <td>
                <a
                  [routerLink]="['/user/geofence', d._id]"
                  class="link mr-2"
                  ngbTooltip="Edit Boundary"
                  ><i class="fa fa-edit"></i
                ></a>

                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Delete Package')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
              <td>
                <a
                  [routerLink]="['/user/geofence-map', d._id]"
                  class="link mr-2"
                  ngbTooltip="Parkings"
                  ><i class="fa fa-plus"></i
                ></a>
              </td>
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openAssetsModal(assetsModal, d)"
                  ngbTooltip="Assets"
                >
                  <i class="fa fa-eye"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="7">No data found</td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div> -->
      </div>
    </div>
  </div>
</div>
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">Delete Boundary</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Are you sure want to delete?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="deleteGeofence()">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #assetsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">Asset List</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-striped border mt-4">
      <thead>
        <tr>
          <th scope="col">
            <input
              type="checkbox"
              [(ngModel)]="isMasterSel"
              name="list_name"
              value="h1"
              (change)="checkUncheckAll()"
            />
            All
          </th>
          <th scope="col">Image</th>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Owner</th>
          <th scope="col">Location</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let d of assetList
              | slice
                : (assetPage - 1) * assetPageSize
                : (assetPage - 1) * assetPageSize + assetPageSize
          "
        >
          <td>
            <input
              type="checkbox"
              [(ngModel)]="d.isSelected"
              name="list_name"
              value="{{ d._id }}"
              (change)="isAllSelected()"
            />
          </td>
          <td><img [src]="d?.lock_image" width="40" /></td>
          <td>{{ d?.lock_name }}</td>
          <td>{{ d?.lock_type }}</td>
          <td>{{ d?.owner_id?.name }}</td>
          <td>{{ d?.lock_address }}</td>
        </tr>
        <tr *ngIf="assetList.length == 0">
          <td colspan="4">No data found</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center mt-5">
      <!-- <ngb-pagination
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="assetList.length"
        [maxSize]="7"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
      ></ngb-pagination> -->

      <ngb-pagination
        [(page)]="assetPage"
        [pageSize]="assetPageSize"
        [collectionSize]="assetList.length"
        [maxSize]="7"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
      ></ngb-pagination>
    </div>
  </div>
  <div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
        Close
      </button>
      <button type="submit" class="btn btn-primary" (click)="createAssets()">
        Save
      </button>
    </div>
  </div>
</ng-template>
