import { Component, OnInit } from "@angular/core";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../common/global";

@Component({
  selector: "app-license",
  templateUrl: "./license.component.html",
  styleUrls: ["./license.component.css"],
})
export class LicenseComponent implements OnInit {
  page = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  modeltitle: any;
  detail: any;
  link: string | undefined;
  status = [
    {
      item: "approved",
      value: "approved",
    },
    {
      item: "rejected",
      value: "rejected",
    },
    {
      item: "pending",
      value: "pending",
    },
  ];
  operator_details: any;
  getUser: any;
  constructor(
    private apiCallingService: ApiCallingService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.getData();
    const operatorDetailsString = localStorage.getItem("operator_details");
    const userDetail = localStorage.getItem("Auth-user");

    if (operatorDetailsString) {
      this.operator_details = JSON.parse(operatorDetailsString);
    }
    if (userDetail) {
      this.getUser = JSON.parse(userDetail);
    }
  }
  ShowEdit(verified: any, val: any) {
    if (verified) {
      return val?._id.toString() == this.getUser?._id.toString();
    } else {
      return true;
    }
  }

  getData() {
    let page = this.page == 0 ? 0 : this.page - 1;

    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.LICENSE.CLOUD_PANEL_LIST +
          "?page=" +
          page +
          "&limit=" +
          this.pageSize +
          "&operator_id=" +
          this.Globals.getUser()._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = res.total;
        }
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getData();
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;

    if (this.modeltitle == "Image" || this.modeltitle == "Video") {
      this.link = data;
    } else {
      this.detail = Object.assign({}, data);
    }
  }
  onSubmit(f: any) {
    let reason =
      f?.value?.reject_reason == "" || f?.value?.reject_reason == null
        ? ""
        : f?.value?.reject_reason;
    if (f?.value.verification_status != "rejected") {
      reason = "";
    }
    const body = new HttpParams()
      .set("verification_status", f.value.verification_status)
      .set("operator_id", this.operator_details?.user_id)
      .set("reject_reason", reason);

    let url = ConfigApi.URLS.LICENSE.UPDATE;
    let method = "POST";

    if (this.modeltitle == "Edit Package") {
      url = ConfigApi.URLS.LICENSE.UPDATE + "/" + this.detail._id;
      method = "POST";
    }
    this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }

  closeBtnClick() {
    this.modalService.dismissAll();
  }
}
