import { Component, OnInit } from '@angular/core';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';

@Component({
  selector: 'app-operator-support',
  templateUrl: './operator-support.component.html',
  styleUrls: ['./operator-support.component.css']
})
export class OperatorSupportComponent implements OnInit {

  constructor(private apiCallingService: ApiCallingService) { }

  ngOnInit(): void {
    this.getList();
  }
  
  data:any; 
  getList() {
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.SUPPORT.OPERATOR_SUPPORT)
      .subscribe(res => {
          this.data =  res?.data;
      });
  }
   
   

}

