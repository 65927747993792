<div class="row">
  <!-- Column -->

  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Frekis</h4>

        <form class="m-t-20" #f="ngForm" (ngSubmit)="updateTerms(f.value)">
          <div class="form-group">
            <label for="price" class="col-form-label">Title </label>
            <div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter title"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="frekisData.title"
                id="title"
                name="titlename"
                ngModel
                #titlename="ngModel"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="price" class="col-form-label"
              >Term and condition
            </label>
            <div>
              <quill-editor
                [style]="{ height: '500px' }"
                required
                [(ngModel)]="frekisData.terms"
                placeholder="Write your Waiver Term and conditions"
                name="content"
                #floatTerm="ngModel"
              >
              </quill-editor>
            </div>
          </div>
          <div class="form-group">
            <button
              [disabled]="!f.valid"
              type="submit"
              class="btn btn-secondary"
            >
              Save
            </button>
          </div>

          <br /><br />
        </form>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Sharekayak</h4>

        <form
          class="m-t-20"
          #sharekayakForm="ngForm"
          (ngSubmit)="updateTerms2(sharekayakForm.value)"
        >
          <div class="form-group">
            <label for="price" class="col-form-label">Title </label>
            <div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter title"
                pattern="(\s*[^\s]+\s*)+"
                [(ngModel)]="sharekayakData.title"
                id="title"
                name="titlename"
                ngModel
                #titlename="ngModel"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="price" class="col-form-label"
              >Term and condition
            </label>
            <div>
              <quill-editor
                [style]="{ height: '500px' }"
                required
                [(ngModel)]="sharekayakData.terms"
                placeholder="Write your Waiver Term and conditions"
                name="content"
                #floatTerm="ngModel"
              >
              </quill-editor>
            </div>
          </div>
          <div class="form-group">
            <button
              [disabled]="!sharekayakForm.valid"
              type="submit"
              class="btn btn-secondary"
            >
              Save
            </button>
          </div>

          <br /><br />
        </form>
      </div>
    </div>
  </div>
  <!-- Column -->
</div>
