import { Component, ChangeDetectionStrategy, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { CalendarEvent, MOMENT } from 'angular-calendar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../common/global";
import { FormBuilder, NgForm } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { NgbCalendar, NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-time-slot',
  templateUrl: './time-slot.component.html',
  styleUrls: ['./time-slot.component.css'],
})
export class TimeSlotComponent implements OnInit {
  model!: NgbDateStruct;
  date!: { year: number; month: number };

  @ViewChild("specialityform", { static: true }) specialityform: NgForm = Object.create(null);

  data: any = {};
  slotDateList: any[] = [];
  operator_id = this.Globals.getUser()._id;
  soliId: any;
  // isDisabled = (date: NgbDate, current: { month: number }) => date.day === 9;
  activeSlot = '';
  slotDetail: any = [];
  showEditField = false;
  detail: any;
  formId = '';
  modeltitle: any;
  currentTime!: string;
  isPackEdit = false;
  disableDate = false;
  end_Date: any;
  model1: any;
  isEditPack: boolean = true;
  constructor(
    private http: HttpClient,
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar
  ) {
    this.setCurrentTime();
    const routeParams = this.route.snapshot.paramMap;
    this.soliId = routeParams.get('id');
  }

  ngOnInit(): void {
    this.getList();

  }

  selectToday() {
    this.model = this.calendar.getToday();

  }

  getList() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.ADVANCE_BOOKING.LIST + "?operator_id=" + this.operator_id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          var list = res?.data?.filter((data: any, i: any) => data._id == this.soliId)
          for (let i of list) {
            //   this.data = i;
            //   this.slotDateList = i.time_slot;
            //   this.activeSlot = this.slotDateList[0]?.date;
            const list = i.time_slot.map((x: any) => {
              const newDatObj = {
                date: x.date,
                disable: moment(x.date, "YYYY-MM-DD").isBefore(moment()) == true && moment(x.date, "YYYY-MM-DD") !== moment('YYYY-MM-DD') ? true : false,
                slot_array: x.slot_array,
                _id: x._id,
              }
              return newDatObj
            });
            const s_date = moment().format('YYYY-MM-DD');
            const newdat = s_date.split('-').map(Number);
            this.model = {
              year: newdat[0],
              month: newdat[1],
              day: newdat[2]
            };
            this.model1 = {
              year: newdat[0],
              month: newdat[1],
              day: newdat[2]
            };
            const year = this.model1.year;
            const month = this.model1.month;
            const day = this.model1.day;
            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            this.slotDetailApi(formattedDate)

            const e_date = list[list.length - 1].date;
            const endDate = e_date.split('-').map(Number);
            this.end_Date = {
              year: endDate[0],
              month: endDate[1],
              day: endDate[2]
            };

          }
        }
      });
  }

  setCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    this.currentTime = `${hours}:${minutes}`;
  }

  onDateSelection(date: any) {
    const year = date.year;
    const month = date.month;
    const day = date.day;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    this.activeSlot = formattedDate;
    this.slotDetailApi('');

    //old code 
    // if (date.disable == true) {
    //   this.disableDate = true;
    // } else {
    //   this.disableDate = false;
    // }
  }

  //old code 
  // selectedSlot(val: any) {
  //   this.activeSlot = val.date;
  //   this.slotDetailApi();
  //   if (val.disable == true) {
  //     this.disableDate = true;
  //   } else {
  //     this.disableDate = false;
  //   }

  // }

  slotDetailApi(val: any) {
    let dateFilter = this.activeSlot ? this.activeSlot : val
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.TIME_SLOT_DETAIL.GET_SLOT_DETAIL_BY_ID + '?date=' + dateFilter + "&operator_id=" + this.operator_id + "&package_id=" + this.soliId
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.slotDetail = res.data;
          this.isPackEdit = res.data.is_checkin_out_editable;
        }
      },
        (error) => {
          this.slotDetail = [];
          this.isPackEdit = false

        });
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
    if (title == 'Edit Pack Detail') {
      this.detail.start_date = moment(
        this.detail.start_date ?? new Date()
      ).format("YYYY-MM-DD");
      this.detail.end_date = moment(
        this.detail.end_date ?? new Date()
      ).format("YYYY-MM-DD");
    }
  }

  closeModalBtnClick() {
    this.modalService.dismissAll();
  }

  showEdit(val: any) {
    this.showEditField = true;
    this.formId = val.slot_id;
    this.detail = Object.assign({});
    this.detail.check_in_time = val.slot.slice(0, 5);
    this.detail.check_out_time = val.slot.slice(6, 11);
    this.detail.date = val.date

  }

  closeBtnClick() {
    this.showEditField = false;
    this.formId = ''
  }



  onEditSubmit(f: any, data: any) {
    let updateUrl = ConfigApi.URLS.TIME_SLOT_DETAIL.UPDATE_DELETE_SLOT;
    let CreateUrl = ConfigApi.URLS.TIME_SLOT_DETAIL.CREATE_SLOT;
    let method = "POST";

    if (data !== undefined && data !== null) {
      const slot = f.value.check_in_time + '-' + f.value.check_out_time;
      const update_delete_body = new HttpParams()
        .set("slot_id", data.slot_id)
        .set("slot", slot)
        .set("date_id", data.date_id)

      this.apiCallingService.callAPI(update_delete_body, method, updateUrl).subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeBtnClick();
            this.slotDetailApi('');
            this.ngOnInit();
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
    } else {
      const create_body = new HttpParams()
        .set("package_id", this.soliId)
        .set("date", this.activeSlot)
        .set("slot_start_time", f.value.slot_start_time)
        .set("slot_end_time", f.value.slot_end_time)
      this.apiCallingService.callAPI(create_body, method, CreateUrl).subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeModalBtnClick();
            this.ngOnInit();
            this.slotDetailApi('');
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
    }

  }
  deleteData() {
    const body = new HttpParams()
      .set("date_id", this.detail.date_id)
      .set('is_deleted', true)
      .set("slot_id", this.detail.slot_id)

    let url = ConfigApi.URLS.TIME_SLOT_DETAIL.UPDATE_DELETE_SLOT;
    let method = "POST";

    this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {

          this.toastr.success(res.msg);
          this.closeModalBtnClick()
          this.closeBtnClick();
          this.ngOnInit();
          this.slotDetailApi('');
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }

  onEditPackSubmit(f: any) {
    let body = new HttpParams()
      .set("check_in_time", f.value.check_in_time)
      .set("check_out_time", f.value.check_out_time)
      .set("start_date", f.value.start_date)
      .set("end_date", f.value.end_date)

    let url = ConfigApi.URLS.ADVANCE_BOOKING.UPDATE + "/" + this.detail._id;
    let method = "PUT";
    this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeModalBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
}