import { Injectable } from "@angular/core";
import { getCurrencySymbol } from "@angular/common";

@Injectable()
export class CsvService {
  downloadFile(data: any, filename = "data") {
    let csvData = this.ConvertToCSV(data, [
      "Date",
      "UserName",
      "EmailID",
      "PackageType",
      "InvoiceNumber",
      "TransactionID",
      "ISExclusive",
      "PackageAmount",
      "totalPaid",
      "ApplicationFee",
      "StripeFees",
      "RefundedAmount",
      "AssetId",
      "AssetName",
      "CouponCodeType",
      "CouponCode",
      "RideTime",
      "BasePrice",
      "WithoutVat",
      "VatPercentage",
      "Vat",
      "NetReceived",
      "Currency",
    ]);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "Sno,";

    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      console.log("array of csv", array);
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        line += "," + array[i][head];
      }
      str += line + "\r\n";
    }

    return str;
  }
}
