<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Book History
        </h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">User Name</th>
              <th scope="col">Lock Name</th>
              <th scope="col">Invoice Number</th>
              <th scope="col">Locker Description</th>
              <th scope="col">Total Paid</th>
              <th scope="col">Stripe Fees</th>
              <th scope="col">Application fees</th>
              <th scope="col">Start Date Time</th>
              <th scope="col">End Date Time</th>
              <th scope="col">Locker Image</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.from_user_id?.username }}</td>
              <td>{{ d?.receipt?.lock_name ? d?.receipt?.lock_name : "-" }}</td>
              <td>
                {{
                d?.stripe_response?.invoice_number
                ? d?.stripe_response?.invoice_number
                : "-"
                }}
              </td>
              <td>
                {{
                d?.lease_package_subscribe_id?.user_locker_info?.description
                ? d?.lease_package_subscribe_id?.user_locker_info
                ?.description
                : "-"
                }}
              </td>

              <td>
                <div>
                  {{
                  d?.stripe_response?.balance_transaction_data?.amount
                  ? d?.stripe_response?.balance_transaction_data?.amount
                  : "-"
                  }}
                  {{
                  d?.stripe_response?.balance_transaction_data?.currency
                  | uppercase
                  }}
                </div>
              </td>
              <td>
                {{
                d?.stripe_response?.balance_transaction_data?.stripe_fee
                ? d?.stripe_response?.balance_transaction_data?.stripe_fee
                : "-"
                }}
                {{
                d?.stripe_response?.balance_transaction_data?.currency
                | uppercase
                }}
              </td>
              <td>
                {{
                d?.stripe_response?.balance_transaction_data &&
                d.stripe_response.balance_transaction_data?.application_fee
                ? d.stripe_response.balance_transaction_data
                ?.application_fee
                : 0
                }}
                {{
                d?.stripe_response?.balance_transaction_data?.currency
                | uppercase
                }}
              </td>
              <td>
                {{
                d?.lease_package_subscribe_id?.start_date | date : "medium"
                }}
              </td>
              <td>
                {{
                d?.lease_package_subscribe_id?.end_date
                ? (d?.lease_package_subscribe_id?.end_date
                | date : "medium")
                : "-"
                }}
              </td>

              <td>
                <a href="javascript: void(0);" class="link mr-2" placement="top" (click)="openImageModal(imageModel, d)"
                  ngbTooltip="Images" *ngIf="
                    d?.lease_package_subscribe_id?.user_locker_info?.images
                      ?.length > 0
                  ">
                  <i class="fa fa-eye"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="10">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount" [maxSize]="7"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #imageModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="lockerImageLabel">Locker Images</h5>
    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let d of detail">
      <div>
        <img [src]="d" width="100%" style="margin-top: 10px" />
      </div>
    </div>
  </div>
  <div></div>
</ng-template>