<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Ride History
        </h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Ride on</th>
              <th scope="col">Distance</th>
              <th scope="col">Started at</th>
              <th scope="col">Ended at</th>

              <th scope="col">Asset name</th>
              <th scope="col">Ride detail</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.endAt | date : "medium" }}</td>
              <td>{{ d?.distance }} KM</td>
              <td>{{ d?.start_address }}</td>
              <td>{{ d?.end_address }}</td>
              <td>{{ d?.lock_id?.lock_name }}</td>

              <td>
                <a [routerLink]="['/user/my-ride-history/detail', d._id]"
                  ><h3><i class="mdi mdi-map-marker-multiple"></i></h3
                ></a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="6">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
