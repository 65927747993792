<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Package Addon
        </h4>
        <div class="d-flex justify-content-end">
          <a
            href="javascript: void(0);"
            class="btn btn-primary m-2"
            (click)="openModal(uploadCSVModal, {}, 'Upload CSV')"
            placement="top"
          >
            Upload CSV
          </a>
          <a
            href="javascript: void(0);"
            class="btn btn-primary m-2"
            (click)="openModal(editModal, {}, 'Create Package Addon')"
            placement="top"
          >
            + Create
          </a>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Bar Code Id</th>
              <th scope="col">Available</th>
              <th scope="col">Price</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data">
              <td>{{ item.title }}</td>
              <td>{{ item.barcode_id }}</td>
              <td>{{ item.is_available ? "Yes" : "No" }}</td>
              <td>{{ price(item.price) }}</td>
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-1"
                  placement="top"
                  ngbTooltip="Edit"
                  (click)="openModal(editModal, item, 'Edit Package Addon')"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, item, 'Delete Package')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>

            <tr *ngIf="data.length == 0">
              <td colspan="6" class="text-center">No data found</td>
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex justify-content-center mt-5"
          *ngIf="data.length != 0"
        >
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form
      *ngIf="
        modeltitle == 'Create Package Addon' ||
        modeltitle == 'Edit Package Addon'
      "
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      <div class="form-group row">
        <label for="title" class="col-sm-4 col-form-label">Barcode</label>
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="barcode_id"
            [(ngModel)]="detail.barcode_id"
            id="barcode_id"
            ngModel
            #barcode_id="ngModel"
            pattern="^(?!\s*$).+"
            required
            (input)="
              detail.barcode_id = trimLeadingWhitespace($event.target.value)
            "
          />
          <div
            *ngIf="
              barcode_id.invalid && (barcode_id.dirty || barcode_id.touched)
            "
            class="text-danger"
          >
            <div *ngIf="barcode_id.errors?.required">Barcode is required.</div>
            <div *ngIf="barcode_id.errors?.pattern">
              Please enter valid Barcode
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="title" class="col-sm-4 col-form-label">Title</label>
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="title"
            [(ngModel)]="detail.title"
            id="title"
            pattern="^(?!\s*$).+"
            ngModel
            #title="ngModel"
            required
            (input)="detail.title = trimLeadingWhitespace($event.target.value)"
          />
          <div
            *ngIf="title.invalid && (title.dirty || title.touched)"
            class="text-danger"
          >
            <div *ngIf="title.errors?.required">Title is required.</div>
            <div *ngIf="title.errors?.pattern">Please enter valid Title</div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="title" class="col-sm-4 col-form-label"
          >Advance Booking</label
        >
        <div class="col-sm-8">
          <select
            class="form-control"
            ngModel
            #package_id="ngModel"
            name="package_id"
            [(ngModel)]="detail.package_id"
          >
            <ng-container *ngIf="bookingType.length > 0; else noBooking">
              <option *ngFor="let d of bookingType" [value]="d._id">
                {{ d.title }}
              </option>
            </ng-container>
            <ng-template #noBooking>
              <option value="" disabled selected>
                No booking packages available
              </option>
            </ng-template>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="price" class="col-sm-4 col-form-label">Price</label>
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            name="price"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.price"
            id="price"
            name="price"
            min="1"
            ngModel
            #price="ngModel"
            required
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label">is_available</label>
        <div class="col-sm-8">
          <ui-switch
            [(ngModel)]="detail.is_available"
            name="is_available"
            #is_available="ngModel"
          ></ui-switch>
        </div>
      </div>
      <div class="form-group row">
        <label for="image" class="col-sm-4 col-form-label"> Image</label>
        <div class="col-sm-8">
          <input
            type="file"
            class="form-control-file"
            (change)="readURL($event)"
            accept="image/png, image/gif, image/jpeg,image/jpg"
            name="image"
            ngModel
            #file="ngModel"
          />
          <img
            id="blah"
            *ngIf="imageSrc"
            width="100"
            [src]="imageSrc"
            class="previewImg"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>

        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>
    <form [FormGroup]="editUser" *ngIf="modeltitle == 'Delete Package'">
      Are you sure want to Delete this Package ?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="deleteData()">
          Delete
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #uploadCSVModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ modeltitle }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="onCsvSubmit()">
      <div class="form-group">
        <label for="csvFile">Choose CSV file</label>
        <input
          type="file"
          id="csvFile"
          (change)="onFileChange($event)"
          class="form-control"
          accept=".csv"
        />
      </div>
      <button type="submit" class="btn btn-primary">Upload</button>
    </form>
  </div>
</ng-template>
