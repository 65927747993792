<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Parking Violation
        </h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">End Address</th>
              <th scope="col">Created At</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <th><img [src]="d?.user_id.user_image" width="40" /></th>
              <td>{{ d?.user_id.name }}</td>
              <td>{{ d?.user_id.email }}</td>
              <td>{{ d?.user_id.mobile }}</td>
              <td>{{ d?.ride_id.end_address }}</td>
              <td>{{ d?.created_at | date : "medium" }}</td>

              <td>
                <a [routerLink]="['/user/parking-violation/detail', d._id]" class="link mr-2" placement="top"
                  ngbTooltip="View detail">
                  <i class="fa fa-eye"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="7">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount" [maxSize]="7"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>