<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-md-block">
    <a
      (click)="toggleSidebar.emit()"
      class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)"
    >
      <i class="mdi mdi-menu font-24"></i>
    </a>
  </li>
  <li class="nav-item d-none d-md-block">
    <div class="nav-link waves-light">
      <i class="mdi mdi-map-marker-radius"></i>
      {{ ride?.total_ride_distance }} Distance
    </div>
  </li>
  <li class="nav-item d-none d-md-block">
    <div class="nav-link waves-light">
      <i class="mdi mdi-clock"></i> {{ ride?.total_ride_hour }} Time
    </div>
  </li>
  <li class="nav-item d-none d-md-block">
    <div class="nav-link waves-light" href="javascript:void(0)">
      <i class="mdi mdi-map-marker"></i>{{ ride?.total_ride }} Ride
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- mega menu -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- End mega menu -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item dropdown mt-3"   placement="bottom-right">
        <select class="form-control col-md-5 float-right">
            <option *ngFor="let c of allcountry">{{c.name}} - {{c.currencies[0].code}}</option>
             
        </select>
    </li> -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a
      routerLink="/user/notifications"
      class="nav-link dropdown-toggle waves-effect waves-dark"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="mdi mdi-bell font-24"></i>
    </a>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        src="{{ user.user_image }}"
        alt="user"
        class="rounded-circle"
        width="31"
        height="31"
        onerror="this.src='./assets/images/No-image-found.jpg';"
      />
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
      <span class="with-arrow">
        <span class="bg-primary"></span>
      </span>
      <div
        class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10"
      >
        <div class="">
          <img
            src="{{ user.user_image }}"
            alt="user"
            class="rounded-circle"
            width="60"
            height="60"
          />
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0">{{ user.name }}</h4>
          <!-- <p class=" m-b-0">{{user.email}}</p> -->
        </div>
      </div>

      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/profile']"
      >
        My Profile</a
      >
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/payment-method']"
      >
        Payment method</a
      >
      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/subscription']"
      >
        Subscription
        <span class="btn btn-rounded btn-xs btn-success float-right">{{
          activeplan
        }}</span></a
      >
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        *ngIf="planePrice > 0 && isAssetsRegistered"
        [routerLink]="['/user/operation']"
      >
        On Boarding
      </a>
      <a class="dropdown-item" href="javascript:void(0)" *ngIf="planePrice > 0">
        {{ user.business_detail.active ? "Renew On" : "Expired On" }}
        {{ renewon | date : "medium" }}
        <b>{{
          user.business_detail.active
            ? user.subscription.currency.code +
              " " +
              user.subscription.price +
              "/" +
              user.subscription.timing
            : ""
        }}</b>
      </a>
      <a class="dropdown-item" href="javascript:void(0)" *ngIf="planePrice > 0">
        <div>
          Commission :-
          <b> {{ commission }}%</b>
        </div>
      </a>
      <a
        class="dropdown-item text-danger"
        href="javascript:void(0)"
        *ngIf="planePrice > 0 && user.business_detail.active"
        (click)="openModal(confirmModel, 'Cancel')"
      >
        Cancel plan
      </a>

      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item text-danger"
        href="javascript:void(0)"
        (click)="openModal(confirmModel, 'Remove')"
      >
        <b>Remove account</b></a
      >
      <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
        Logout</a
      >
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>

<ng-template #confirmModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>
    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="modeltitle == 'Remove account completely'">
    Your existing data will be removed and can not be recovered.
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">Close</button> -->
      <button type="submit" class="btn btn-danger" (click)="removeAccount()">
        Remove
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="modeltitle == 'Cancel plan'">
    Are you sure you want to cancel your subscription?. You won't be charge from
    next renewal cycle.
    <div class="modal-footer">
      <button type="submit" class="btn btn-danger" (click)="cancelPlan()">
        Yes
      </button>
    </div>
  </div>
</ng-template>
