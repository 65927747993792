import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";

import { ApiCallingService } from "src/app/services/api-calling.service";
import { HttpClient } from "@angular/common/http";
import { Globals } from "../../common/global";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../environments/environment";
import * as mapboxgl from "mapbox-gl";
import { ActivatedRoute, Router } from "@angular/router";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { IfStmt } from "@angular/compiler";

@Component({
  selector: "app-geofence-map",
  templateUrl: "./geofence-map.component.html",
  styleUrls: ["./geofence-map.component.css"],
})
export class GeofenceMapComponent implements OnInit {
  map!: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  lat = 37.75;
  lng = -122.41;
  space: any;
  boundaryId: string = "";
  boundaryDetail: any;
  geometryData: any;
  draw: any;
  name: string = "";
  isShapeCreated: boolean = false;
  deleteId = "";
  parkings: any[] = [];
  selectedIndex = 0;
  selectedParking: any = {};
  selectedParkingId = "";
  isCreateClicked = false;
  marker: any;
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    public globals: Globals,
    public http: HttpClient,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    mapboxgl!.accessToken = environment.mapbox.accessToken;

    this.route.params.subscribe((params) => {
      this.boundaryId = params.boundaryId;
      //this.getDetails();
    });
  }
  ngOnInit(): void {
    this.initMap();
  }

  createShape(): void {
    this.isCreateClicked = true;
    this.selectedParkingId = "";
    this.selectedParking = {};
    this.addLayers();
  }

  initMap(): void {
    this.map = new mapboxgl.Map({
      container: "map",
      style: this.style,
      zoom: 13,
      center: [this.lng, this.lat],
    });
    const geocoder = new MapboxGeocoder({
      // Initialize the geocoder
      accessToken: mapboxgl.accessToken, // Set the access token
      //mapboxgl: mapboxgl, // Set the mapbox-gl instance
      marker: false, // Do not use the default marker style
      placeholder: "Search with address or name",
    });
    // Add the geocoder to the map
    this.map.addControl(geocoder);

    this.draw = new MapboxDraw({
      displayControlsDefault: false,

      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: false,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: "draw_polygon",
    });
    this.map.addControl(this.draw);

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
      })
    );

    this.map.on("draw.create", (e) => {
      const data = this.draw.getAll();
      if (data.features.length > 1) {
        const lid = data.features[data.features.length - 1].id;
        this.draw.delete(lid);
      } else {
        this.selectedParking.geometry = e.features[0].geometry;
        this.isShapeCreated = true;
      }
    });
    this.map.on("draw.update", (e) => {
      this.selectedParking.geometry = e.features[0].geometry;
      this.isShapeCreated = true;
    });

    this.map.on("draw.delete", (e) => {
      this.isShapeCreated = false;
    });
    this.map.on("draw.modechange", (e) => { });

    this.map.on("load", () => {
      if (this.boundaryId) {
        // var location = this.boundaryDetail.geometry.coordinates[0][0];
        // this.map.flyTo({
        //   center: [location[0], location[1]],
        //   zoom: 12,
        //   essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        // });
        //this.addLayers();
        this.getDetails();
      } else this.navigateToCurrentLocation();
    });
  }

  getDetails() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.GEOFENCE.BOUNDARY_DETAIL + "/" + this.boundaryId
      )
      .subscribe((res) => {
        this.boundaryDetail = res.data;
        this.geometryData = res.data.geometry;
        this.parkings = res.data.parking_areas;
        if (this.parkings.length > 0) {
          this.selectedParkingId = this.parkings[0]._id;
          this.selectedParking = this.parkings[0];
          this.addLayers();
          this.isCreateClicked = false;
          this.getCenter(this.selectedParking.geometry.coordinates[0]);
        } else {
          this.createShape();
          this.getCenter(this.boundaryDetail.geometry.coordinates[0]);
        }
      });
  }

  allMarker: any = [];
  removeLayer() {
    this.allMarker.forEach((marker: any) => {
      marker.remove();
    });
  }

  addLayers() {
    this.parkings.forEach((parking: any) => {
      if (this.map.getSource(parking._id)) {
        this.map.removeLayer(parking._id);
        this.map.removeSource(parking._id);
      }
    });

    //ADD PARKING LAYER FIRST
    this.draw.deleteAll();
    if (!this.map.getSource(this.boundaryId)) {
      this.map.addSource(this.boundaryId, {
        type: "geojson",
        data: this.boundaryDetail.geometry,
      });
      this.map.addLayer({
        id: this.boundaryId,
        type: "line",
        source: this.boundaryId,
        layout: {},

        paint: {
          "line-color": "#DC143C",
          "line-width": 3,
        },
      });
    }

    this.parkings.forEach((parking: any) => {
      if (this.selectedParkingId == parking._id) {
        this.draw.add({
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: parking.geometry.coordinates,
          },
        });
      } else {
        const el = document.createElement("div");
        el.className = parking._id;
        el.style.backgroundImage = `url(/assets/map/ic_parking.png)`;
        el.style.width = `${30}px`;
        el.style.height = `${30}px`;

        el.style.backgroundSize = "100%";

        /*  this.marker = new mapboxgl.Marker(el)
          .setLngLat([
            parking.geometry.coordinates[0][0][0],
            parking.geometry.coordinates[0][0][1],
          ])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(parking.name)
          )
          .addTo(this.map); */
        var x = 0;
        var y = 0;
        parking.geometry.coordinates[0].forEach((element: any) => {
          x = x + element[0];
          y = y + element[1];
        });

        this.marker = new mapboxgl.Marker(el)
          .setLngLat([
            x / parking.geometry.coordinates[0].length,
            y / parking.geometry.coordinates[0].length,
          ])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(parking.name)
          )
          .addTo(this.map);
        this.allMarker.push(this.marker);

        this.map.addSource(parking._id, {
          type: "geojson",
          data: parking.geometry,
        });
        this.map.addLayer({
          id: parking._id,
          type: "fill",
          source: parking._id,
          layout: {},

          paint: {
            "fill-color": "#1565C0",
            "fill-opacity": 0.6,
          },

          // This is the important part of this example: the addLayer
          // method takes 2 arguments: the layer as an object, and a string
          // representing another layer's name. If the other layer
          // exists in the style already, the new layer will be positioned
          // right before that layer in the stack, making it possible to put
          // 'overlays' anywhere in the layer stack.
          // Insert the layer beneath the first symbol layer.
        });
      }
    });
  }

  getCentroid = function (arr: any) {
    return arr.reduce(
      function (x: any, y: any) {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
      },
      [0, 0]
    );
  };

  editGeofence(data: any): void {
    this.isCreateClicked = false;
    this.selectedParking = data;
    this.selectedParkingId = data._id;
    //remove marker here
    this.removeLayer();
    this.addLayers();
    this.getCenter(data.centerLatLong.coordinates);
    /*  this.getCenter(this.selectedParking.geometry.coordinates[0]); */
  }

  create() {
    let url = ConfigApi.URLS.GEOFENCE.CREATE;
    let method = "POST";
    var x = 0;
    var y = 0;
    this.selectedParking.geometry.coordinates[0].forEach((element: any) => {
      x = x + element[0];
      y = y + element[1];
    });
    var centerLatLong = {
      type: "Point",
      coordinates: [
        x / this.selectedParking.geometry.coordinates[0].length,
        y / this.selectedParking.geometry.coordinates[0].length,
      ],
    };

    var data = {
      operator_id: this.globals.getUser()._id,
      name: this.selectedParking.name,
      centerLatLong: centerLatLong,
      geometry: this.selectedParking.geometry,
      fenceType: "parking_area",
      parent_id: this.boundaryId,
    };
    console.log("body", data);

    if (this.selectedParking._id) {
      url = ConfigApi.URLS.GEOFENCE.UPDATE + "/" + this.selectedParking._id;
    }
    this.apiCallingService.callAPI(data, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.removeLayer();
          if (!this.selectedParking._id) {
            this.getDetails();
          } else this.addLayers();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }

  openModal(targetModal: NgbModal, data: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.deleteId = data._id;
  }

  deleteGeofence(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.GEOFENCE.DELETE + "/" + this.deleteId
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeModels();
          this.ngOnInit();
        }
      });
  }

  navigateToCurrentLocation(): void {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          zoom: 13,
        };

        this.map.flyTo({
          center: [position.coords.latitude, position.coords.longitude],
          zoom: 12,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      },
      (error: GeolocationPositionError) => {
        alert(error.message);
      }
    );
  }

  closeModels() {
    this.modalService.dismissAll();
  }

  getCenter(array: any) {
    /*  var x = 0;
    var y = 0;
    array.forEach((element: any) => {
      x = x + element[0];
      y = y + element[1];
    }); */
    this.map.flyTo({
      /*  center: [x / array.length, y / array.length], */
      center: [array[0], array[1]],
      zoom: 14,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  }
}
