import { Component, OnInit } from "@angular/core";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { Globals } from "../../common/global";
@Component({
  selector: "app-pludda",
  templateUrl: "./pludda.component.html",
  styleUrls: ["./pludda.component.css"],
})
export class PluddaComponent implements OnInit {
  modeltitle: any;
  pageNO = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  isPluddaEnabled: boolean = false;
  constructor(
    private apiCallingService: ApiCallingService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem("isPluddaEnabled"))
      this.isPluddaEnabled = localStorage.getItem("isPluddaEnabled") === "true";

    this.getList();
  }
  loadPage(page: number) {
    this.pageNO = page;
    this.getList();
  }
  getList() {
    let user_id = this.Globals.user._id;
    let PageData = this.pageNO == 0 ? 0 : this.pageNO - 1;
    var body = {
      page: this.pageNO == 0 ? 0 : this.pageNO - 1,
      limit: this.pageSize,
      operator_id: this.Globals.user._id,
    };

    let query = "?operator_id=" + user_id + "&limit=10&page=" + PageData;
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.PLUDDA.LIST)
      .subscribe((res) => {
        this.data = res.data[0].list;
        this.totalcount = this.data.length;
      });
  }
  changeStatus() {
    const uploadData = new FormData();
    uploadData.append("user_id", this.Globals.getUser()._id);
    uploadData.append("isPluddaEnabled", "" + !this.isPluddaEnabled);

    this.apiCallingService
      .callAPI(uploadData, "POST", ConfigApi.URLS.PLUDDA.CHANGE_STATUS)
      .subscribe((res) => {
        localStorage.setItem("isPluddaEnabled", "" + this.isPluddaEnabled);

        console.log(localStorage.getItem("isPluddaEnabled"));
      });
  }
}
