import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as mapboxgl from "mapbox-gl";
import { ToastrService } from "ngx-toastr";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit {
  map!: mapboxgl.Map;
  @Output() centerChanged = new EventEmitter<{ lat: number; lng: number }>();

  style = "mapbox://styles/mapbox/streets-v11";
  searchQuery: string = "";
  suggestions: any[] = [];
  selectedSuggestion: any = null;

  lat = 40;
  lng = -74.5;
  searchLong: any;
  searchLat: any;
  mainObj: any;
  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    setTimeout(() => {
      (mapboxgl as any).accessToken = environment.mapbox.accessToken;

      this.map = new mapboxgl.Map({
        container: "map",
        style: this.style,
        zoom: 13,
        center: [72.5713621, 23.022505],
      });
      const geocoder = new MapboxGeocoder({
        accessToken: environment.mapbox.accessToken,
        mapboxgl: mapboxgl,
      });
      this.map.addControl(geocoder);
      geocoder.on("result", (event) => {
        this.mainObj = event.result;
        // Extract the coordinates from the search result
        const latitude = event.result.center[1];
        const longitude = event.result.center[0];

        // Update the map's center to the selected coordinates
        this.map.setCenter([longitude, latitude]);
      });
    }, 1);
  }
  searchLocation() {
    if (this.searchQuery) {
      this.apiCallingService
        .getLocationSuggestions(this.searchQuery)
        .subscribe((data: any[]) => {
          this.suggestions = data;
        });
    } else {
      this.suggestions = [];
    }
  }
  selectSuggestion(suggestion: any) {
    this.selectedSuggestion = suggestion;
    this.searchQuery = suggestion.display_name; // Set the search query to the selected suggestion
    this.suggestions = [];
    this.searchLat = suggestion.lat;
    this.searchLong = suggestion.lon;
  }
  saveLocation() {
    console.log(this.mainObj);
  }
}
