import { Component, OnInit } from '@angular/core';
 
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { Globals } from '../../common/global';


@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.css']
})
export class PurchaseHistoryComponent implements OnInit {

  constructor(private apiCallingService: ApiCallingService,public Globals: Globals) { }

  ngOnInit(): void {
    this.getList();
  }
  modeltitle:any;
  page = 0;
  pageSize = 10;
  data=[];
  totalcount = 0;
  detail : any;
  type:string = 'Rent';
  status:string = 'Active';
  currency : any = localStorage.getItem('CUR');


  getList() {
    this.data = [];
    let user_id = this.Globals.getUser()._id;
    let query = '?user_id='+user_id+'&package_type='+this.type+'&type='+this.status;
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.GET_PURCHASE_HISTORY+query)
      .subscribe(res => {
            this.data =  res.data;
            this.totalcount =  res.total;
      });
  }
   
  loadPage(page: number,type:string=""){
    this.type=type ? type : this.type;
    this.page=page-1;
    this.getList(); 
  }

  changeStatus(status:string){
    this.status = status;
    this.getList(); 
  }
}
