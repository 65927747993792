<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title">Lock Fallback History</h4>
      </div>

      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Lock Name</th>
              <th scope="col">User Name</th>
              <th scope="col">Type</th>
              <th scope="col">Description</th>
              <th scope="col">Supplier Name</th>
              <th scope="col">Amount</th>
              <th scope="col">Invoice Number</th>
              <th scope="col">Battery Status</th>
              <th scope="col">Date</th>
              <th scope="col">Lock Image</th>
              <th scope="col">Receipt</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.lock_id?.lock_name }}</td>
              <td>{{ d?.user_id?.username }}</td>
              <td>
                {{
                d?.stripe_transaction_id?.transaction_type ==
                "lease_package_purchase"
                ? "Book"
                : d?.stripe_transaction_id?.transaction_type ==
                "sale_package_purchase"
                ? "Sale"
                : d?.stripe_transaction_id?.transaction_type ==
                "package_purchase"
                ? "Rent"
                : d?.stripe_transaction_id?.transaction_type ==
                "subscription_purchase"
                ? "Subscription"
                : ""
                }}
              </td>
              <td>{{ d?.description }}</td>

              <td>{{ d?.lock_id?.supplier_name }}</td>
              <td>
                {{
                d?.stripe_transaction_id?.stripe_intent_response?.amount / 100
                }}
                {{
                d?.stripe_transaction_id?.stripe_intent_response?.currency
                | uppercase
                }}
              </td>
              <td>
                {{
                d?.stripe_transaction_id?.stripe_intent_response
                ?.invoice_number
                }}
              </td>
              <td>{{ d?.battery_percentage }}%</td>
              <td>{{ d?.created_at | date : "medium" }}</td>
              <td style="text-align: center">
                <a href="javascript: void(0);" class="link mr-2" placement="top" (click)="openImageModal(imageModel, d)"
                  ngbTooltip="Images">
                  <i class="fa fa-eye"></i>
                </a>
              </td>
              <td style="text-align: center">
                <a class="cursor-pointer" (click)="loadReceipt(d?.stripe_transaction_id?._id)"><i
                    class="fas fa-print"></i></a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="11">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount" [maxSize]="7"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #imageModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="lockerImageLabel">Locker Images</h5>
    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let d of detail">
      <div>
        <img [src]="d" width="100%" style="margin-top: 10px" />
      </div>
    </div>
  </div>
  <div></div>
</ng-template>