<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0"><span class="lstick"></span>License</h4>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Username</th>
              <th scope="col">Front Image</th>
              <th scope="col">Back Image</th>
              <th scope="col">User Video</th>
              <th scope="col">Device Type</th>
              <th scope="col">Verified</th>
              <th scope="col">Approve-Reject by</th>
              <th scope="col">License Type</th>
              <th scope="col">Reject Reason</th>
              <th scope="col">Verification Status</th>
              <th scope="col">Issue Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.user_id?.username }}</td>
              <td>
                <img
                  [src]="
                    d.front_image
                      ? d?.front_image
                      : './assets/images/No-image-found.jpg'
                  "
                  width="40"
                  (click)="
                    openModal(
                      openImage,
                      d?.front_image
                        ? d?.front_image
                        : './assets/images/No-image-found.jpg',
                      'Image'
                    )
                  "
                  ngbTooltip="Click to view image"
                />
              </td>
              <td>
                <img
                  [src]="
                    d?.back_image
                      ? d?.back_image
                      : './assets/images/No-image-found.jpg'
                  "
                  width="40"
                  (click)="
                    openModal(
                      openImage,
                      d?.back_image
                        ? d?.back_image
                        : './assets/images/No-image-found.jpg',
                      'Image'
                    )
                  "
                  ngbTooltip="Click to view image"
                />
              </td>
              <td>
                <div *ngIf="d.user_video; else image_content">
                  <video
                    width="50"
                    height="50"
                    (click)="openModal(openVideo, d.user_video, 'Video')"
                    ngbTooltip="Click to view video"
                  >
                    <source [src]="d.user_video" type="video/mp4" />
                  </video>
                </div>
                <ng-template #image_content>
                  <img
                    src="./assets/images/no-video-available.jpg"
                    alt=""
                    width="40"
                    (click)="
                      openModal(
                        openImage,
                        './assets/images/no-video-available.jpg',
                        'Image'
                      )
                    "
                  />
                </ng-template>
              </td>
              <td>{{ d?.device_type }}</td>
              <td>{{ d?.is_verified ? "Yes" : "No" }}</td>
              <td>{{ d?.verified_by?.username }}</td>
              <td>{{ d?.license_type }}</td>
              <td>
                {{
                  d.verification_status == "rejected" ? d?.reject_reason : "-"
                }}
              </td>
              <td>
                {{
                  d?.verification_status == "approved"
                    ? "Approved"
                    : d?.verification_status == "rejected"
                    ? "Rejected"
                    : "Pending"
                }}
              </td>
              <td>{{ d?.created_at | date : "medium" }}</td>
              <td>
                <div>
                  <a
                    href="javascript: void(0);"
                    class="link mr-2"
                    placement="top"
                    (click)="openModal(editModal, d, 'Edit Package')"
                    ngbTooltip="Edit"
                    *ngIf="ShowEdit(d.is_verified, d?.verified_by)"
                  >
                    <i class="fa fa-edit"></i>
                  </a>
                </div>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="12">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form
      *ngIf="modeltitle == 'Create Package' || modeltitle == 'Edit Package'"
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      <div class="form-group row">
        <label for="fullName" class="col-sm-4 col-form-label"
          >Verification Status</label
        >
        <div class="col-sm-8">
          <select
            class="form-control"
            #verification_status="ngModel"
            name="verification_status"
            [(ngModel)]="detail.verification_status"
          >
            <option *ngFor="let d of status" [value]="d.value">
              {{ d.item }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="detail.verification_status === 'rejected'"
      >
        <label for="rejectReason" class="col-sm-4 col-form-label"
          >Reject Reason</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="reject_reason"
            [(ngModel)]="detail.reject_reason"
            id="reject_reason"
            required
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>

        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #openImage let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <img [src]="link" style="width: 100%" alt="" />
  </div>
</ng-template>

<ng-template #openVideo let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <video width="100%" height="250" controls autoplay>
      <source [src]="link" type="video/mp4" />
    </video>
  </div>
</ng-template>
