import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../common/global';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  data:any;
  subscribed:any;
  allcountry :any;
  country:any;
  ccode:any

  constructor(private apiCallingService: ApiCallingService,
    public routes: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    public Globals: Globals) { }

  ngOnInit(): void {
    this.subscribed = this.Globals.getUser().subscription?.stripeProduct?.name;
    console.log(this.subscribed);
  

    
    if ("geolocation" in navigator) { 
        
      navigator.geolocation.getCurrentPosition(position => { 
        let lat=position.coords.latitude;
        let lng=position.coords.longitude; 
        this.getCurrentCountry(lat,lng);
      });
        
    }
    
  }
  getCurrentCountry(lat:any,lng:any){
    let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'+
            + lng + ', ' + lat
            + '.json?access_token=' + environment.mapbox.accessToken;
            let geodata;
            this.apiCallingService.callAPI({}, 'GET', url)
            .subscribe(res => {
              geodata=res.features;
              let c =  geodata.find((cn:any) => {
                if(cn.id.includes('country'))
                {
                  return cn;
                }
              });
                this.country=  c.text; 
               //get location base country
                this.http.get("assets/countries.json").subscribe(data =>{
                  this.allcountry = data;
                  let detail = this.allcountry.find((c:any) => {
                    return this.country==c?.name
                  });
                  this.ccode=detail.alpha2Code;
                  this.getList();
                })
            });

            
  } 
  getList() {
    console.log('d',this.ccode);
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.SUBSCRIPTION+"?country="+this.ccode)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data =  res.data;
        } 
      });
  }
 
  detail : any;

  openModal(targetModal: NgbModal, data:any) {
    this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
    });
   
    this.detail = Object.assign({},data);
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  subscribe(){

    let detail = this.allcountry.find((c:any) => {
      return this.country==c?.name
     });
     
    const body = new HttpParams()
    //.set('country',this.Globals.getUser().subscription.currency.country_name)
    .set('country',this.country)
    .set('user_id',this.Globals.getUser()._id)
    .set('is_live',false)
    .set('descriptiom','Subscription Purchased -Frekis App')
    //.set('currency',this.Globals.getUser().subscription.currency.code)
    .set('currency',detail?.currencies[0]?.code)
    .set('chargebleAmount',this.detail.chargeableAmount)
    .set('package_id',this.detail._id);
   
   this.apiCallingService.callAPI(body, 'POST', ConfigApi.URLS.SUBSCRIPTION_PURCHASE)
     .subscribe(res => {
      if (this.apiCallingService.handleSuccess(res)) {
        this.toastr.success(res.msg);
        this.closeBtnClick();
        this.routes.navigate(['/user/operation']);
       } 
     });
  }

}
