<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <ngb-tabset>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <div (click)="loadPageForReview('reviews')">Reviews</div>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="">
              <table class="table table-striped border mt-4">
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Average Rating</th>
                    <th scope="col">Brand Name</th>
                    <th scope="col">Comments</th>
                    <th scope="col">View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let d of data">
                    <th><img [src]="d?.image" width="40" /></th>
                    <td>{{ d?.created_at | date : "medium" }}</td>
                    <td>{{ d?.user_id?.username }}</td>
                    <td>
                      {{
                        d?.avg_rating
                          ? d?.avg_rating
                          : d?.rating
                          ? d?.rating
                          : "-"
                      }}
                    </td>
                    <td>
                      {{
                        d?.brand_name == undefined
                          ? ""
                          : d?.brand_name == "OdeServices"
                          ? "Shrvee"
                          : d?.brand_name
                      }}
                    </td>
                    <td>{{ d?.avg_rating ? d?.comment : d?.description }}</td>

                    <td>
                      <a
                        *ngIf="d?.avg_rating"
                        (click)="
                          openModal(editModal, d, 'Customer Review form detail')
                        "
                        class="link mr-2"
                        placement="top"
                        ngbTooltip="View detail"
                      >
                        <i class="fa fa-eye"></i>
                      </a>
                    </td>
                  </tr>
                  <tr *ngIf="data.length == 0">
                    <td colspan="6">No data found</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center mt-5">
                <ngb-pagination
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [collectionSize]="totalcount"
                  [maxSize]="7"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true"
                  (pageChange)="loadPage($event)"
                ></ngb-pagination>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <div (click)="loadPageForReview('ratings')">Ratings</div>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="">
              <table class="table table-striped border mt-4">
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Average Rating</th>
                    <th scope="col">Brand Name</th>
                    <th scope="col">Comments</th>
                    <th scope="col">View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let d of data">
                    <th><img [src]="d?.image" width="40" /></th>
                    <td>{{ d?.created_at | date : "medium" }}</td>
                    <td>{{ d?.user_id?.username }}</td>
                    <td>
                      {{
                        d?.avg_rating
                          ? d?.avg_rating
                          : d?.rating
                          ? d?.rating
                          : "-"
                      }}
                    </td>
                    <td>
                      {{
                        d?.brand_name == undefined
                          ? ""
                          : d?.brand_name == "OdeServices"
                          ? "Shrvee"
                          : d?.brand_name
                      }}
                    </td>
                    <td>{{ d?.avg_rating ? d?.comment : d?.description }}</td>

                    <td>
                      <a
                        *ngIf="d?.avg_rating"
                        (click)="
                          openModal(editModal, d, 'Customer Review form detail')
                        "
                        class="link mr-2"
                        placement="top"
                        ngbTooltip="View detail"
                      >
                        <i class="fa fa-eye"></i>
                      </a>
                    </td>
                  </tr>
                  <tr *ngIf="data.length == 0">
                    <td colspan="6">No data found</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center mt-5">
                <ngb-pagination
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [collectionSize]="totalcount"
                  [maxSize]="7"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true"
                  (pageChange)="loadPage($event)"
                ></ngb-pagination>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Name:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.user_id?.name }}
      </div>
      <label class="col-sm-2 col-form-label">User Name:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.user_id?.username }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Email:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.user_id?.email }}
      </div>
      <label class="col-sm-2 col-form-label">Average Rating:</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.avg_rating ? detail?.avg_rating : "-" }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Comment</label>
      <div class="col-sm-4 col-form-label">
        {{ detail?.comment }}
      </div>
      <label class="col-sm-2 col-form-label">Brand Name :</label>
      <div class="col-sm-4 col-form-label">
        {{
          detail?.brand_name == undefined
            ? ""
            : detail?.brand_name == "OdeServices"
            ? "Shrvee"
            : detail?.brand_name
        }}
      </div>
    </div>
    <form (ngSubmit)="onSubmit(opreatorReplyform)" #opreatorReplyform="ngForm">
      <div class="form-group row">
        <label for="description" class="col-sm-4 col-form-label">
          Reply Customer Review
        </label>
        <div class="col-sm-8">
          <textarea
            class="form-control"
            [(ngModel)]="detail.operator_reply"
            id="operator_reply"
            name="operator_reply"
            ngModel
            #operator_reply="ngModel"
          ></textarea>
        </div>
        <label style="margin-top: 10px" class="col-sm-12">
          <span class="col-form-label">Unpublish Review ?</span>&nbsp;
          <input
            [ngModel]="status == 'publish' ? false : true"
            (ngModelChange)="status = $event ? 'unpublish' : 'publish'"
            type="checkbox"
            (change)="changeStatus()"
            [ngModelOptions]="{ standalone: true }"
          />
        </label>
      </div>
      <div style="float: right; margin-bottom: 5px">
        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!opreatorReplyform.valid"
        >
          Submit
        </button>
      </div>
    </form>
    <div *ngIf="detail.reviews.length > 0">
      <div class="form-group row">
        <h5 class="modal-title col-sm-12">Review List</h5>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <table class="table table-striped border col-sm-12">
            <thead>
              <tr>
                <th scope="col">Question</th>
                <th scope="col">Rating</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let d of detail.reviews">
                <td>{{ d?.question ? d.question : "-" }}</td>
                <td>{{ d?.rate ? d.rate : "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
