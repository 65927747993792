import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(public router: Router) { }
  ngOnInit() {
    setTimeout(() => { 
      if (this.router.url === '/') {
          this.router.navigate(['/login']);
      } 
    })
  }
}
