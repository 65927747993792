import { Component, OnInit } from '@angular/core';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { Globals } from '../../common/global';
import * as mapboxgl from 'mapbox-gl';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {Router,ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    public routes: Router,
    private apiCallingService: ApiCallingService,
    public Globals: Globals) { }

  ngOnInit(): void {
    this.getList();
  }
  modeltitle:any;
  page = 0;
  pageSize = 10;
  data=[];
  totalcount = 0;
  detail : any;
  searchresult:any;
  model1: any;
  map:any;
  getList() {
    let user_id = this.Globals.getUser()._id;
    let query = '/'+user_id+'?limit=10&page='+(this.page==0 ? 0 : this.page-1);
    
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.GET_NOTIFICATIONS+query)
      .subscribe(res => {
          this.data =  res.data;
          this.totalcount =  res.total;
      });
  }
   
  loadPage(page: number){
    this.page=page;
    this.getList(); 
  }
  openModal(targetModal: NgbModal, data:any,title:any) {
    this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
    });
    this.modeltitle  = title;
    this.model1 = "";
    this.searchresult = [];
    this.detail = Object.assign({},data);
    
    console.log(this.detail);
    if(title=="Asset detail"){
      setTimeout(() => { 
        (mapboxgl as any).accessToken  = environment.mapbox.accessToken;
        this.map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/mapbox/streets-v11',
          zoom: 13,
          center: [this.detail.lock_id.longitude, this.detail.lock_id.latitude]
        });
                let markerUrl = this.getCustomerMarker(this.detail);

                const el = document.createElement('div');
                el.className = 'marker';
                el.style.backgroundImage = `url(${markerUrl})`;
                el.style.width = `40px`;
                el.style.height = `40px`;
                el.style.backgroundSize = '100%';
                let htmlofPopup = '<b>Address : </b>'+this.detail.lock_id.lock_address;
                let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlofPopup);
                 let m = new mapboxgl.Marker(el)
                .setLngLat([this.detail.lock_id.longitude,this.detail.lock_id.latitude])
                .setPopup(popup) 
                .addTo(this.map);
      })
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  removeMember(userid:any,index:any){
    let users = {
      lock_id:this.detail.lock_id._id,
      users:[{user_id:userid}]
    };
   this.apiCallingService.callAPI(users, 'POST', ConfigApi.URLS.REMOVE_MEMBER_FROM_LOCK)
     .subscribe(res => {
      if (this.apiCallingService.handleSuccess(res)) {
        this.toastr.success('Removed memeber successfully');
        this.search();
        this.closeBtnClick();
        this.ngOnInit();
       } 
     });
  }
  addMember(userid:any,index:any){
    let users = {
      lock_id:this.detail.lock_id._id,
      users:[{user_id:userid}]
    };
   this.apiCallingService.callAPI(users, 'POST', ConfigApi.URLS.ADD_MEMEBR_FOR_LOCK)
     .subscribe(res => {
      if (this.apiCallingService.handleSuccess(res)) {
        this.toastr.success('Added memeber successfully');
        this.search();
        this.closeBtnClick();
        this.ngOnInit();
       } 
     });
  }
  search(){
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.SEARCH_MEMEBR_FOR_LOCK+"?search="+this.model1+"&lock_id="+this.detail.lock_id._id)
    .subscribe(res => {
     if (this.apiCallingService.handleSuccess(res)) {
       if(this.model1!==""){
        this.searchresult = res.userslists;
       }else{
        this.searchresult = [];
       }
      
      } 
    });
  }
   
  payPendingPayment(d:any){
    console.log(d);
    let pay = {
      capture_method:"manual",
      purchase_type: "extra_payment",
      amount:d.payload.chargeableAmount,
      payment_method_types:["card"],
      destination :d.payload.stripeAccountId,
      vat:6.0,
      description:d.payload.description,
      source : "card no",
      isPartner: d.payload.partnerDetails.isPartner,
      isExclusive: false,
      user_id : this.Globals.getUser()._id,
      commissionPercent : d.payload.partnerDetails.commissionPercent,
      currency:"INR",
      statement_descriptor_suffix:d.payload.statement_descriptor_suffix,
      customer:this.Globals.getUser().stripe_customer_code,
      extra:{
        packageId : d.payload.partnerDetails.packageId,
        notification_id:d._id,
        extraTime:d.payload.partnerDetails.extraTime
      }
    };
    localStorage.setItem('PENDING_PAYMENT',JSON.stringify(pay));
    this.routes.navigate(['/user/payment-method/pendingpay']);
    // this.apiCallingService.callAPI(pay, 'POST', ConfigApi.URLS.PAYMENT)
    // .subscribe(res => {
    //  if (this.apiCallingService.handleSuccess(res)) {
    //    if(this.model1!==""){
    //     this.searchresult = res.userslists;
    //    }else{
    //     this.searchresult = [];
    //    }
      
    //   } 
    // });
  }


  getCustomerMarker(detail:any){
        let imagepath,imageName;
        if(detail.isSharekayakLock) {
          if(detail.isSharekayakLock) {
              if(detail.in_use) {
                  imageName = "rideRed";
              }else{
                  imageName = "rideGreen";
              }
          }else{
              if(detail.in_use){
                  imageName = "rideHireRed";
              }else{
                  imageName = "rideHire";
              }
          }
      }else{
          if(detail.in_use) {
              imageName = "rideRed";
          }else{
              imageName = "rideGreen";
          }
      }
      imagepath = "assets/map/"+imageName+'/icon.png';
      return imagepath;
  }

}
