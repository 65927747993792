import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { Globals } from '../../common/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';


import { environment } from '../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('specialityform', {static:true}) specialityform: NgForm=Object.create(null);

  map:any= mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 37.75;
  lng = -122.41;
  type='shared';
  allmarkers : mapboxgl.Marker[]=[];

  dashboard:any;
  constructor(
    private modalService: NgbModal,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    public Globals: Globals
  ) { }

  ngOnInit(): void {
    //this.getDashboard();
    //mapbox
   
    if ("geolocation" in navigator) { 
      (mapboxgl as any).accessToken  = environment.mapbox.accessToken;
      navigator.geolocation.getCurrentPosition(position => { 
          this.lat=position.coords.latitude, 
          this.lng=position.coords.longitude; 
          this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style,
            zoom: 13,
            center: [this.lng, this.lat]
          });

          // Add map controls
          this.map.addControl(new mapboxgl.FullscreenControl());
          this.map.addControl(
            new mapboxgl.GeolocateControl({
              positionOptions: {
              enableHighAccuracy: true
              },
              // When active the map will receive updates to the device's location as it changes.
              trackUserLocation: true,
              // Draw an arrow next to the location dot to indicate which direction the device is heading.
              showUserHeading: true,
            }),
            'top-left'
          );
          this.getAssets();
      }); 
    }  
  }
  getAssets() { 
    //Removing existing markers 
    this.allmarkers.forEach(element => {
       element.remove();
    });
    
    let user_id = this.Globals.getUser()._id;
    let query;
    if(this.type=="owner"){
        query = "?user_id="+user_id+"&page=0&req_type="+this.type;
    }else{
        query = "?user_id="+user_id+"&country=IN&req_type="+this.type+"&distance=1000&latitude="+this.lat+"&longitude="+this.lng+"&page=0&limit=1000";
    }
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.ASSET_LOCK_LIST+query)
      .subscribe(res => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.dashboard = res.data;
          for (const d of this.dashboard) {
              if(!d.isSharekayakLock){
                let markerUrl = this.getCustomerMarker(d);

                const el = document.createElement('div');
                el.className = 'marker';
                el.style.backgroundImage = `url(${markerUrl})`;
                el.style.width = `40px`;
                el.style.height = `40px`;
                el.style.backgroundSize = '100%';

                //Remove old marker
              
                // Set popup content 
                let htmlofPopup =
                //'<img src="'+d.lock_image+'" width="50"><br>'+
                '<b>'+d.lock_name+' ('+d.lock_type+')</b><br>'+
                '<b>Owner : </b>'+d.owner_id.name+'<img src="assets/images/battery.png" width="20">'+d.battery_percentage+'% <br>'+
                '<b>Address : </b>'+d.lock_address;
                let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlofPopup);
                // Add markers to the map.
                let m = new mapboxgl.Marker(el)
                .setLngLat([d.longitude,d.latitude])
                .setPopup(popup) 
                .addTo(this.map);

                this.allmarkers.push(m);
                
              }
          }
        } 
      });
  }
  changeType(type:any){
    this.getAssets()
  }

  getCustomerMarker(detail:any){
        let imagepath,imageName;
        if(this.type=="shared") {
          if(detail.is_shared_with_me) {
              if(detail.inUse) {
                  imageName = "rideRed";
              }else{
                  imageName = "rideGreen";
              }
          }else{
              if(detail.inUse){
                  imageName = "rideHireRed";
              }else{
                  imageName = "rideHire";
              }
          }
      }else{
          if(detail.inUse) {
              imageName = "rideRed";
          }else{
              imageName = "rideGreen";
          }
      }
      imagepath = "assets/map/"+imageName+'/icon.png';
      return imagepath;
  }
  detail:any = {};
  modeltitle:any;
  imageSrc : any;
  file:any;
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        this.file = file;
        const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;
        reader.readAsDataURL(file);
        this.imageSrc=file;
    }
  }
  openModal(targetModal: NgbModal,title:any) {
    this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
    });
    this.modeltitle=title;
    this.detail.email = this.Globals.getUser().email;
    this.detail.description = '';
    console.log(this.lat);
    console.log(this.lng);
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  onSubmit(f:any) {
    
    const uploadData = new FormData()
    if(this.file){
      uploadData.append('accident_image', this.file,this.file.name);
    }
    uploadData.append('user_id',this.Globals.getUser()._id);
    uploadData.append('latitude',this.lat.toLocaleString());
    uploadData.append('longitude',this.lng.toLocaleString());
    uploadData.append('description',f.value.description);

   this.apiCallingService.callAPIwithFile(uploadData, 'POST', ConfigApi.URLS.CREATE_ACCIDENT_REPORT)
     .subscribe(res => {
      if (this.apiCallingService.handleSuccess(res)) {
        this.toastr.success(res.msg);
        this.closeBtnClick();
       } 
     });
  }

}
