<!-- <div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Users Booking
        </h4>
        <br />
        <div class="row content">
          <div class="col content-date">
            <div class="form-group row">
              <label for="startdate" class="col-sm-4 col-form-label">Start date</label>
              <input type="date" class="form-control" onkeydown="return false" name="startdate" [(ngModel)]="startDate"
                id="startdate" ngModel #startdate="ngModel" required />
            </div>
          </div>
          &nbsp;
          <div class="col content-date">
            <div class="form-group row">
              <label for="enddate" class="col-sm-4 col-form-label">
                End date</label>
              <input type="date" class="form-control" onkeydown="return false" min="{{ startDate }}" name="enddate"
                [(ngModel)]="endDate" id="enddate" ngModel #enddate="ngModel" required />
            </div>
          </div>
          &nbsp;
          <div class="col content-date">
            <div class="form-group row">
              <label for="statusFilter" class="col-sm-4 col-form-label">Status</label>
              <select class="form-control" #lock_type="ngModel" name="statusFilter" [(ngModel)]="statusFilter">
                <option value="all" selected>All</option>
                <option value="upcoming">Upcoming</option>
                <option value="active">Active</option>
                <option value="returned">Returned</option>
                <option value="completed">Completed</option>
                <option value="cancel_request">Cancel Request</option>
                <option value="cancelled">Cancelled</option>
                <option value="expired">Expired</option>
                <option value="refund_request">Refund Request</option>
                <option value="refunded">Refunded</option>
              </select>
            </div>
          </div>
          <div class="col-md-1 bttn">
            <a href="javascript: void(0);" class="btn btn-primary ml-auto" placement="top" (click)="getList()"
              ngbTooltip="Search between days">
              Search
            </a>
          </div>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4 table-responsive">
          <thead>
            <tr>
              <th scope="col">UserName</th>
              <th scope="col">Email</th>
              <th scope="col">Type</th>
              <th scope="col">Brand Name</th>
              <th scope="col">Booking Type</th>
              <th scope="col">Address</th>
              <th scope="col">Total Asset Allowed</th>
              <th scope="col">Duration</th>
              <th scope="col">Timing</th>
              <th scope="col">Vat(%)</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Book TimeSlot</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.user_id?.username }}</td>
              <td>
                {{ d?.user_id?.email }}
              </td>
              <td>
                {{ d?.package_id?.type }}
              </td>
              <td>
                {{ d.brand_name }}
              </td>
              <td>
                {{
                d?.package_id?.booking_type == "activities"
                ? "Activities"
                : d?.package_id?.booking_type == "self_serve"
                ? "Self Serve"
                : d?.package_id?.booking_type == "rentx"
                ? "Rentx"
                : "-"
                }}
              </td>
              <td>{{ d?.package_id?.address }}</td>
              <td>{{ d?.package_id?.total_asset_allow_in_pack }}</td>
              <td>{{ d?.package_id?.duration }}</td>
              <td>{{ d?.package_id?.timing }}</td>
              <td>{{ d?.package_id?.vat }}</td>
              <td>{{ d?.start_date | date : "medium" }}</td>
              <td>{{ d?.end_date | date : "medium" }}</td>
              <td>{{ d.booked_time_slot[0] }}</td>
              <td>
                {{
                d.status == "upcoming"
                ? "Upcoming"
                : d.status == "cancel_request"
                ? "Cancel Request"
                : d.status == "refund_request"
                ? "Request Refund"
                : d.status == "active"
                ? "Active"
                : d.status == "returned"
                ? "Returned"
                : d.status == "completed"
                ? "Completed"
                : d.status == "cancelled"
                ? "Cancelled"
                : d.status == "expired"
                ? "Expired"
                : d.status == "refunded"
                ? "Refunded"
                : "-"
                }}
              </td>
              <td *ngIf="d.status === 'upcoming'">
                <a href="javascript: void(0);" class="link mr-2" placement="top"
                  (click)="openModal(editModal, d, 'Cancel Package')" ngbTooltip="Cancel Package">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
              <td *ngIf="d.status === 'cancel_request'">
                <button class="btn btn-secondary btn-raised mr-1" (click)="openModal(editModal, d, 'Refund Amount')">
                  Refund
                </button>
              </td>
              <td *ngIf="d.status === 'refund_request'">
                <button class="btn btn-secondary btn-raised mr-1" (click)="openModal(editModal, d, 'Refund amount')">
                  Refund
                </button>
              </td>
              <td>
                -
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="15">No data found</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5">
          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalcount" [maxSize]="7"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
-->
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form #specialityform="ngForm" *ngIf="modeltitle == 'Cancel Package'">
      Are you sure want to cancel this Package ?
      <div class="card-body">
        <div class="form-group row">
          <label for="reason" class="col-sm-2 col-form-label">Reason</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" name="reason" pattern="(\s*[^\s]+\s*)+" [(ngModel)]="detail.reason"
              id="reason" ngModel #reason="ngModel" required />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
          Close
        </button>
        <button type="submit" class="btn btn-danger" [disabled]="!specialityform.valid" (click)="deleteData()">
          Confirm
        </button>
      </div>
    </form>
    <form #specialityform="ngForm"
      *ngIf="this.detail.chargeableAmount.status == 'cancel_request' && modeltitle == 'Refund amount'">
      Would you like to charge some cancellation fee?
      <div class="card-body">
        <div class="form-group row">
          <div class="col-sm-8">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" [(ngModel)]="detail.is_charge_cancellation_fee"
                name="is_charge_cancellation_fee" id="inlineRadio1" #is_charge_cancellation_fee="ngModel"
                [value]="true" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" [(ngModel)]="detail.is_charge_cancellation_fee"
                name="is_charge_cancellation_fee" id="inlineRadio2" [value]="false" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>

        <div *ngIf="detail.is_charge_cancellation_fee == true" class="form-group row">
          <label for="amount" class="col-sm-4 col-form-label">Cancellation fee ({{ currency }})</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" name="amount" pattern="(\s*[^\s]+\s*)+"
              [(ngModel)]="detail.amount" max="{{ maxValue }}" id="amount" ngModel #amount="ngModel" required />
            <small class="form-text text-danger" *ngIf="!amount.valid && (amount.dirty || amount.touched)">Please enter
              valid fee (Max: {{ maxValue }}{{ currency }})</small>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
          Cancel
        </button>
        <button type="submit" class="btn btn-danger" [disabled]="!specialityform.valid" (click)="refundData()">
          Submit
        </button>
      </div>
    </form>
    <form #specialityform="ngForm"
      *ngIf="this.detail.chargeableAmount.status == 'refund_request' && modeltitle == 'Refund amount'">
      Would you like to refund full amount?

      <div class="card-body">
        <div class="form-group row">
          <div class="col-sm-8">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" [(ngModel)]="detail.is_refund_full_amount"
                name="is_refund_full_amount" id="inlineRadio1" #is_refund_full_amount="ngModel" [value]="true" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" [(ngModel)]="detail.is_refund_full_amount"
                name="is_refund_full_amount" id="inlineRadio2" [value]="false" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>

        <div *ngIf="detail.is_refund_full_amount == false" class="form-group row">
          <label for="amountTotal" class="col-sm-4 col-form-label">Refund Amount ({{ currency }})</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" name="amountTotal" pattern="(\s*[^\s]+\s*)+"
              [(ngModel)]="detail.amountTotal" max="{{ maxValue }}" id="amountTotal" ngModel #amountTotal="ngModel"
              required />
            <small class="form-text text-danger" *ngIf="
                !amountTotal.valid && (amountTotal.dirty || amountTotal.touched)
              ">Please enter valid fee (Max: {{ maxValue }}{{ currency }})</small>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
          Cancel
        </button>
        <button type="submit" class="btn btn-danger" [disabled]="!specialityform.valid" (click)="totalRefundData()">
          Submit
        </button>
      </div>
    </form>
  </div>
</ng-template>
<div class="row text-center">
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Previous
      </div>
      <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
        Today
      </div>
      <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()" (click)="test($event)">
        Next
      </div>
    </div>
  </div>
  <!-- <div class="col-md-3" style="text-align: left !important;">
    <div class="row">
      <div class="col"><i style="color: rgb(175 255 188);
        " class="fa fa-circle" aria-hidden="true"></i> <b> Active</b> <br />
        <i style="color: #75b5f5;
       " class="fa fa-circle" aria-hidden="true"></i> <b> Upcoming</b> <br />
        <i style="color: #99f5e5;
        " class="fa fa-circle" aria-hidden="true"></i> <b> Refunded</b> <br />
        <i style="color: #fc6c6c ;
         " class="fa fa-circle" aria-hidden="true"></i> <b> Cancel Request</b> <br />
        <i style="color: #fae37c;
        " class="fa fa-circle" aria-hidden="true"></i> <b> Cancelled</b> <br />

      </div>
      <div class="col"><i style="color: #c29df7;
        " class="fa fa-circle" aria-hidden="true"></i> <b> Completed</b> <br />
        <i style="color: #e2dad6;
        " class="fa fa-circle" aria-hidden="true"></i> <b> Returned</b> <br />
        <i style="color: #ff982a;
         " class="fa fa-circle" aria-hidden="true"></i> <b> Refund Request</b> <br />
        <i style="color: #696969;
          " class="fa fa-circle" aria-hidden="true"></i> <b> Expired</b> <br />
      </div>
    </div>


  </div> -->

  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
        Month
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
        Week
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        Day
      </div>
    </div>


  </div>
</div>
<br />
<div [ngSwitch]="view" *ngIf="events.length > 0">
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
    [refresh]="refresh" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->
<!-- 
<br /><br /><br />

<h3>
  <button class="btn btn-primary float-right" (click)="addEvent()">
    Add new
  </button>
  <div class="clearfix"></div>
</h3>

<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Title</th>
        <th>Starts at</th>
        <th>Ends at</th>
        <th>Action</th>
      </tr>
    </thead>

    <tbody *ngFor="let event of data">
      <tr>
        <td>
          <input type="text" class="form-control" [(ngModel)]="event.description" (keyup)="refresh.next()" />
        </td>

        <td>
          <input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.start_date"
            (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true"
            dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
        </td>
        <td>
          <input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.end_date"
            (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true"
            dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
        </td>
        <td>
          <button class="btn btn-danger" (click)="deleteEvent(event)">
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
-->
<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Booking Details</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col" [innerHTML]="modalData.event.title"></div>
      <div class="col">
        <img class="previewImg img-fluid" src="{{modalData.event.img}}" style="border-radius: 5px;" width='100%' />
      </div>
    </div>


  </div>
  <div class="modal-footer">

    <button type="submit" *ngIf="modalData.event.status === 'upcoming'" class="btn btn-danger"
      (click)="openModal(editModal, d, 'Cancel Package')">
      Cancel Package
    </button>
    <button type="submit" *ngIf="modalData.event.status === 'cancel_request'" class="btn btn-danger"
      (click)="openModal(editModal, d, 'Refund amount')">
      Refund
    </button>
    <button type="submit" *ngIf="modalData.event.status === 'refund_request'" class="btn btn-danger"
      (click)="openModal(editModal, d, 'Refund amount')">
      Refund
    </button>
    <button type="button" class="btn btn-secondary btn-raised mr-1" (click)="close()">
      OK
    </button>
  </div>
</ng-template>