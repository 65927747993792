<div class="row">
    <div class="col-12">
        <div class="card card-body">
            <div class="map" id="map" class="match-parent"></div>
            <br />
            <div class="form-group row">
                <!-- <div class="col-sm-4 ">
                    <input type="text" name="address" class="form-control" id="address" ngModel #address="ngModel"
                        placeholder="Search location" [(ngModel)]="searchQuery" (input)="searchLocation()" />
                    <ul *ngIf="suggestions.length" class="suggestions-list">
                        <li *ngFor="let suggestion of suggestions | slice : 0 : 2"
                            (click)="selectSuggestion(suggestion)" class="suggestion-item">
                            {{ suggestion.display_name }}
                        </li>
                        <li *ngIf="suggestions.length > 2" class="suggestion-item">
                            and more
                        </li>
                    </ul>
                </div> -->
                <div class="col-sm-4 "><button class="btn btn-primary" (click)="saveLocation()"
                        [disabled]="!mainObj">Save</button></div>
            </div>


        </div>

    </div>
</div>