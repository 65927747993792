<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

              <div class="text-center mb-1">
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="changeType(type)" [(ngModel)]="type">
                  <label ngbButtonLabel class="btn-primary" >
                    <input ngbButton type="radio" value="shared"> Shared
                  </label>
                  <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" value="owner"> Owned
                  </label>
                </div>
               
                <a class="btn btn btn-secondary ml-auto float-right" [routerLink]="['/user/my-assets']"><i class="fa fa-lock"></i></a>  

              </div>
                
                  
                <div class="map" id="map" class="match-parent"></div>
                <button class="btn btn btn-danger ml-auto float-left mt-2" (click)="openModal(editModal,'report')">Report accident</button>  
            </div>
        </div>
    </div>
</div>


<ng-template #editModal let-modal>
  
  <div class="modal-header">

    <h5 class="modal-title" id="editUserLabel">Accident details</h5>

    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>

  <div class="modal-body">
   
    <form  *ngIf="modeltitle=='report'" (ngSubmit)="onSubmit(specialityform)" #specialityform="ngForm">

      <p>Report will be verified,also all reported accidents will be shared with users</p>
       
      <div class="form-group row">
        <label for="image" class="col-sm-4 col-form-label">Image</label>
        <div class="col-sm-8">
          <input type="file" class="form-control-file" (change)="readURL($event);" accept="image/png, image/gif, image/jpeg" name="file" ngModel #file="ngModel">
          <img id="blah" *ngIf="imageSrc"  width="100" [src]="imageSrc"/>
        </div>
      </div>
      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">Email</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" disabled  name="email" pattern="(\s*[^\s]+\s*)+"   [(ngModel)]="detail.email" id="email" name="email" ngModel
             #email="ngModel">
        </div>
      </div>
 
      <div class="form-group row">
        <label for="description" class="col-sm-4 col-form-label">Tell us about it</label>
        <div class="col-sm-8">
        <textarea class="form-control"  pattern="(\s*[^\s]+\s*)+"   [(ngModel)]="detail.description" id="description" name="description" ngModel #description="ngModel"  ></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">Close</button>

        <button type="submit" class="btn btn-secondary btn-raised mr-1"
        [disabled]="!specialityform.valid">Submit</button>
       </div>
    </form>
  </div>
</ng-template>
