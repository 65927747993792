import { Component, OnInit } from "@angular/core";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { Globals } from "../../common/global";

@Component({
  selector: "app-parking-violation",
  templateUrl: "./parking-violation.component.html",
  styleUrls: ["./parking-violation.component.css"],
})
export class ParkingViolationComponent implements OnInit {
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  constructor(
    private apiCallingService: ApiCallingService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    let user_id = this.Globals.getUser()._id;
    let query =
      "?user_id=" +
      user_id +
      "&limit=" +
      this.pageSize +
      "&page=" +
      (this.page - 1);

    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.PARKING_VIOLATION.LIST + query)
      .subscribe((res) => {
        this.data = res.data.list;
        this.totalcount = res.data.totalCount;
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }
}
