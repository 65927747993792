import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { Globals } from "../../common/global";

@Component({
  selector: "app-ode-term",
  templateUrl: "./ode-term.component.html",
  styleUrls: ["./ode-term.component.css"],
})
export class OdeTermComponent implements OnInit {
  odeData: any;
  hasDefaultOde = false;
  userId = "";
  constructor(
    private toastr: ToastrService,
    private apiCallingService: ApiCallingService,
    public Globals: Globals
  ) {
    this.userId = this.Globals.getUser()._id;
  }

  ngOnInit(): void {
    this.getTerms();
  }
  getTerms() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.WAIVER.GET_WAIVER +
          "?user_id=" +
          this.userId +
          "&brand=OdeServices"
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          var data = res.data;

          if (data.waiverTerm) {
            this.odeData = data.waiverTerm;
            this.hasDefaultOde = false;
          } else {
            this.odeData = data.defaultWaiverTerm;
            this.hasDefaultOde = true;
          }
        }
      });
  }

  updateTerms(data: any) {
    if (!this.hasDefaultOde) {
      const body = new HttpParams()
        .set("terms", data.content)
        .set("brand_name", this.odeData.brand_name)
        .set("title", data.titlename);
      this.apiCallingService
        .callAPI(
          body,
          "PUT",
          ConfigApi.URLS.WAIVER.UPDATE + "/" + this.odeData._id
        )
        .subscribe((res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success("Waiver updated successfully");
            this.getTerms();
          }
        });
    } else {
      const body = new HttpParams()
        .set("user_id", this.userId)
        .set("terms", data.content)
        .set("brand_name", this.odeData.brand_name)
        .set("title", data.titlename);
      this.apiCallingService
        .callAPI(body, "POST", ConfigApi.URLS.WAIVER.ADD)
        .subscribe((res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success("Waiver updated successfully");
            this.getTerms();
          }
        });
    }
  }
}
