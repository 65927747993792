import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  policy:any;
  
  constructor(     
     private http: HttpClient,
     private toastr: ToastrService,
     private apiCallingService: ApiCallingService
  ) { }

  ngOnInit(): void {
    this.getPolicy();
  }
  getPolicy() { 
    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.MY_PROFILE.PRIVACY)
      .subscribe(res => {
          this.policy = res.data;
      });
  }
  
}
