import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouteInfo } from './sidebar.metadata';
import { SidebarService } from './sidebar.service';
import { Globals } from '../../common/global';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  planePrice = 0;
  isAssetsRegistered : boolean = false;
  constructor(private menuServise: SidebarService, private router: Router,public Globals: Globals) {
    let user = this.Globals.getUser();
    this.planePrice = user?.subscription?.price;
    this.planePrice = this.planePrice ? this.planePrice : 0;
    this.isAssetsRegistered = user.flags.is_assets_registered;
    this.menuServise.items.subscribe(menuItems => {
      this.sidebarnavItems = menuItems; 
      // Active menu 
      this.sidebarnavItems.filter(m => m.submenu.filter(
        (s) => {
          if (s.path === this.router.url) {
            this.path = m.title;
          }
        }
      ));
      this.addExpandClass(this.path);
    });
  }
  checkToshow(menu:any){
    let section1 = ['Dashboard','On Boarding','Track Assets','Purchase History','Ride History','Transactions'];
    
    if(section1.indexOf(menu)>=0){
      if(this.planePrice>0) {
        if(this.isAssetsRegistered) {
          let accessmenu = ['Dashboard','On Boarding','Track Assets','Purchase History','Ride History','Transactions']
           if(accessmenu.indexOf(menu)>=0){
             return true;
           }else{
             return false;
           }
        }else{
          let accessmenu = ['Dashboard','On Boarding','Purchase History','Ride History','Transactions']
          if(accessmenu.indexOf(menu)>=0){
            return true;
          }else{
            return false;
          }
        }
      }else{
        if (this.isAssetsRegistered) {
          let accessmenu = ['Track Assets','Purchase History','Ride History','Transactions']
          if(accessmenu.indexOf(menu)>=0){
            return true;
          }else{
            return false;
          }
        }else{
          let accessmenu = ['Purchase History','Ride History','Transactions']
          if(accessmenu.indexOf(menu)>=0){
            return true;
          }else{
            return false;
          }
        }
      }
    }else{
      return true;
    }
   
  }
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


}
