<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-6 mb-lg-0 mb-4">
            <ngb-carousel [showNavigationArrows]="data.images.length == 1 ? false : true" [showNavigationIndicators]="
                data.images.length == 1 ? false : true
              ">
              <ng-template ngbSlide *ngFor="let image of data.images; let i = index">
                <img [src]="image" />
              </ng-template>
            </ngb-carousel>
          </div>
          <div class="col-lg-5 ml-4">
            <h4 class="card-title mb-0 section-title-underline mb-4">
              <span class="lstick"></span>Pludda Details
            </h4>
            <p>
              <strong class="text-black">Status:</strong>&nbsp;{{
              data.status | titlecase
              }}
            </p>
            <p>
              <strong class="text-black">Weight:</strong>&nbsp;{{
              data.weight
              }}Kg
            </p>
            <p>
              <strong class="text-black d-block">Address:</strong>{{ data.address }}
            </p>
            <p>
              <strong class="text-black d-block">Description:</strong>{{ data.description }}
            </p>
            <div class="row">
              <div class="col-4"><label for="a">Status</label></div>
              <div class="col-8">
                <select class="form-control" name="a" id="a" [(ngModel)]="status" (change)="clearCoupan()">
                  <option [ngValue]="'publish'">Publish</option>
                  <option [ngValue]="'approved'">Approved</option>
                  <option [ngValue]="'unpublish'">Unpublish</option>
                </select>
              </div>

              <div *ngIf=" status == 'approved' && !isCouponApplied" class="col-4"><label for="a">Type</label></div>
              <div *ngIf=" status == 'approved' && !isCouponApplied" class="col-8">
                <input type="radio" [(ngModel)]="type" (change)="onType()" id="Defalut" name="defalut"
                  value="defalut">&nbsp;
                <label for="defalut">Defalut</label>&nbsp;&nbsp;
                <input type="radio" [(ngModel)]="type" (change)="onType()" id="manual" name="manual"
                  value="manual">&nbsp;
                <label for="manual">Manual</label>
              </div>


              <div *ngIf="status == 'approved' && !isCouponApplied &&type=='defalut'" class="col-4">
                <label>Coupon</label>
              </div>
              <div *ngIf="status == 'approved' && !isCouponApplied  &&type=='defalut'" class="col-8">
                <select [disabled]="isCouponApplied" class="form-control" [(ngModel)]="coupon_code_id">
                  <option *ngFor="let coupon of couponList" [ngValue]="coupon._id">
                    {{ coupon.couponCode }}
                  </option>
                </select>
              </div>
              <div *ngIf="
                  coupon_code_id != undefined &&
                  coupon_code_id != '' &&
                  status == 'approved' &&
                  !isCouponApplied && type=='defalut'
                " class="col-12">
                <input [disabled]="isCouponApplied" type="checkbox" [(ngModel)]="is_personalized" />&nbsp; <label
                  for="a">Personalized</label>
              </div>





              <div *ngIf="status == 'approved' && !isCouponApplied &&type=='manual'" class="col-4">
                <label>Coupon Code</label>
              </div>
              <div *ngIf="status == 'approved' && !isCouponApplied  &&type=='manual'" class="col-8">
                <input [disabled]="isCouponApplied" type="text" [(ngModel)]="coupon_code" class="form-control" />

              </div>

              <div *ngIf="status == 'approved' && !isCouponApplied &&type=='manual'" class="col-4">
                <label>Coupon Description</label>
              </div>
              <div *ngIf="status == 'approved' && !isCouponApplied  &&type=='manual'" class="col-8">
                <textarea [disabled]="isCouponApplied" type="text" [(ngModel)]="coupon_desc"
                  class="form-control"></textarea>

              </div>










              <div class="col-12">
                <button style="float: right" class="btn btn-primary" (click)="update()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-6 mb-lg-0 mb-4">
            <h4 class="card-title mb-0 section-title-underline mb-4">
              <span class="lstick"></span>User Info
            </h4>
            <img [src]="
                data?.user_id?.user_image == ''
                  ? './assets/images/No-image-found.jpg'
                  : data?.user_id?.user_image
              " class="userImage" alt="" />
            <p>
              <strong class="text-black">User Name:</strong>&nbsp;{{
              data?.user_id?.username | titlecase
              }}
            </p>
            <p>
              <strong class="text-black">Name:</strong>&nbsp;{{
              data?.user_id?.name | titlecase
              }}
            </p>
            <p>
              <strong class="text-black">Phone Number:</strong>&nbsp;{{
              data?.user_id?.mobile
              }}
            </p>
            <p>
              <strong class="text-black">Email:</strong>&nbsp;{{
              data?.user_id?.email
              }}
            </p>
          </div>
          <div *ngIf="isCouponApplied" class="col-lg-5 mb-lg-0 mb-4 ml-4">
            <span *ngIf="data.coupon_code?.couponCode">
              <h4 class="card-title mb-0 section-title-underline mb-4">
                <span class="lstick"></span>Coupon Details
              </h4>

              <p>
                <strong class="text-black">Coupon Code:</strong>&nbsp;{{
                data?.coupon_code?.couponCode
                }}
              </p>
              <p>
                <strong class="text-black">Coupon Type:</strong>&nbsp;{{
                data?.coupon_code?.couponType | titlecase
                }}
              </p>
              <p>
                <strong class="text-black">Time:</strong>&nbsp;{{
                data?.coupon_code?.time
                }}
              </p>
              <p>
                <strong class="text-black">type:</strong>&nbsp;{{
                data?.coupon_code?.type | titlecase
                }}
              </p>
              <p>
                <strong class="text-black">Discount Percent:</strong>&nbsp;{{
                data?.coupon_code?.discountPercent
                }}
              </p>
            </span>
            <span *ngIf="data.manual_coupon_code_data">
              <h4 class="card-title mb-0 section-title-underline mb-4">
                <span class="lstick"></span>Manual Coupon Code Details
              </h4>
              <p>
                <strong class="text-black">Coupon Code:</strong>&nbsp;{{
                data.manual_coupon_code_data.couponCode
                }}
              </p>
              <p>
                <strong class="text-black">Coupon Description:</strong>&nbsp;{{
                data.manual_coupon_code_data.description
                }}
              </p>

            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>