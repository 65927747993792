import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { Globals } from "../../common/global";
import * as moment from "moment";
@Component({
  selector: "app-coupons",
  templateUrl: "./coupons.component.html",
  styleUrls: ["./coupons.component.css"],
})
export class CouponsComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  today: any = moment(new Date()).format("YYYY-MM-DD");

  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  packages = [];
  timings = ["Day", "Week", "Month", "3 Month", "6 Month", "Yearly"];
  currency: any = localStorage.getItem("CUR");
  selectedPakcage: any;
  pattern = /(\w+)$/;
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.getList();
    this.getPackages();
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
    this.detail.package_id = this.detail?.package_id?._id;
    this.detail.expire_date = moment(
      this.detail.expire_date ?? new Date()
    ).format("YYYY-MM-DD");
    if (Object.keys(data).length == 0) {
      this.detail.isMultipleUse = false;
      this.detail.type = "hour";
      this.detail.time = 1;
    }
    console.log("data: ", data);
    console.log("this.detail : ", this.detail);

    // if (data) this.onPackageSelect(data.package_id._id);
  }
  keyPressNumbers(event: any) {
    //console.log(parseFloat(this.detail.discountPercent));

    if (
      !this.detail.discountPercent ||
      parseFloat(this.detail.discountPercent) <= 100
    ) {
      console.log("true");
      return true;
    } else {
      console.log("false");
      event.preventDefault();
      return false;
    }
    // var charCode = event.which ? event.which : event.keyCode;
    // // Only Numbers 0-9
    // if (charCode < 48 || charCode > 57) {
    //   event.preventDefault();
    //   return false;
    // } else {
    //   return true;
    // }
  }
  onPackageSelect(id: any): void {
    console.log(`Id is ${id}`);

    for (let index = 0; index < this.packages.length; index++) {
      const element: any = this.packages[index];
      console.log(element);
      if (id == element._id) {
        this.selectedPakcage = element;
        this.detail.type = element.timing.toLowerCase();
        break;
      }
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.COUPON.LIST + "?operator_id=" + user_id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = 2;
        }
      });
  }
  getPackages() {
    const body = new HttpParams().set("userId", this.Globals.getUser()._id);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_PACKAGE)
      .subscribe((res) => {
        console.log("Package List : ", res.data.data);
        this.packages = res.data.data.filter(function (packageData: any) {
          return (
            packageData.timing == "Hour" ||
            packageData.timing == "Minute" ||
            packageData.timing == "Day"
          );
        });

        //  this.packages =  res.data.data;
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }

  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.COUPON.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  onSubmit(f: any) {
    var type, time;
    console.log("f.value: ", f.value);
    console.log("this.selectedPakcage : ", this.selectedPakcage);
    console.log("this.detail : ", this.detail);

    if (f.value.couponType == "time") {
      type = this.detail.type;
      time = this.detail.time;
    } else {
      type =
        this.selectedPakcage && this.selectedPakcage.timing
          ? this.selectedPakcage.timing.toLowerCase()
          : this.detail.type;
      time = 0;
    }

    const body = new HttpParams()
      .set("operator_id", this.Globals.getUser()._id)
      .set("package_id", f.value.package_id)
      .set("couponCode", f.value.couponCode)
      .set("couponType", f.value.couponType)
      .set("discountPercent", f.value.discountPercent ?? 0)
      .set("isMultipleUse", f.value.isMultipleUse)
      .set("expire_date", f.value.expire_date)
      .set("limit", f.value.limit)
      .set("time", time)
      .set("type", type);

    let url = ConfigApi.URLS.COUPON.CREATE;
    let method = "POST";
    if (this.modeltitle == "Edit Coupon") {
      url = ConfigApi.URLS.COUPON.UPDATE + "/" + this.detail._id;
      method = "PUT";
    }
    let k = this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }

  getSelectedPackage() {}
}
