<div class="row">
    <div class="col-12">
      <div class="card card-body">
  
        <div class="">
          <h4 class="card-title mb-0">{{data?.title}}  <span class="float-right">{{data?.createdAt | date:"medium"}}</span></h4>
 
        </div>
        <div class="card-body">
    
             <p>{{data?.note}}</p>
          
        </div>
  
      </div>
    </div>
  </div>
   