import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { ConfigApi } from "../../../config-api";
import { Globals } from "../../../common/global";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-pludda-details",
  templateUrl: "./pludda-details.component.html",
  styleUrls: ["./pludda-details.component.css"],
  providers: [NgbCarouselConfig],
})
export class PluddaDetailsComponent implements OnInit {
  _id!: string;
  data: any = [];
  couponList: any = [];
  status!: string;
  is_personalized: boolean = false;
  isCouponApplied: boolean = false;
  coupon_code_id!: string;
  coupon_code!: string;
  coupon_desc!: string;
  type: any;
  constructor(
    config: NgbCarouselConfig,
    private activatedRoute: ActivatedRoute,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals
  ) {
    config.interval = 5000;
  }

  ngOnInit(): void {
    this.getCoupanList();
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.get("id")) {
        this._id = params.get("id") as string;
        this.getDetails();
      }
    });
  }
  getDetails() {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.PLUDDA.DETAILS + "/" + this._id)
      .subscribe((res) => {
        this.data = res.data;
        this.status = this.data.status;
        if (res.data.coupon_code) {
          console.log("Default");

          this.coupon_code_id = res.data.coupon_code.couponCodeId;
          this.is_personalized = res.data.coupon_code.is_personalized;
        }
        if (res.data.manual_coupon_code_data) {
          console.log("Manual");
          this.coupon_code = res.data.manual_coupon_code_data.couponCode;
          this.coupon_desc = res.data.manual_coupon_code_data.description;
        }
        if (res.data.coupon_code || res.data.manual_coupon_code_data)
          this.isCouponApplied = true;
      });
  }
  getCoupanList() {
    let user_id = this.Globals.getUser()._id;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.COUPON.LIST + "?operator_id=" + user_id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.couponList = res.data;
        }
      });
  }
  clearCoupan() {
    (this.coupon_code_id = ""), (this.is_personalized = false);
  }
  update() {
    let body = {};
    if (!this.isCouponApplied) {
      if (this.coupon_code_id) {
        body = {
          status: this.status,
          coupon_code_id: this.coupon_code_id,
          is_personalized: this.is_personalized,
        };
      }
      if (this.coupon_code) {
        body = {
          status: this.status,
          manual_coupon_code_data: {
            couponCode: this.coupon_code,
            description: this.coupon_desc,
          },
        };
      }
    } else {
      body = {
        status: this.status,
      };
    }

    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.PLUDDA.UPDATE + "/" + this._id)
      .subscribe((res) => {
        this.toastr.success(res.msg);
        this.ngOnInit();
      });
  }
}
