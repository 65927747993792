import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Globals } from "src/app/common/global";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";

@Component({
  selector: "app-geofence-list",
  templateUrl: "./geofence-list.component.html",
  styleUrls: ["./geofence-list.component.css"],
})
export class GeofenceListComponent implements OnInit {
  constructor(
    private apiCallingService: ApiCallingService,
    public globals: Globals,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getList();
    this.getAssetList();
  }
  modeltitle: any;
  page = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  getList() {
    let user_id = this.globals.user._id;
    let query = "?operator_id=" + user_id;
    // let query = "?operator_id=615aa5142886af45d9edcaba"
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GEOFENCE.LIST + query)
      .subscribe((res) => {
        this.data = res.data;
      });
  }

  loadPage(page: number) {
    this.page = page - 1;
    //this.getList();
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  deleteGeofence(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.GEOFENCE.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeBtnClick();
          this.getList();
        }
      });
  }
  assetParkingSelectedId: string = "";
  assetList: any[] = [];
  isMasterSel = false;
  assetPageSize = 10;
  assetPage = 1;
  checkUncheckAll() {
    for (var i = 0; i < this.assetList.length; i++) {
      this.assetList[i].isSelected = this.isMasterSel;
    }
    // this.getCheckedItemList();
  }
  isAllSelected() {
    this.isMasterSel = this.assetList.every(function (item: any) {
      return item.isSelected == true;
    });
  }
  getCheckedItemList(): string[] {
    var checkedCategoryList = [];
    for (var i = 0; i < this.assetList.length; i++) {
      if (this.assetList[i].isSelected)
        checkedCategoryList.push(this.assetList[i]._id);
    }
    return checkedCategoryList;
  }
  getAssetList() {
    let user_id = this.globals.user._id;
    let query = "?user_id=" + user_id + "&req_type=owner&limit=100&page=1";
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.MY_ASSETS + query)
      .subscribe((res) => {
        res.data.forEach((element: any) => {
          element.isSelected = false;
          this.assetList.push(element);
        });
        //this.assetList = res.data;
      });
  }
  createAssets() {
    let url =
      ConfigApi.URLS.GEOFENCE.UPDATE_PARKING_SASSET +
      "/" +
      this.assetParkingSelectedId;
    let method = "POST";

    var data = {
      lock_ids: this.getCheckedItemList(),
    };

    this.apiCallingService.callAPI(data, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.getList();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
  openAssetsModal(assetsModal: NgbModal, data: any) {
    this.modalService.open(assetsModal, {
      size: "lg",
      centered: true,
      backdrop: "static",
    });

    this.assetParkingSelectedId = data._id;

    var lockIds: any[] = [];
    lockIds = data.lock_ids;
    this.assetList.forEach((element: any) => {
      if (lockIds.indexOf(element._id) >= 0) element.isSelected = true;
      else element.isSelected = false;
    });
  }
}
