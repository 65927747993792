import { HttpClient, HttpParams } from "@angular/common/http";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, NgForm, } from "@angular/forms";
import { Globals } from "../../common/global";
import * as moment from "moment";
import { environment } from '../../../environments/environment';
import * as mapboxgl from "mapbox-gl";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  AfterViewInit
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import 'moment-timezone';


import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { Router } from "@angular/router";

const colors: any = {
  red: {
    primary: '#fd2624',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },

  green: {
    primary: '#9edf26',
    secondary: 'rgb(175 255 188)',

  },
  gray: {
    primary: '#b9b5b3',
    secondary: '#e2dad6',
  },
  black: {
    primary: '#000000',
    secondary: '#eae8e8',
  },
  orange: {
    primary: '#ffa500',
    secondary: '#ffe1bb',
  }
};

@Component({
  selector: 'app-upcoming-booking',
  templateUrl: './upcoming-booking.component.html',
  styleUrls: ['./upcoming-booking.component.css'],
})
export class UpcomingBookingComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  today: any = moment(new Date()).format("YYYY-MM-DD");
  startDate = moment().startOf('month').format("YYYY-MM-DD")
  endDate = moment().endOf('month').format("YYYY-MM-DD");
  map: any = mapboxgl.Map;
  currentTime!: string;
  geometryData: any;
  isShapeCreated: boolean = false;
  operator_id = this.Globals.getUser()._id;
  allcountry: any;
  countryShortCode: any;
  style = "mapbox://styles/mapbox/streets-v11";
  boundaryId: string = "";
  draw: any;
  boundaryDetail = {};
  lat = 37.75;
  lng = -122.41;
  current_time = moment().format("HH:mm")
  latitude: any;
  longitude: any;
  searchQuery: string = '';
  suggestions: any[] = [];
  hideCountry: boolean = true;
  cityAdress: any;

  imageSrc: any;
  file: any;
  editImgPreview: any;
  hashArr: any[] = [];
  hashTags: string = '';
  AuthDetail: any;
  countryDetail: any;
  MapLatitude: any;
  MapLongitude: any;

  view: CalendarView = CalendarView.Week;
  data: any = [];
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  maxValue: any

  modalData!: {
    [x: string]: any;
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Cancel',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  obj2: any;
  eventDetail: any;
  price: any;


  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals,
    private formBuilder: FormBuilder,
    private router: Router, private loc: Location
  ) {
    // mapboxgl!.accessToken = environment.mapbox.accessToken;
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }

  ngOnChanges() {
    this.getList();

  }

  ngOnInit(): void {
    this.getList();
    this.http.get("assets/countries.json").subscribe(data => {
      this.allcountry = data;
    })
    // if ("geolocation" in navigator) {

    //   navigator.geolocation.getCurrentPosition(position => {a
    //     let lat = position.coords.latitude;
    //     let lng = position.coords.longitude;
    //     this.MapLatitude = lat;
    //     this.MapLongitude = lng;
    //     this.getCurrentCountry(lat, lng);
    //   });
    // }
    this.AuthDetail = localStorage.getItem("Auth-user");
    this.AuthDetail = JSON.parse(this.AuthDetail);
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.view = CalendarView.Day;

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    console.log(action);
    console.log(event);
    this.modalData = { event, action };
    this.modalService.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    console.log(eventToDelete);

    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  test(e: any) {
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  getCurrentCountry(lat: any, lng: any) {
    let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      + lng + ', ' + lat
      + '.json?access_token=' + environment.mapbox.accessToken;
    let geodata;
    this.apiCallingService.callAPI({}, 'GET', url)
      .subscribe(res => {
        geodata = res.features;
        let c = geodata.find((cn: any) => {
          if (cn.id.includes('country')) {
            6
            return cn;
          }
        });
        this.countryShortCode = c.properties.short_code;
      });
  }


  modeltitle: any;
  page = 1;
  pageSize = 10;
  totalcount = 0;
  detail: any;
  statusFilter: any = "all"
  bookingType = [{
    item: 'Self Serve',
    value: "self_serve"
  },
  {
    item: 'Activities',
    value: "activities"
  }, {
    item: 'Rentx',
    value: "rentx"
  }];
  selectedSuggestion: any = null;
  isWaiverDisplay: boolean = true;
  is_ending_pic_mandatory: Boolean = true;
  timings = ["Hour", "Day", "Week", "Month", "Yearly"];
  duration = ["0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5", " 5.5", "6", "6.5", "7", "7.5",
    "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"];
  currency: any = localStorage.getItem("CUR");


  changeTiming(e: any) {
    if (e.target.value == "Minute") {
      this.maxValue = 60;
    } else if (e.target.value == "Hour") {
      this.maxValue = 12;
    } else if (e.target.value == "Day") {
      this.maxValue = 365;
    } else if (e.target.value == "Week") {
      this.maxValue = 52;
    } else if (e.target.value == "Month") {
      this.maxValue = 12;
    }
  }

  selectSuggestion(suggestion: any) {
    this.selectedSuggestion = suggestion;
    this.searchQuery = suggestion.display_name; // Set the search query to the selected suggestion
    this.suggestions = [];
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.detail = Object.assign({}, data);
    this.modeltitle = title;
    this.imageSrc = this.detail.poster_image;
    this.detail.is_charge_cancellation_fee = false;
    this.detail.is_refund_full_amount = false;
    this.detail._id = this.modalData.event.id
    this.detail.chargeableAmount = this.modalData.event
    this.maxValue = this.detail.chargeableAmount.maxValue / 100
    // console.log("this.maxValue", this.detail.chargeableAmount.maxValue);

    if (this.modeltitle === "Edit Package") {
      this.detail.start_date = moment(
        this.detail.start_date ?? new Date()
      ).format("YYYY-MM-DD");
      this.detail.end_date = moment(
        this.detail.end_date ?? new Date()
      ).format("YYYY-MM-DD");
      this.hideCountry = false;
    } else {
      this.hideCountry = true;
    }

    const operatorCountry = this.allcountry.find((val: any, i: any) => val.name == this.AuthDetail?.countryObject?.countryName);
    // if (Object.keys(data).length == 0) {
    //   this.detail.isExclusive = false;
    //   this.detail.is_charge_cancellation_fee = false;

    //   this.detail.isWaiverDisplay = false;
    //   this.detail.is_ending_pic_mandatory = false;
    //   this.detail.vat = 0;
    //   this.detail.country = operatorCountry.name;
    //   this.detail.duration = this.duration[0];
    // } else {
    //   if (this.detail.timing == "Minute") {
    //     this.maxValue = 60;
    //   } else if (this.detail.timing == "Hour") {
    //     this.maxValue = 24;
    //   } else if (this.detail.timing == "Day") {
    //     this.maxValue = 365;
    //   } else if (this.detail.timing == "Week") {
    //     this.maxValue = 52;
    //   } else if (this.detail.timing == "Month") {
    //     this.maxValue = 12;
    //   }
    // }

    if (title == "Choose Location") {
      setTimeout(() => {
        this.map = new mapboxgl.Map({
          container: 'map',
          style: this.style,
          zoom: 13,
          center: [this.MapLongitude, this.MapLongitude]
        });
        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true,
          }),
          'top-left'
        );
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken, // Set the access token
          placeholder: "Search with address or name",
          marker: false,
        });
        this.map.addControl(geocoder);
        this.map.on("load", () => {
          this.getDetails(this.MapLatitude, this.MapLongitude);
        });
      }, 1);
    }
  }
  getDetails(lat: any, lng: any,) {
    let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      + lng + ', ' + lat
      + '.json??country=' + this.countryShortCode + '&types=address&access_token=' + environment.mapbox.accessToken;
    let geodata;
    this.apiCallingService.callAPI({}, 'GET', url)
      .subscribe(res => {
        console.log("🚀 ~~~~~~~~~~ ~ AdvanceBookingComponent ~ getDetails ~ res:", res)
      });
  }
  closeBtnClick() {
    this.modalService.dismissAll();
    this.hashArr = [];
    this.imageSrc = ''
  }

  closeModels() {
    this.modalService.dismissAll();
    this.hashArr = [];
    this.imageSrc = ''
  }
  filterbydate() {
    console.log("startDate--", this.startDate);
    console.log("endDate--", this.endDate);
    console.log("detail.statusFilte--", this.statusFilter);


  }

  loadPage(page: number) {
    this.page = page
    this.getList();
  }

  getList() {
    let pageNo = this.page == 0 ? 0 : this.page - 1;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.UPCOMING_BOOKING.LIST + "?operator_id=" + this.operator_id + "&page=" + pageNo + "&limit=" + 10 + "&status=" + this.statusFilter + "&start_date=" + this.startDate + "&end_date=" + this.endDate
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          // this.ngOnChanges(this.data)
          for (let detail of this.data) {
            // .tz('Europe/Stockholm').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')
            // .tz('Asia/Kolkata').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')
            const formattedStartDate = moment(detail.start_date).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
            const formattedEndDate = moment(detail.end_date).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
            let mainStartDate = new Date(formattedStartDate)
            let mainEndDate = new Date(formattedEndDate)
            let category_name = detail.package_id?.category_name ? detail.package_id?.category_name : "-"
            let sub_category_name = detail.package_id?.sub_category_name ? detail.package_id?.sub_category_name : "-"
            this.price = detail.package_id?.price ? detail.package_id?.price : 0.00
            let security_deposit = detail.package_id?.security_deposit ? detail.package_id?.security_deposit : 0.00
            let extra_fee = detail.package_id?.extra_fee ? detail.package_id?.extra_fee : 0.00
            let type = detail?.package_id?.type ? detail?.package_id?.type : "-"
            let user_address = detail.user_id?.address ? detail.user_id?.address : '-'
            let user_mobile = detail.user_id?.mobile ? detail.user_id?.mobile : '-'
            let mainObject: any =
            {
              start: mainStartDate,
              end: mainEndDate,
              title:
                "<b  class='btn-" + detail.status + "'  >" +
                this.getStatusName(detail.status) + "</b>" + "<br/>" +
                "<b>" + detail.package_id?.title + "</b>" + "<br/>" +
                "<b class=col-4>User:  </b>  " + detail.user_id?.username + "<br/>" +
                "<b class=col-4>User Address:  </b>  <div class='col-8 d-inline-flex'> " + user_address + "</div><br/>" +
                "<b class=col-4>User Mobile:  </b>  <div class='col-8 d-inline-flex'> " + user_mobile + "</div><br/>" +
                "<b class=col-4>Package Type:  </b>  " + detail?.package_id?.booking_type + "<br/>" +
                "<b class=col-4>Type:  </b>  " + type + "<br/>" +
                "<b class=col-4>Category Name:  </b>  " + category_name + "<br/>" +
                "<b class=col-4>Sub-Category Name:  </b>  " + sub_category_name + "<br/>" +
                "<b class=col-4>Fees:  </b>  " + this.price + " " + this.currency + "<br/>" +
                "<b class=col-4>Deposit:  </b>  " + security_deposit + " " + this.currency + "<br/>" +
                "<b class=col-4>Extra Charge Fee:  </b>  " + extra_fee + " " + this.currency + "<br/>" +
                "<b class=col-4>Time:  </b>  " + moment(detail.start_date).format("HH:mm A") + "-" + moment(detail.end_date).format(" HH:mm A") + "<br/>" +
                "<b class=col-4>Email:  </b>  " + detail.user_id?.email + "<br/>" +
                "<b class=col-4>Brand Name:  </b>  " + detail.brand_name + "<br/>" +
                "<b class=col-4>Total Asset Allowed:  </b>  " + detail.package_id?.total_asset_allow_in_pack + "<br/>" +
                "<b class=col-4>Duration:  </b>  " + detail.package_id?.duration + "<br/>" +
                "<b class=col-4>Timing:  </b>  " + detail.package_id?.timing + "<br/>" +
                "<b class=col-4>Vat(%) :  </b>  " + detail.package_id?.vat + "<br/>" +
                "<b class=col-4>Start Date:  </b>  " + moment(detail.start_date).format("YYYY-MM-DD, HH:mm A") + "<br/>" +
                "<b class=col-4>End Date:  </b>  " + moment(detail.end_date).format("YYYY-MM-DD, HH:mm A") + "<br/>" +
                "<b class=col-4>Description:  </b>  <div class='col-8 d-inline-flex'> " + detail.description + "</div><br/>" +
                "<div class='address d-flex'><i class='mdi mdi-map-marker-multiple h5 mr-2'></i><p class='h5'>" + detail.package_id?.address + "</p></div>",
              color: this.getColorForStatus(detail.status),
              status: detail.status,
              maxValue: detail.chargeableAmount,
              img: detail?.package_id?.poster_image,
              backgroundColor: '#FF5733', // Example color
              id: detail._id,
              // allDay: true,
              resizable: {
                beforeStart: true,
                afterEnd: true,
              },
              draggable: true,
            }
            this.events.push(mainObject)
            this.eventDetail =
              "<h4>" + detail.package_id?.title + "</h4>" +
              "<b class=col-4>User:  </b>  " + detail.user_id?.username + "<br/>" +
              "<b class=col-4>Package Type:  </b>  " + detail?.package_id?.booking_type + "<br/>" +
              "<b class=col-4>Type:  </b>  " + type + "<br/>" +
              "<b class=col-4>Category Name:  </b>  " + category_name + "<br/>" +
              "<b class=col-4>Sub-Category Name:  </b>  " + sub_category_name + "<br/>" +
              "<b class=col-4>Fees:  </b>  " + this.price + " " + this.currency + "<br/>" +
              "<b class=col-4>Deposit:  </b>  " + security_deposit + " " + this.currency + "<br/>" +
              "<b class=col-4>Extra Charge Fee:  </b>  " + extra_fee + " " + this.currency + "<br/>" +
              "<b class=col-4>Time:  </b>  " + moment(detail.start_date).format("HH:mm A") + "-" + moment(detail.end_date).format(" HH:mm A") + "<br/>" +
              "<b class=col-4>Email:  </b>  " + detail.user_id?.email + "<br/>" +
              "<b class=col-4>Brand Name:  </b>  " + detail.brand_name + "<br/>" +
              "<b class=col-4>Total Asset Allowed:  </b>  " + detail.package_id?.total_asset_allow_in_pack + "<br/>" +
              "<b class=col-4>Duration:  </b>  " + detail.package_id?.duration + "<br/>" +
              "<b class=col-4>Timing:  </b>  " + detail.package_id?.timing + "<br/>" +
              "<b class=col-4>Vat(%) :  </b>  " + detail.package_id?.vat + "<br/>" +
              "<b class=col-4>Start Date:  </b>  " + moment(detail.start_date).format("YYYY-MM-DD, HH:mm A") + "<br/>" +
              "<b class=col-4>End Date:  </b>  " + moment(detail.end_date).format("YYYY-MM-DD, HH:mm A") + "<br/>" +
              "<b class=col-4>Description:  </b>  <div class='col-8 d-inline-flex'> " + detail.description + "</div><br/>" +
              "<b class=col-4>User Address:  </b>  <div class='col-8 d-inline-flex'> " + detail.user_id?.address + "</div><br/>" +
              "<div class='address d-flex'><i class='mdi mdi-map-marker-multiple h5 mr-2'></i><p class='h5'>" + detail.package_id?.address + "</p></div>"
          }
          this.totalcount = res.total;
        }
      });
  }
  getStatusName(status: string) {
    switch (status) {
      case "expired":
        return "Expired";
      case "upcoming":
        return "Upcoming";
      case "active":
        return "Active";
      case "returned":
        return "Returned";
      case "completed":
        return "Completed";
      case "cancel_request":
        return "Cancel Request";
      case "refund_request":
        return "Refund Request";
      case "refunded":
        return "Refunded";
      case "cancelled":
        return "Cancelled";
      default:
        return "Cancelled";
    }
  }
  getColorForStatus(status: string): string {
    switch (status) {
      case "expired":
        return colors.red;
      case "upcoming":
        return colors.black;
      case "active":
        return colors.green;
      case "returned":
        return colors.blue;
      case "completed":
        return colors.gray;
      case "cancel_request":
        return colors.red;
      case "refund_request":
        return colors.orange;
      case "refunded":
        return colors.orange;
      case "cancelled":
        return colors.red;
      default:
        return colors.black;
    }
  }

  deleteData(id: any) {

    const body = new HttpParams()
      .set("cancel_reason", this.detail.reason)
    this.apiCallingService
      .callAPI(
        body,
        "PUT",
        ConfigApi.URLS.UPCOMING_BOOKING.DELETE + "/" + this.modalData.event.id

      )
      .subscribe((res) => {
        this.closeBtnClick();
        location.reload()
      });
  }
  refundData() {
    const cancellation_fee = this.detail.is_charge_cancellation_fee ? Number(this.detail.amount) : 0
    const body = new HttpParams()
      .set("cancellation_fee", Number(cancellation_fee))
      .set("is_charge_collection_fee", this.detail.is_charge_cancellation_fee)
      .set("refund_amount", this.detail.is_refund_full_amount ? this.detail.amountTotal : 0)
      .set("is_refund_full_amount", this.detail.is_refund_full_amount)
      .set("booking_purchase_id", this.detail._id)
    this.apiCallingService
      .callAPI(
        body,
        "POST",
        ConfigApi.URLS.UPCOMING_BOOKING.UPDATE
      )
      .subscribe((res) => {
        this.closeBtnClick();
        location.reload();
      });
  }
  totalRefundData() {
    const is_refund_full_amount = this.detail.is_refund_full_amount ? 0 : this.detail.amountTotal;
    const body = new HttpParams()
      .set("cancellation_fee", this.detail.is_charge_cancellation_fee ? this.detail.amountTotal : 0)
      .set("is_charge_collection_fee", this.detail.is_charge_cancellation_fee)
      .set("refund_amount", Number(is_refund_full_amount))
      .set("is_refund_full_amount", this.detail.is_refund_full_amount)
      .set("booking_purchase_id", this.detail._id)

    this.apiCallingService
      .callAPI(
        body,
        "POST",
        ConfigApi.URLS.UPCOMING_BOOKING.UPDATE
      )
      .subscribe((res) => {
        this.closeBtnClick();
        location.reload();
      });
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.file = file;
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
      this.imageSrc = file;
    }
  }


}
