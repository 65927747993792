import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiCallingService } from '../../services/api-calling.service';
import { ConfigApi } from '../../config-api';
import { Globals } from '../../common/global';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as mapboxgl from 'mapbox-gl';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-all-queuers',
  templateUrl: './all-queuers.component.html',
  styleUrls: ['./all-queuers.component.css']
})
export class AllQueuersComponent implements OnInit {
  data = [];
  totalcount: any;
  show1: boolean = true;
  show2: boolean = false;
  show3: boolean = false;
  showdata: boolean = true;
  lockunlockdata: any;
  activeTab: any = "waiting";
  totalHour: any;
  totalEarn: any;
  user_id: any;
  type: any;
  page = 0;
  pageSize = 10;
  soliId: string | null;
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    private router: Router,
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this.soliId = routeParams.get('id');

  }
  ngOnInit(): void {
    this.getList('waiting');
  }
  getLockTypes(type: any) {
    this.activeTab = type
    if (type == "waiting") {
      this.show1 = true;
      this.showdata = true;
      this.show2 = false;
      this.show3 = false;
    }
    if (type == "completed") {
      this.show2 = true;
      this.show1 = false;
      this.show3 = false;
      this.showdata = false;
    }
    if (type == "leaved") {
      this.show3 = true;
      this.show2 = false;
      this.show1 = false;
      this.showdata = false;
    }
    this.type = type
    this.getList(type)
  }

  getList(type: any) {
    let page = this.page == 0 ? 0 : this.page - 1;

    this.apiCallingService.callAPI({}, 'GET', ConfigApi.URLS.ALL_QUEUERS.LIST + "?page=" + page + "&limit=" + this.pageSize + "&status=" + type + "&booking_package_id=" + this.soliId)
      .subscribe((res) => {
        this.data = res.data;
        this.totalcount = res.total;
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList(this.type);
  }
}
