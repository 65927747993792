import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiCallingService } from 'src/app/services/api-calling.service';
import { Globals } from "../../../common/global";
import { ConfigApi } from 'src/app/config-api';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-business-analytics',
  templateUrl: './business-analytics.component.html',
  styleUrls: ['./business-analytics.component.css']
})
export class BusinessAnalyticsComponent implements OnInit {
  data = [];
  totalcount: any;
  show1: boolean = true;
  show2: boolean = false;
  show3: boolean = false;
  showdata: boolean = true;
  lockunlockdata: any;
  activeTab: any = "Rent";
  totalHour: any;
  totalEarn: any;
  user_id: any;
  type: any;
  page = 0;
  pageSize = 10;
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    public Globals: Globals,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    private router: Router,
  ) {
    // console.log("user id =>", this.router.getCurrentNavigation()?.extras);
    // this.user_id = this.router.getCurrentNavigation()?.extras?.state?.id;
    // console.log(this.user_id);

  }

  ngOnInit(): void {
    console.log("history =>", history.state);

    this.getList('Rent');
  }
  getLockTypes(type: any) {
    console.log("🚀 ~ file: business-analytics.component.ts:39 ~ BusinessAnalyticsComponent ~ getLockTypes ~ type:", type)
    this.activeTab = type
    if (type == "Rent") {
      this.show1 = true;
      this.showdata = true;
      this.show2 = false;
      this.show3 = false;
    }
    if (type == "Sale") {
      this.show2 = true;
      this.show1 = false;
      this.show3 = false;
      this.showdata = false;
    }
    if (type == "Lease") {
      this.show3 = true;
      this.show2 = false;
      this.show1 = false;
      this.showdata = false;
    }
    this.type = type
    this.getList(type)
  }
  loadPage(page: number) {
    this.page = page;
    this.getList('Rent');
  }
  getList(type: any) {
    const user_id = this.route.snapshot.params["id"];
    console.log(user_id);
    const data: any = localStorage.getItem("Auth-user");
    const id = JSON.parse(data);
    let payload = {
      operator_id: id._id,
      type: type ? type : "Rent",
      limit: 10,
      page: this.page == 0 ? 0 : this.page - 1,

    }
    this.apiCallingService
      .callAPI(payload, "POST", ConfigApi.URLS.LOCK_ANALYTICS.GET_LOCK_ANALYTICS_DATA + user_id)
      .subscribe((res) => {
        this.data = res.data;
        console.log(this.data);
        this.lockunlockdata = res.lockUnlockHistory;
        this.totalHour = res.totalHourSpend;
        this.totalEarn = res.totalEarned;
        this.totalcount = res.total;
      });
  }
}
